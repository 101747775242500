import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { AppContext } from '../main/AppContext';

import {
  loadCombinedAction,
  saveProgressAction
} from '../../redux-actions/dataActions';
import dataHelpers from '../../utils/dataHelpers';

import Header from '../common/Header';
import Footer from '../common/Footer';
import Loader from '../common/Loader';
import RegisterStepHeader from './RegisterStepHeader';
import RegisterButton from './RegisterButton';
import RegisterFirstStep from './RegisterFirstStep';
import i18n from '../../translation/translation';
import RegisterFirstStep2 from './RegisterFirstStep2';

class SelectCourse extends Component {
  rearm = false;

  state = {
    redirectToProgram: false,
    redirectDashboard: false,
    courseSelected: {
      id: false
    },
    languageSelected: null,
    firstPage: true
  };

  handleSaveEvent = () => {
    if (this.state.firstPage) {
      this.setState({
        firstPage: false
      });
      return;
    }
    const course = this.state.courseSelected;
    if (!course) return;

    var response = this.props.response.data.response;
    var progress = response.user.learningProgress;
    var selected = progress.selected || [];
    var found = false;

    let programId = course.programs ? course.programs[0].id : undefined;

    for (let i = selected.length - 1; i >= 0; i--) {
      let item = selected[i];
      item.selected = false;
      if (item.packageId === course.id && item.programId === programId) {
        if (!found) {
          found = true;
          item.selected = true;
        }
      }
    }
    if (!found) {
      selected.push({
        packageId: course.id,
        languageId: course.languageId,
        programId: programId,
        selected: true
      });
      progress.selected = selected;
    }

    this.rearm = true;
    this.props.dispatch(saveProgressAction(progress));
  };

  handleSelectCourse = course => {
    this.setState({
      courseSelected: course
    });
  };

  handleSelectLanguage = languageId => {
    this.setState({
      languageSelected: languageId,
      firstPage: languageId ? this.state.firstPage : true
    });
  };

  handleStepChange = e => {
    // this.setState({
    //   redirectToProgram: true
    // });
    // eslint-disable-next-line
    this.state.redirectToProgram = true;
    this.handleSaveEvent();
  };

  handleDashboardView = (left, right) => {
    this.setState({
      leftPanel: left,
      rightPanel: right,
      redirectDashboard: !this.state.redirectDashboard
    });
  };

  render() {
    if (this.state.redirectDashboard) {
      return (
        <Redirect
          to={{
            pathname: '/dashboard',
            state: {
              leftPanel: this.state.leftPanel,
              rightPanel: this.state.rightPanel
            }
          }}
        />
      );
    }

    if (this.props.response.progress.hasOwnProperty('response') && this.rearm) {
      //TODO: update progress in user data
      //this.context.user.learningProgress = this.props.response.progress.response;

      if (this.state.redirectToProgram) {
        return (
          <Redirect
            to={{
              pathname: '/select-program',
              state: {}
            }}
          />
        );
      } else {
        let user = dataHelpers.getUser();
        this.props.dispatch(loadCombinedAction(user.id));
        return <Redirect to="/dashboard" />;
      }
    }

    if (!this.props.response.data.hasOwnProperty('response')) {
      let user = JSON.parse(localStorage.getItem('user'));
      if (user) {
        this.props.dispatch(loadCombinedAction(user.id));
        return <Loader />;
      } else {
        return <Redirect to="/login" />;
      }
    } else {
      var response = this.props.response.data.response;

      localStorage.setItem('user', JSON.stringify(response.user));

      let user = response.user;
      let languages = response.languages;

      let last = dataHelpers.getSelectedProgress(user);
      var packge, program;
      if (last) {
        packge = dataHelpers.getPackageById(languages, last.packageId);
        program = dataHelpers.getProgramById(languages, last.programId);
      }

      return (
        <>
          <Header
            {...this.props}
            data={user}
            package={packge}
            program={program}
            isLogged={last != null}
            // rightLayoutHandler={this.handleRightLayoutView.bind(this)}
            viewHandler={this.handleDashboardView.bind(this)}
          />
          <RegisterStepHeader
            title={i18n(
              this.state.firstPage ? 'select-your-language' : 'what-u-want'
            )}
          />
          {this.state.firstPage ? (
            <RegisterFirstStep
              languageSelected={this.state.languageSelected}
              courseSelected={this.state.courseSelected.id}
              onCourseClick={this.handleSelectCourse.bind(this)}
              onLanguageClick={this.handleSelectLanguage.bind(this)}
              languages={this.props.response.data.response.languages}
            />
          ) : (
            <RegisterFirstStep2
              languageSelected={this.state.languageSelected}
              courseSelected={this.state.courseSelected.id}
              onCourseClick={this.handleSelectCourse.bind(this)}
              onLanguageClick={this.handleSelectLanguage.bind(this)}
              languages={this.props.response.data.response.languages}
            />
          )}
          {!last ? (
            <RegisterButton
              handleClickEvent={this.handleStepChange.bind(this)}
              {...this.state}
            />
          ) : (
            <RegisterButton
              handleClickEvent={this.handleSaveEvent.bind(this)}
              step={4}
            />
          )}
          <Footer />
        </>
      );
    }
  }
}

SelectCourse.contextType = AppContext;

const mapStateToProps = response => ({ response });

export default connect(mapStateToProps)(SelectCourse);
