import React, { Component } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { AppContext, AppContextClass } from './AppContext';
import { isMobileOnly } from 'react-device-detect';
import { ConnectedRouter } from 'connected-react-router';

import 'bootstrap/dist/css/bootstrap.min.css';

import PrivateRoute from '../../container/privateRoute';
import MainRoute from '../../container/MainRoute';
import TestPage from '../auth/Test';
import Login from '../auth/Login';
import Logout from '../auth/Logout';
import PasswordRecovery from '../auth/PasswordRecovery';
import RegisterPage from '../auth/Register';
import Info from '../auth/Info';
import DashboardPage from '../dashboard/DashboardLayout';
import Loader from '../common/Loader';
import SelectCourse from '../register/SelectCourse';
import SelectProgram from '../register/SelectProgram';
import SelectLevel from '../register/SelectLevel';
import SelectReminders from '../register/SelectReminders';
import EndLesson from '../dashboard/completion/EndLesson';
import OfferPage from '../dashboard/completion/OfferPage';
import LessonLimit from '../dashboard/exercise/LessonLimit';
import SuccessfulCompletion from '../dashboard/completion/SuccessfulCompletion';
import UnsuccessfulCompletion from '../dashboard/completion/UnsuccessfulCompletion';
import ErrorBoundary from './ErrorBoundary';
import { history } from '../../store/configureStore';
import TestAd from './TestAd';
import Main from './Main';
import '../main/App.css';
import 'react-sound';

import 'bootstrap/dist/js/bootstrap.min.js';
import Exercise from '../dashboard/exercise/Exercise';

class App extends Component {
  constructor(props) {
    super(props);
    window.soundManager.setup({ debugMode: false });
  }

  render() {
    return (
      <AppContext.Provider value={new AppContextClass()}>
        <ConnectedRouter history={history}>
          <ErrorBoundary history={history}>
            <Switch>
              <MainRoute path="/" exact={true} component={Main} />
              <PrivateRoute path="/dashboard" component={DashboardPage} />
              <Route
                path="/select-course"
                exact={true}
                component={SelectCourse}
              />
              <PrivateRoute path="/select-program" component={SelectProgram} />
              <PrivateRoute path="/select-level" component={SelectLevel} />
              <PrivateRoute
                path="/select-reminders"
                component={SelectReminders}
              />
              <PrivateRoute path="/exercise" component={Exercise} />
              <PrivateRoute path="/endlesson" component={EndLesson} />
              <PrivateRoute path="/offer" component={OfferPage} />
              <PrivateRoute path="/limit" component={LessonLimit} />
              <PrivateRoute
                path="/unsuccessful"
                component={UnsuccessfulCompletion}
              />
              <PrivateRoute
                path="/successful"
                component={SuccessfulCompletion}
              />

              <Route path="/test" component={TestPage} />
              <Route path="/main" component={Main} />
              <Route path="/login" component={Login} />
              <Route path="/logout" component={Logout} />
              <Route path="/passrecovery" component={PasswordRecovery} />
              <Route path="/register" component={RegisterPage} />
              <Route path="/loader" component={Loader} />
              <Route path="/terms" component={Info} />
              <Route path="/privacy" component={Info} />
              <Route path="/contacts" component={Info} />
              <Route path="/test-ad" component={TestAd} />

              <Route
                path="/mobile.html"
                component={() => {
                  window.location.href = '/mobile.html';
                  return null;
                }}
              />
            </Switch>
            {isMobileOnly ? <Redirect to="/mobile.html" /> : <></>}
          </ErrorBoundary>
        </ConnectedRouter>
      </AppContext.Provider>
    );
  }
}

export default App;
