import React, { Component } from 'react';

class RegisterStepHeader extends Component {
  render() {
    return (
      <h3 align="center" className="log-header-title">
        {this.props.title}
      </h3>
    );
  }
}

export default RegisterStepHeader;
