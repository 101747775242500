import React, { Component } from 'react';
import speach_round_ic from '../../../../images/speach_round_ic.svg';
import PlayBtnSimple from '../PlayBtnSimple';
import ExerciseLogic from '../ExerciseLogic';

import Sentence from './type9/Sentence';

class Type9 extends Component {
  state = {
    sentence: null,
    options: null,
    translation: null
  };

  componentWillMount = () => {
    const exercise = this.props.exercise;
    let optionData = exercise.type9_word_choice.split('; ');
    let sentenceData = exercise.type9_in_correct_order.split(' ');
    let options = [];
    let sentence = [];

    optionData.forEach(element => {
      options.push(
        <button
          className="ex-options-btn"
          key={element}
          onClick={() => {
            this.handleOptionSelect(
              this.state.sentence,
              this.state.options,
              element
            );
            this.handleSentenceResponse();
          }}
        >
          {element}
        </button>
      );
    });

    //populate null for initial sentence
    sentenceData.forEach(element => {
      sentence.push(null);
    });

    this.setState({
      sentence: sentence,
      options: options,
      translations: exercise.type9_sentence
    });
  };

  handleOptionSelect = (sentence, options, param) => {
    //returns first instance of null element
    const nullArea = sentence.find(element => element === null);
    //re set the value of sentence when first null value is found
    sentence[sentence.indexOf(nullArea)] = param;

    //remove selected option
    const optionArea = []; //options.filter(element => element.key !== param);

    this.state.options.forEach(option => {
      let element = option.key;
      let disabled =
        param.toLowerCase() === element.toLowerCase() || option.props.disabled;
      let newElement = (
        <button
          disabled={disabled}
          className="ex-options-btn"
          key={element}
          onClick={() => {
            this.handleOptionSelect(
              this.state.sentence,
              this.state.options,
              element
            );
            this.handleSentenceResponse();
          }}
        >
          {disabled ? '      ' : element}
        </button>
      );

      optionArea.push(newElement);
    });

    this.setState({
      sentence: sentence,
      options: optionArea
    });
  };

  handleSentenceDeselect = param => {
    let optionArea = [];

    this.state.options.forEach(option => {
      let element = option.key;
      let disabled = param === element ? false : option.props.disabled;
      let newElement = (
        <button
          disabled={disabled}
          className="ex-options-btn"
          key={element}
          onClick={() => {
            this.handleOptionSelect(
              this.state.sentence,
              this.state.options,
              element
            );
            this.handleSentenceResponse();
          }}
        >
          {disabled ? '      ' : element}
        </button>
      );

      optionArea.push(newElement);
    });

    let sentenceFilter = this.state.sentence;
    sentenceFilter[sentenceFilter.indexOf(param)] = null;

    this.setState({
      sentence: sentenceFilter,
      options: optionArea
    });
  };

  handleSentenceView = () => {
    let view = [];

    this.state.sentence.forEach((element, index) => {
      view.push(
        <Sentence
          key={'type9-sentence-' + index}
          handleDiselect={this.handleSentenceDeselect.bind(this)}
          element={element}
        />
      );
    });

    return view;
  };

  handleSentenceResponse = () =>
    this.props.selectedValue(9, this.state.sentence);

  render() {
    const exercise = this.props.exercise;
    return (
      <div className="exercise-card-type9">
        <p>
          <PlayBtnSimple
            className="speach_round"
            alt="speach_ic"
            src={speach_round_ic}
            audio_file={ExerciseLogic.getAudioUrl(
              this.props.language,
              exercise.type9_sentence
            )}
          />
          {this.state.translations}
        </p>

        <div className="ex-options-type9">
          <div
            className="ex-sentence"
            style={{
              marginTop: '-14px',
              position: 'fixed'
            }}
          >
            {this.handleSentenceView()}
          </div>
        </div>

        <div> {this.state.options}</div>
      </div>
    );
  }
}

export default Type9;
