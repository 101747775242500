import React, { Component } from 'react';
import '../../dashboard/dashboard.css';

class Contacts extends Component {
  render() {
    return (
      <div className="info-body-text">
        <p className="subtitle mb-5">Contact with us</p>{' '}
        <p>Our Address: Hungary, 2094, Nagykovácsi, Nagykovácsi utca 6г</p>
        <br />
        <p>
          Email: <a href="mailto:appcloodee.com@gmail.com">appcloodee.com@gmail.com</a>
        </p>
        <br />
        <p>
          When you contact us, please enter the details of your request and, if
          you have any questions regarding our Terms of Use, please include
          specific samples of the usage you wish to give our resouces. If you’re
          reporting a problem, make sure to include as much information as
          possible. Please include any screenshots or videos of issues since
          this will also help us resolve problems much sooner. Once your request
          is submitted, a member of our support staff will respond as soon as
          possible.
        </p>
        <br />
        <p>
          Basic information on Data Protection: Cloodee Service collects your
          data to be able to answer to questions, suggested, or complaints
          filed. Info <a href="https://cloodee.com/privacy">Privacy Policy</a>
        </p>
        <br />
        <br />
        <br />
      </div>
    );
  }
}

export default Contacts;
