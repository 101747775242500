import React, { Component } from 'react';
import Sound from 'react-sound';
import speach_ic from '../../../images/speach_ic.svg';
import './exercise.css';

class PlayBtn extends Component {
  constructor(props) {
    super(props);
    this.state = {
      play: Sound.status.PAUSED
    };
    this.playAudio = this.playAudio.bind(this);
  }

  playAudio() {
    this.setState({ play: Sound.status.PLAYING });
  }

  onFinishPlay() {
    this.setState({ play: Sound.status.STOPPED });
    if (this.props.onFinishPlay) {
      this.props.onFinishPlay();
    }
  }

  render() {
    return (
      <div className="exercise-soundcard" onClick={this.playAudio}>
        <img
          className={this.props.className}
          src={this.props.src || speach_ic}
          alt="speach"
        />

        <Sound
          url={this.props.audio_file}
          playStatus={this.state.play}
          onFinishedPlaying={this.onFinishPlay.bind(this)}
        />
      </div>
    );
  }
}

export default PlayBtn;
