import { put, call } from 'redux-saga/effects';
import {
  registerUserService,
  loginUserService,
  logoutUserService,
  resetPasswordService,
  changePasswordService,
  updateProfileService
} from '../services/authenticationService';

import * as types from '../redux-actions';

export function* registerSaga(payload) {
  try {
    const response = yield call(registerUserService, payload);
    yield put({ type: types.REGISTER_USER_SUCCESS, response });
  } catch (error) {
    yield put({ type: types.REGISTER_USER_ERROR, error });
  }
}

export function* loginSaga(payload) {
  try {
    const response = yield call(loginUserService, payload);
    yield put({ type: types.LOGIN_USER_SUCCESS, response });
  } catch (error) {
    yield put({ type: types.LOGIN_USER_ERROR, error });
  }
}

export function* logoutSaga(payload) {
  try {
    const response = yield call(logoutUserService, payload);
    yield put({ type: types.LOGOUT_USER_SUCCESS, response });
  } catch (error) {
    yield put({ type: types.LOGOUT_USER_ERROR, error });
  }
}

export function* resetPasswordSaga(payload) {
  try {
    const response = yield call(resetPasswordService, payload);
    yield put({ type: types.RESET_PASSWORD_SUCCESS, response });
  } catch (error) {
    yield put({ type: types.RESET_PASSWORD_ERROR, error });
  }
}

export function* changePasswordSaga(payload) {
  try {
    const response = yield call(changePasswordService, payload);
    yield put({ type: types.CHANGE_PASSWORD_SUCCESS, response });
  } catch (error) {
    yield put({ type: types.CHANGE_PASSWORD_ERROR, error });
  }
}

export function* updateProfileSaga(payload) {
  try {
    let response = yield call(updateProfileService, payload);

    if (payload.user.newPassword) {
      const passwordPayload = {
        newPassword: payload.user.newPassword,
        oldPassword: payload.user.oldPassword
      };
      const passwordResponse = yield call(
        changePasswordService,
        passwordPayload,
        payload.accessToken
      );
      response.passwordResponse = passwordResponse;
    }

    yield put({ type: types.UPDATE_PROFILE_SUCCESS, response });
  } catch (error) {
    yield put({ type: types.UPDATE_PROFILE_ERROR, error });
  }
}
