import React, { Component } from 'react';
import PlayBtn from '../PlayBtn';
import play_big_ic from '../../../../images/play_btn_big_normal.svg';
import ExerciseLogic from '../ExerciseLogic';

class Type7 extends Component {
  handleSentence = data => {
    let selectedWords = data.type7_selected_words || [];
    let sentence = data.type7_sentence;

    if (selectedWords.length !== 0) {
      sentence = data.type7_sentence.split(' ');

      // eslint-disable-next-line
      for (let i of sentence) {
        // eslint-disable-next-line
        for (let select of selectedWords) {
          let key = data.id + '-' + i;
          if (i.toLowerCase() === select.toLowerCase()) {
            sentence[sentence.indexOf(i)] = (
              <span key={key} style={{ color: '#FFD14C' }}>
                {sentence[sentence.indexOf(i)]}
              </span>
            );
          } else {
            sentence[sentence.indexOf(i)] = <span key={key}> {i} </span>;
          }
        }
      }
    }
    return sentence;
  };

  onFinishPlay() {
    this.props.selectedValue(7, true);
  }

  render() {
    const exercise = this.props.exercise;

    return (
      <div className="exercise-card-type7" align="center">
        <p> {this.handleSentence(exercise)} </p>
        <p> {exercise.type7_translation} </p>
        <PlayBtn
          className="type7_audio"
          src={play_big_ic}
          alt="type7_audio"
          audio_file={ExerciseLogic.getAudioUrl(
            this.props.language,
            exercise.type7_sentence
          )}
          onFinishPlay={this.onFinishPlay.bind(this)}
        />
      </div>
    );
  }
}

export default Type7;
