import React, { Component } from 'react';
import i18n from '../../translation/translation';

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    //logErrorToMyService(error, errorInfo);
    console.error(error);
  }

  render() {
    if (this.state.hasError) {
      console.error('Error');
      setTimeout(function() {
        window.location.href = '/';
      }, 10000);
      return (
        <div style={{ width: '100%' }}>
          <br />
          <br />
          <br />
          <h3 style={{ textAlign: 'center' }}>{i18n('unknown-error')}</h3>
        </div>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
