import React, { Component } from 'react';
import speach_round_ic from '../../../../images/speach_round_ic.svg';
import radio_unchecked from '../../../../images/radio_unchecked.svg';
import radio_checked from '../../../../images/radio_checked.svg';
import PlayBtnSimple from '../PlayBtnSimple';
import ExerciseLogic from '../ExerciseLogic';

class Type8 extends Component {
  constructor() {
    super();
    this.initialState = {
      choice1: false,
      choice2: false,
      choice3: false,
      choice4: false
    };

    this.state = this.initialState;
  }

  handleRadioCheck = param => {
    this.setState(this.initialState);

    switch (param) {
      case 0:
        this.setState({ choice1: !this.state.choice1 });
        this.props.selectedValue(12, param);
        break;
      case 1:
        this.setState({ choice2: !this.state.choice2 });
        this.props.selectedValue(12, param);
        break;
      case 2:
        this.setState({ choice3: !this.state.choice3 });
        this.props.selectedValue(12, param);
        break;
      case 3:
        this.setState({ choice4: !this.state.choice4 });
        this.props.selectedValue(12, param);
        break;
      default:
        return;
    }
  };

  handleRadioButton = param => {
    switch (param) {
      case 0:
        return (
          <img
            alt="type12_option"
            className="type12_option"
            src={this.state.choice1 ? radio_checked : radio_unchecked}
            onClick={() => this.handleRadioCheck(param)}
          />
        );

      case 1:
        return (
          <img
            alt="type12_option"
            className="type12_option"
            src={this.state.choice2 ? radio_checked : radio_unchecked}
            onClick={() => this.handleRadioCheck(param)}
          />
        );

      case 2:
        return (
          <img
            alt="type12_option"
            className="type12_option"
            src={this.state.choice3 ? radio_checked : radio_unchecked}
            onClick={() => this.handleRadioCheck(param)}
          />
        );

      case 3:
        return (
          <img
            alt="type12_option"
            className="type12_option"
            src={this.state.choice4 ? radio_checked : radio_unchecked}
            onClick={() => this.handleRadioCheck(param)}
          />
        );

      default:
        return;
    }
  };

  render() {
    const subcards = [];
    const exercise = this.props.exercise;

    exercise.type12_translation.forEach((param, index) => {
      subcards.push(
        <div
          className="div-radio"
          key={index}
          onClick={() => this.handleRadioCheck(index)}
        >
          <label className="type6_label">
            {/* <input
              type="radio"
              name="type12_option"
              className="type12_option"
              onClick={() => this.props.selectedValue(12, index)}
            /> */}
            {this.handleRadioButton(index)}
            {param}
          </label>
          <hr className="exercise-type-divider"></hr>
        </div>
      );
    });

    return (
      <div className="exercise-card-type12">
        <p>
          <PlayBtnSimple
            className="speach_round"
            alt="speach_ic"
            src={speach_round_ic}
            audio_file={ExerciseLogic.getAudioUrl(
              this.props.language,
              exercise.type12_sentence
            )}
          />{' '}
          {exercise.type12_sentence}{' '}
        </p>
        {subcards}
      </div>
    );
  }
}

export default Type8;
