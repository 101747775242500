import React, { Component } from 'react';
import './exercise.css';
import Type1 from './exercisetype/Type1';
import Type2 from './exercisetype/Type2';
import Type3 from './exercisetype/Type3';
import Type4 from './exercisetype/Type4';
import Type5 from './exercisetype/Type5';
import Type6 from './exercisetype/Type6';
import Type7 from './exercisetype/Type7';
import Type8 from './exercisetype/Type8';
import Type9 from './exercisetype/Type9';
import Type10 from './exercisetype/Type10';
import Type11 from './exercisetype/Type11';
import Type12 from './exercisetype/Type12';
import Type13 from './exercisetype/Type13';
import Type14 from './exercisetype/Type14';
import Type15 from './exercisetype/Type15';

class ExerciseCards extends Component {
  topicSelection() {
    let type = this.props.exercise.type;
    switch (type) {
      case 1:
        return <Type1 {...this.props} />;
      case 2:
        return <Type2 {...this.props} />;
      case 3:
        return <Type3 {...this.props} />;
      case 4:
        return <Type4 {...this.props} />;
      case 5:
        return <Type5 {...this.props} />;
      case 6:
        return <Type6 {...this.props} />;
      case 7:
        return <Type7 {...this.props} />;
      case 8:
        return <Type8 {...this.props} />;
      case 9:
        return <Type9 {...this.props} />;
      case 10:
        return <Type10 {...this.props} />;
      case 11:
        return <Type11 {...this.props} />;
      case 12:
        return <Type12 {...this.props} />;
      case 13:
        return <Type13 {...this.props} />;
      case 14:
        return <Type14 {...this.props} />;
      case 15:
        return <Type15 {...this.props} />;
      default:
        return <div className="exercise-card"> Unknown type </div>;
    }
  }

  render() {
    return <>{this.topicSelection()}</>;
  }
}

export default ExerciseCards;
