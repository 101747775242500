import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import '../dashboard.css';
import {
  resetProgressAction,
  loadCombinedAction
} from '../../../redux-actions/dataActions';

import { Modal, Button } from 'react-bootstrap';
import dataHelpers from '../../../utils/dataHelpers';
import i18n from '../../../translation/translation';

class ResetModal extends Component {
  render() {
    if (this.props.response.progress.hasOwnProperty('response')) {
      let response = this.props.response.progress.response;
      if (response === true) {
        var user = dataHelpers.getUser();
        if (user) {
          this.props.dispatch(loadCombinedAction(user.id));
          return <Redirect to="/select-course" />;
        }
      }
    }

    return (
      <>
        <Modal
          onHide={this.props.onHide}
          show={this.props.show}
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header className="modal-header-reset">
            <Modal.Title
              className="modal-title-exit"
              id="contained-modal-title-vcenter"
            >
              {i18n('confirm-reset')}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="modal-body-reset">
            <p>{i18n('reset-text')}</p>
            <span style={{ float: 'right', marginBottom: '0px' }}>
              <Button
                className="modal-btn-close"
                variant="default"
                onClick={this.props.onHide}
              >
                {i18n('cancel')}
              </Button>
              <Button
                className="modal-btn-exit"
                variant="default"
                onClick={() => {
                  this.props.onHide();
                  const user = dataHelpers.getUser();
                  this.props.dispatch(resetProgressAction(user));
                }}
              >
                {i18n('reset')}
              </Button>
            </span>
          </Modal.Body>
        </Modal>
      </>
    );
  }
}

export default ResetModal;
