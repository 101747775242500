import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { AppContext } from '../main/AppContext';

import {
  loadCombinedAction,
  saveProgressAction
} from '../../redux-actions/dataActions';
import dataHelpers from '../../utils/dataHelpers';

import Header from '../common/Header';
import Footer from '../common/Footer';
import Loader from '../common/Loader';
import RegisterStepHeader from './RegisterStepHeader';
import RegisterButton from './RegisterButton';
import RegisterThirdStep from './RegisterThirdStep';
import i18n from '../../translation/translation';

class SelectLevel extends Component {
  hasData = false;
  hasUser = false;

  rearm = false;

  state = {
    redirectToReminders: false,
    levelSelected: undefined
  };

  handleSelectLevel = level => {
    this.setState({
      levelSelected: level
    });
  };

  addProgress = (selected, course, program, topicId, lessonId) => {
    var found = false;
    for (const item of selected) {
      item.selected = false;
      if (
        item.packageId === course.id &&
        item.programId === program.id &&
        item.topicId === topicId
      ) {
        if (!found) {
          found = true;
          item.selected = true;
          item.topicId = topicId;
          item.lessonId = lessonId;
        }
      }
    }

    if (!found) {
      selected.push({
        packageId: course.id,
        languageId: course.languageId,
        programId: program.id,
        topicId,
        lessonId,
        selected: true
      });
    }
  };

  handleSaveEvent = e => {
    const level = this.state.levelSelected;
    if (level === undefined) return;

    var response = this.props.response.data.response;

    let user = response.user;
    var progress = user.learningProgress;
    var selected = progress.selected || [];
    let languages = response.languages;

    let last = dataHelpers.getSelectedProgress(user);
    if (!last) {
      return <Redirect to="/select-course" />;
    }

    const course = dataHelpers.getPackageById(languages, last.packageId);
    const program = dataHelpers.getProgramById(languages, last.programId);

    if (!level) {
      let topicId = program.topics[0].id;
      this.addProgress(selected, course, program, topicId);
    } else {
      let basic = program.topics.filter(function(item) {
        return item.basic;
      });
      for (let topic of basic) {
        let lessons = topic.lessons.filter(i => i.type === 0);
        let last = lessons.slice(-1)[0];
        this.addProgress(
          selected,
          course,
          program,
          topic.id,
          last ? last.id : null
        );
      }
      let nextTopic = dataHelpers.getNextItem(
        program.topics.filter(i => i.type === 0),
        basic.slice(-1)[0]
      );
      this.addProgress(
        selected,
        course,
        program,
        nextTopic.id,
        nextTopic.lessons.find(i => i.type === 0).id
      );
    }

    this.rearm = true;
    this.props.dispatch(saveProgressAction(progress));
  };

  handleStepChange = e => {
    // eslint-disable-next-line
    this.state.redirectToReminders = true;
    this.handleSaveEvent();
  };

  handleDashboardView = (left, right) => {
    this.setState({
      leftPanel: left,
      rightPanel: right,
      redirectDashboard: true
    });
  };

  render() {
    if (this.state.redirectDashboard) {
      return (
        <Redirect
          to={{
            pathname: '/dashboard',
            state: {
              leftPanel: this.state.leftPanel,
              rightPanel: this.state.rightPanel
            }
          }}
        />
      );
    }
    if (this.props.response.progress.hasOwnProperty('response') && this.rearm) {
      //TODO: update progress in user data
      //this.context.user.learningProgress = this.props.response.progress.response;
      if (this.state.redirectToReminders) {
        return <Redirect to="/select-reminders" />;
      } else {
        let user = dataHelpers.getUser();
        this.props.dispatch(loadCombinedAction(user.id));
        return <Redirect to="/dashboard" />;
      }
    }

    if (!this.props.response.data.hasOwnProperty('response')) {
      let user = JSON.parse(localStorage.getItem('user'));
      if (user) {
        this.props.dispatch(loadCombinedAction(user.id));
        return <Loader />;
      } else {
        return <Redirect to="/login" />;
      }
    } else {
      var response = this.props.response.data.response;

      localStorage.setItem('user', JSON.stringify(response.user));

      let user = response.user;
      let languages = response.languages;

      let last = dataHelpers.getSelectedProgress(user);
      var packge, program;
      if (last) {
        packge = dataHelpers.getPackageById(languages, last.packageId);
        program = dataHelpers.getProgramById(languages, last.programId);
        if (!program) last = null;
      }

      return (
        <>
          <Header
            {...this.props}
            data={user}
            package={packge}
            program={program}
            isLogged={last != null}
            viewHandler={this.handleDashboardView.bind(this)}
          />
          <RegisterStepHeader title={i18n('choose-level')} />
          <RegisterThirdStep
            onSelectLevel={this.handleSelectLevel.bind(this)}
          />
          <RegisterButton
            handleClickEvent={this.handleStepChange.bind(this)}
            {...this.state}
          />
          <Footer />
        </>
      );
    }
  }
}

SelectLevel.contextType = AppContext;

const mapStateToProps = response => ({ response });

export default connect(mapStateToProps)(SelectLevel);
