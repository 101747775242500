import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { InputGroup, FormControl, Button } from 'react-bootstrap';
import { connect } from 'react-redux';

import Header from '../common/Header';
import Footer from '../common/Footer';
import SocialButton from './SocialButton';

import { loginUserAction } from '../../redux-actions/authActions';
import { setCookie } from '../../utils/cookies';

import icon_vk from '../../images/icon_vk.svg';
import icon_fb from '../../images/icon_fb.svg';
import icon_g from '../../images/icon_g.svg';
import i18n from '../../translation/translation';

class Login extends Component {
  constructor(props) {
    super(props);

    this.state = {
      logged: false,
      user: {},
      currentProvider: ''
    };
    this.nodes = {};

    this.onLoginSuccess = this.onLoginSuccess.bind(this);
    this.onLoginFailure = this.onLoginFailure.bind(this);
    this.onLogoutSuccess = this.onLogoutSuccess.bind(this);
    this.onLogoutFailure = this.onLogoutFailure.bind(this);
    this.logout = this.logout.bind(this);
  }

  setNodeRef(provider, node) {
    if (node) {
      this.nodes[provider] = node;
    }
  }

  onLoginSuccess(user) {
    console.log(user);

    this.setState({
      logged: true,
      currentProvider: user._provider,
      user
    });

    var network;
    if (user.provider === 'facebook') network = 'fb';
    else if (user.provider === 'google') network = 'g';
    else network = 'vk';
    const token = network === 'g' ? user.token.idToken : user.token.accessToken;

    const data = {
      network,
      token
    };

    this.props.dispatch(loginUserAction(data));
  }

  onLoginFailure(err) {
    console.error(err);

    if (err === 'User already connected') {
      this.logout();
    }

    this.setState({
      logged: false,
      currentProvider: '',
      user: {}
    });
  }

  onLogoutSuccess() {
    this.setState({
      logged: false,
      currentProvider: '',
      user: {}
    });
  }

  onLogoutFailure(err) {
    console.error(err);
  }

  logout() {
    const { logged, currentProvider } = this.state;

    if (logged && currentProvider) {
      this.nodes[currentProvider].props.triggerLogout();
    }
  }

  onHandleLogin = event => {
    event.preventDefault();

    let email = event.target.email.value;
    let password = event.target.password.value;

    const data = {
      email,
      password
    };

    this.props.dispatch(loginUserAction(data));
  };

  componentDidMount() {
    document.title = i18n('authorize');
  }

  render() {
    let isSuccess, message;

    if (this.props.response.login.hasOwnProperty('response')) {
      var response = this.props.response.login.response;
      isSuccess = response && response.accessToken;
      message = response
        ? i18n('user-not-found')
        : i18n('server-not-responding');

      if (isSuccess) {
        let THREE_MONTHS = 3 * 30 * 24; // hrs
        setCookie('accessToken', response.accessToken, THREE_MONTHS);
        localStorage.setItem('user', JSON.stringify(response.user));
        return <Redirect to="/dashboard" />;
      }
    }

    return (
      <>
        <Header {...this.props} toMain={true} />
        <div className="PageContent">
          <div className="LogContainer">
            <h3 align="center" className="LogHeaderTitle">
              {i18n('authorize')}
            </h3>

            <form
              className="Inputs"
              onSubmit={this.onHandleLogin}
              autoComplete="on"
            >
              <div align="center">
                <InputGroup className="LogInputField">
                  <FormControl
                    id="email"
                    name="email"
                    placeholder={i18n('email')}
                    aria-label={i18n('email')}
                    aria-describedby="basic-addon1"
                    className="LogInputControl"
                  />
                </InputGroup>
                <InputGroup className="LogInputField">
                  <FormControl
                    id="password"
                    name="password"
                    type="password"
                    placeholder={i18n('password')}
                    aria-label={i18n('password')}
                    aria-describedby="basic-addon2"
                    className="LogInputControl"
                  />
                </InputGroup>
                <Button
                  style={{ backgroundColor: '#683da1' }}
                  className="LoginButton"
                  type="submit"
                >
                  {i18n('login')}
                </Button>
              </div>
            </form>

            {!isSuccess ? (
              <p
                align="center"
                className="LinkRestoreText"
                style={{ color: '#EB1F7F' }}
              >
                {message}
              </p>
            ) : (
              {}
            )}

            <Link to="passrecovery">
              <p align="center" className="LinkRestoreText">
                {i18n('forgot-password')}
              </p>
            </Link>

            <div className="social" align="center">
              <SocialButton
                provider="vkontakte"
                appId="7108860"
                onLoginSuccess={this.onLoginSuccess}
                onLoginFailure={this.onLoginFailure}
                onLogoutSuccess={this.onLogoutSuccess}
                getInstance={this.setNodeRef.bind(this, 'vkontakte')}
                key={'vkontakte'}
              >
                <img className="icon_vk" alt="icon_vk" src={icon_vk} />
              </SocialButton>

              <SocialButton
                provider="facebook"
                appId="414728655815414"
                onLoginSuccess={this.onLoginSuccess}
                onLoginFailure={this.onLoginFailure}
                onLogoutSuccess={this.onLogoutSuccess}
                getInstance={this.setNodeRef.bind(this, 'facebook')}
                key={'facebook'}
              >
                <img className="icon_fb" alt="icon_fb" src={icon_fb} />
              </SocialButton>

              <SocialButton
                provider="google"
                appId="919234281206-kbvt2eteec46pfif0oe4oolpvh5lsqem.apps.googleusercontent.com"
                onLoginSuccess={this.onLoginSuccess}
                onLoginFailure={this.onLoginFailure}
                onLogoutSuccess={this.onLogoutSuccess}
                onLogoutFailure={this.onLogoutFailure}
                getInstance={this.setNodeRef.bind(this, 'google')}
                key={'google'}
              >
                <img className="icon_g" alt="icon_g" src={icon_g} />
              </SocialButton>
            </div>
          </div>
        </div>
        <Footer />
      </>
    );
  }
}

const mapStateToProps = response => ({ response });

export default connect(mapStateToProps)(Login);
