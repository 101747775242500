import React, { Component } from 'react';
import congrats_ic from '../../../images/congrats_ic.svg';
import i18n from '../../../translation/translation';
import Sound from 'react-sound';
import { backendAudio } from '../../../config';
import dataHelpers from '../../../utils/dataHelpers';
import './completion.css';
import { refreshDataAction } from '../../../redux-actions/miscActions';
import { connect } from 'react-redux';

class SuccessfulCompletion extends Component {
  constructor(props) {
    super(props);
    this.props.dispatch(refreshDataAction());
  }

  onContinue() {
    if (this.props.location.state.isBlitz) {
      //this.props.history.push('/dashboard');
      window.location.href = '/dashboard';
    } else {
      this.props.history.push(
        '/endlesson',
        this.props.location.state.endLessonState
      );
    }
  }

  render() {
    let status = this.props.location.state.newStatus || {};
    let isBlitz = this.props.location.state.isBlitz;

    return (
      <div className="sc-container">
        <div className="end-lesson-div" align="center">
          <img className="sc-img" src={congrats_ic} alt="congrats_ic" />
          <label className="sc-lbl"> {i18n('congratulations')} </label>
          {isBlitz ? (
            <label>{i18n('successful-pass')}</label>
          ) : (
            <label>
              {' '}
              {i18n('you-ve-got') + ' '}
              <b>{status.points}</b>
              {' ' +
                dataHelpers.num2str18n(status.points, 'points-case') +
                ' ' +
                i18n('and-got-status') +
                ' '}
              <b>{status.name}</b>
            </label>
          )}
          <button className="sc-btn" onClick={this.onContinue.bind(this)}>
            {i18n('continue')}
          </button>
        </div>

        <Sound
          url={backendAudio + 'success.mp3'}
          playStatus={Sound.status.PLAYING}
        />
      </div>
    );
  }
}

const mapStateToProps = response => ({ response });

export default connect(mapStateToProps)(SuccessfulCompletion);
