import React, { Component } from 'react';
import '../dashboard.css';
import AvatarEditor from 'react-avatar-editor';
//import Dropzone from 'react-dropzone';
import avatar_ic from '../../../images/avatar_ic.svg';
import i18n from '../../../translation/translation';
import { backend } from '../../../config';
import dataHelpers from '../../../utils/dataHelpers';
import { getCookie } from '../../../utils/cookies';
import { loadCombinedAction } from '../../../redux-actions/dataActions';

class ProfilePhoto extends Component {
  constructor(props) {
    super(props);
    this.state = {
      save: false,
      image: props.data.avatar === undefined ? avatar_ic : props.data.avatar,
      label:
        props.data.avatar === undefined
          ? i18n('upload-photo')
          : i18n('change-photo'),
      allowZoomOut: false,
      position: { x: 0.5, y: 0.5 },
      scale: 1,
      rotate: 0,
      borderRadius: 0,
      preview: null,
      width: 300,
      height: 300,
      fileUpload: React.createRef(),
      class: 'editor-canvas',
      linkAddState: '',
      linkMinState: ''
    };

    this.baseState = this.state;
  }

  showFileUpload = () => this.state.fileUpload.current.click();

  onChangeHandler = event => {
    this.setState({
      image: event.target.files[0],
      save: !this.state.save,
      borderRadius: 50,
      label: i18n('save'),
      class: 'editor-canvas-edit'
    });
  };

  onCancelHandler = () => this.setState(this.baseState);

  handleScale = e => {
    const scale = parseFloat(e.target.value);
    this.setState({ scale });
  };

  handleScaleMin = () => {
    const scale = parseFloat(this.state.scale - 0.05);
    this.setState({ scale });
    return false;
  };

  handleScaleAdd = () => {
    const scale = parseFloat(this.state.scale + 0.05);
    this.setState({ scale });
    return false;
  };

  handlePositionChange = position => {
    this.setState({ position });
  };

  // TODO: refactor to Saga?
  saveToServer = async () => {
    const canvas = this.editor.getImageScaledToCanvas();
    var dataURL = canvas.toDataURL();
    var blobBin = atob(dataURL.split(',')[1]);
    var array = [];
    for (var i = 0; i < blobBin.length; i++) {
      array.push(blobBin.charCodeAt(i));
    }
    var file = new Blob([new Uint8Array(array)], { type: 'image/png' });

    const user = dataHelpers.getUser();

    this.setState({ uploading: true });

    const avatarUrl = backend + '/api/upload/avatar';
    const userUrl = backend + '/api/users/' + user.id + '/update';

    try {
      const formData = new FormData();
      formData.append('file', file);

      var res = await fetch(avatarUrl, {
        method: 'POST',
        body: formData
      });
      var json = await res.json();
      let imageUrl = json.url;

      let request = JSON.stringify({
        avatar: backend + imageUrl
      });
      res = await fetch(userUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-Access-Token': getCookie('accessToken')
        },
        body: request
      });
      json = await res.json();
      this.setState({
        uploading: false,
        image: imageUrl
      });
      this.props.dispatch(loadCombinedAction(user.id));
      this.setState({
        ...this.baseState,
        image: dataURL
      });
    } catch (err) {
      console.error(err);
    }
  };

  setEditorRef = editor => (this.editor = editor);

  render() {
    return (
      <div className="card photo-card">
        <div className="status-header general-header">{i18n('add-avatar')}</div>
        <div className="collapse show">
          <div className="card-body">
            <div className="pp-image-container" align="center">
              <AvatarEditor
                ref={this.setEditorRef}
                scale={parseFloat(this.state.scale)}
                width={this.state.width}
                height={this.state.height}
                position={this.state.position}
                onPositionChange={this.handlePositionChange}
                rotate={parseFloat(this.state.rotate)}
                borderRadius={
                  this.state.width / (100 / this.state.borderRadius)
                }
                image={this.state.image}
                className={this.state.class}
                border={0}
              />
            </div>

            <div
              className="scaling-div"
              align="center"
              style={{ display: !this.state.save ? 'none' : '' }}
            >
              <span onClick={this.handleScaleMin} className="minus-scale">
                {' '}
                <b> - </b>{' '}
              </span>
              <input
                name="scale"
                type="range"
                className="pp-scaler"
                onChange={this.handleScale}
                min={this.state.allowZoomOut ? '0.1' : '1'}
                max="2"
                step="0.01"
                value={this.state.scale}
              />
              <span
                href="#"
                onClick={this.handleScaleAdd}
                className="plus-scale"
              >
                {' '}
                <b> + </b>
              </span>
            </div>

            {!this.state.save ? (
              <>
                <input
                  type="file"
                  id="selectImage"
                  onChange={this.onChangeHandler}
                  ref={this.state.fileUpload}
                  hidden
                />
                <button
                  className="pp-button"
                  onClick={this.showFileUpload.bind(this)}
                >
                  {' '}
                  {this.state.label}{' '}
                </button>
              </>
            ) : (
              <>
                <button className="pp-button" onClick={this.saveToServer}>
                  {' '}
                  {this.state.label}{' '}
                </button>
                <div
                  align="center"
                  className="pp-cancel"
                  onClick={this.onCancelHandler}
                >
                  {i18n('cancel-cap')}
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default ProfilePhoto;
