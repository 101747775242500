// Data helpers
import i18n from '../translation/translation';
import detectBrowserLanguage from 'detect-browser-language';

class DataHelpers {
  getPackageById(languages, id) {
    for (const language of languages || []) {
      for (const pack of language.packages || []) {
        if (pack.id === id) {
          return pack;
        }
      }
    }
    return null;
  }

  getProgramById(languages, id) {
    for (const language of languages || []) {
      for (const pack of language.packages || []) {
        for (const program of pack.programs || []) {
          if (program.id === id) {
            return program;
          }
        }
      }
    }
    return null;
  }

  getTopicById(languages, id) {
    for (const language of languages || []) {
      for (const pack of language.packages || []) {
        for (const program of pack.programs || []) {
          for (const topic of program.topics || []) {
            if (topic.id === id) {
              return topic;
            }
          }
        }
      }
    }
    return null;
  }

  getLessonById(languages, id) {
    for (const language of languages || []) {
      for (const pack of language.packages || []) {
        for (const program of pack.programs || []) {
          for (const topic of program.topics || []) {
            for (const lesson of topic.lessons || []) {
              if (lesson.id === id) {
                return lesson;
              }
            }
          }
        }
      }
    }
    return null;
  }

  getUiLanguages() {
    return {
      EN: 'ENG',
      AR: 'ARA',
      ES: 'ESL',
      IT: 'ITA',
      ZH: 'CHI',
      DE: 'DEU',
      RU: 'RUS',
      PT: 'POR',
      TR: 'TUR',
      FR: 'FRA'
    };
  }

  getUiLanguage() {
    var languageCode = localStorage.getItem('ui-language');

    if (!languageCode) {
      let browserLang = detectBrowserLanguage();
      if (browserLang) {
        languageCode = browserLang.toUpperCase().substring(0, 2);
      }

      let languages = this.getUiLanguages();
      if (!languageCode || !languages[languageCode]) {
        languageCode = 'EN';
      }
    }
    return languageCode;
  }

  getPreferredLanguageId(user, languages) {
    // let last = this.getSelectedProgress(user);
    // if (last) {
    //   return last.languageId;
    // }
    let lang = localStorage.getItem('ui-language');

    var language = (languages || []).find(i => i.code === lang);
    if (language) return language.id;

    language = (languages || []).find(i => i.code === 'EN');
    if (language) return language.id;

    language = (languages || []).find(i => i.code === 'RU');
    if (language) return language.id;

    return null;
  }

  getSelectedProgress(user) {
    let progresList =
      user.learningProgress && user.learningProgress.selected
        ? user.learningProgress.selected
        : null;
    return (
      (progresList && progresList.filter(item => item.selected).slice(-1)[0]) ||
      null
    );
  }

  getNextLesson(lessons, lesson) {
    var next = false;
    for (let item of lessons) {
      if (next) {
        return item;
      }
      if (item.id === lesson.id) {
        next = true;
      }
    }
    return null;
  }

  getNextItem(arr, current) {
    if (!current) {
      return arr[0];
    }
    var next = false;
    for (let item of arr) {
      if (next) {
        return item;
      }
      if (item.id === current.id) {
        next = true;
      }
    }
    return null;
  }

  getNextProgressItem(user, languages, currentLesson) {
    let selected =
      user.learningProgress && user.learningProgress.selected
        ? user.learningProgress.selected
        : [];
    let last = Object.assign(
      {},
      selected.filter(item => item.selected).slice(-1)[0] || {}
    );

    // We cannot use last progress item here because it is saving asynchronously
    // but might reconsider to delay until it saved

    // let program = this.getProgramById(languages, last.programId);
    // var topic = this.getTopicById(languages, last.topicId);
    // if (!topic) {
    //   topic = program.topics.filter(i => i.type === 0)[0];
    // }

    var topic = this.getTopicById(languages, currentLesson.topicId);
    let program = this.getProgramById(languages, topic.programId);

    let lessons = topic.lessons.filter(item => item.type === 0);
    let lesson =
      currentLesson ||
      //this.getLessonById(languages, last.lessonId) ||
      lessons[0];
    var nextLesson = this.getNextItem(lessons, lesson);
    if (!nextLesson) {
      let topics = program.topics.filter(item => item.type === 0);
      let nextTopic = this.getNextItem(topics, topic);
      if (nextTopic) {
        topic = nextTopic;
        let lessons = topic.lessons.filter(item => item.type === 0);
        nextLesson = lessons[0];
      }
    }

    if (nextLesson) {
      last.lessonId = nextLesson.id;
    } else {
      last.finished = true;
    }
    last.topicId = topic.id;

    return last;
  }

  getUserStatus(user, statuses) {
    let points = user.statistics ? user.statistics.points : 0;
    return this.getStatusByPoints(points, statuses);
  }

  getStatusByPoints(points, list) {
    let statuses = list.sort(function(a, b) {
      return Math.sign(a.points - b.points);
    });
    var nextPoints = 0;
    let userStatus = statuses[0];

    for (const status of statuses || []) {
      if (status.points > userStatus.points && status.points < points) {
        userStatus = status;
      }

      if (nextPoints === 0 && status.points > userStatus.points) {
        userStatus.nextPoints = status.points;
        userStatus.toNextStatus = userStatus.nextPoints - points;
        break;
      }
    }
    return userStatus;
  }

  getUser() {
    let json = localStorage.getItem('user');
    var user = undefined;
    try {
      user = JSON.parse(json);
    } catch (e) {
      console.error(e);
    }
    return user;
  }

  getBlitzTopics(topicId, topics) {
    var blitz = [];
    for (let topic of topics) {
      if (topic.id === topicId) {
        return blitz;
      } else if (topic.type === 2) {
        blitz = [];
      } else if (topic.type === 0) {
        blitz.push(topic);
      }
    }
    return blitz;
  }

  checkData(props) {
    if (!props.response.data.hasOwnProperty('response')) {
      if (this.getUser()) {
        return '/dashboard';
      } else {
        return '/login';
      }
    }
    return null;
  }

  getLastLessonId(languages, programProgress) {
    // if have lessonId just return it
    if (programProgress.lessonId) {
      return programProgress.lessonId;
    }

    // if have topicId, get first lesson id
    if (programProgress.topicId) {
      let lastTopic = this.getTopicById(languages, programProgress.topicId);

      let firstLesson = lastTopic.lessons.find(i => i.type === 0);
      if (firstLesson) {
        return firstLesson.id;
      }
    }
    // if have program, get first lesson id from all topics
    if (programProgress.programId) {
      let program = this.getProgramById(languages, programProgress.programId);

      for (let topic of program.topics) {
        let firstLesson = topic.lessons.find(i => i.type === 0);
        if (firstLesson) {
          return firstLesson.id;
        }
      }
    }
    // TODO: should we enumerate programs of language?

    // everything fails
    return null;
  }

  flattenLessons(program) {
    var lessons = [];
    for (let topic of program.topics) {
      lessons = lessons.concat(topic.lessons);
    }
    return lessons;
  }

  lessonHasLearned(data, lesson) {
    let lessonId = lesson.id;
    let topicId = lesson.topicId;
    let topic = this.getTopicById(data.languages, topicId);
    let programId = topic.programId;
    let program = this.getProgramById(data.languages, programId);
    let allLessons = this.flattenLessons(program).filter(i => i.type === 0);

    let selected = data.user.learningProgress.selected || [];
    let programProgress = selected.filter(i => i.programId === programId);

    var lastIndex = 0;
    for (const p of programProgress) {
      let ix = allLessons.findIndex(i => i.id === p.lessonId);
      lastIndex = Math.max(ix, lastIndex);
    }
    let currIndex = allLessons.findIndex(i => i.id === lessonId);

    return currIndex >= lastIndex;
  }

  topicHasLearned(data, lesson) {
    // Topic learned if last lesson is learned
    let topicId = lesson.topicId;
    let topic = this.getTopicById(data.languages, topicId);
    let lessons = topic.lessons.filter(i => i.type === 0);
    return lesson.id === (lessons.slice(-1)[0] || {}).id;
  }

  num2str(n, text_forms) {
    n = Math.abs(n) % 100;
    var n1 = n % 10;
    if (n > 10 && n < 20) {
      return text_forms[2];
    }
    if (n1 > 1 && n1 < 5) {
      return text_forms[1];
    }
    if (n1 === 1) {
      return text_forms[0];
    }
    return text_forms[2];
  }

  num2str18n(n, text_forms) {
    return this.num2str(n, i18n(text_forms).split(','));
  }

  getEpochDay(date) {
    return Math.floor(
      (date.getTime() - date.getTimezoneOffset() * 60000) / 8.64e7
    );
  }

  getDefaultFreeLessons(props) {
    if (props.response.data.hasOwnProperty('response')) {
      return props.response.data.response.config.freeLessons;
    }
    return 2;
  }

  getFreeLessons(props) {
    let v = JSON.parse(localStorage.getItem('free-lessons') || '{}');
    if (!v) {
      v = {};
    }
    if (!v.date || v.num === undefined) {
      v.num = this.getDefaultFreeLessons(props);
      v.date = new Date().getTime();
      localStorage.setItem('free-lessons', JSON.stringify(v));
    }

    let eDay = this.getEpochDay(new Date(v.date));
    let eDayNow = this.getEpochDay(new Date());
    if (eDay !== eDayNow) {
      v.num = this.getDefaultFreeLessons(props);
      localStorage.setItem('free-lessons', JSON.stringify(v));
    }

    return v;
  }

  useFreeLesson(props) {
    let v = this.getFreeLessons(props);
    let num = v.num;

    v.num = num - 1 > 0 ? num - 1 : 0;
    v.date = new Date().getTime();
    localStorage.setItem('free-lessons', JSON.stringify(v));
  }

  hasFreeLessons(props) {
    let v = this.getFreeLessons(props);
    return v.num > 0;
  }

  isPremium(props) {
    //TODO
    //return false;

    if (props.response.subscription.hasOwnProperty('response')) {
      let subscription = props.response.subscription.response;
      if (subscription.status === 'canceled') {
        let end = subscription.current_period_end;
        let now = new Date().getTime() / 1000;
        let seconds = end - now;
        if (seconds > 0) {
          return true;
        }
      } else {
        return true;
      }
    }
    return false;
  }
}

var dataHelpers = new DataHelpers();

export default dataHelpers;
