import React, { Component } from 'react';
import { Modal, Button } from 'react-bootstrap';
import i18n from '../../../translation/translation';
import dataHelpers from '../../../utils/dataHelpers';
import LoaderItem from '../../common/LoaderItem';
import { backend } from '../../../config';

import {
  CardNumberElement,
  CardExpiryElement,
  CardCVCElement,
  StripeProvider,
  Elements,
  injectStripe
} from 'react-stripe-elements';
import './buyform.css';

const createOptions = () => {
  return {
    style: {
      base: {
        fontSize: '15px',
        color: '#424770',
        '::placeholder': {
          color: '#aab7c4'
        },
        padding: '8px'
      },
      invalid: {
        color: '#9e2146'
      }
    }
  };
};

class _SplitForm extends React.Component {
  state = {
    errorMessage: null,
    loading: false
  };

  showCardError = error => {
    console.error(error);

    this.setState({
      loading: false,
      errorMessage: error
    });
  };

  orderComplete = () => {
    console.info('success');

    this.setState({
      loading: false,
      errorMessage: null
    });
    this.props.onHide();
  };

  handleSubscription = subscription => {
    console.log(subscription);

    const { latest_invoice } = subscription;
    const { payment_intent } = latest_invoice;

    if (payment_intent) {
      const { client_secret, status } = payment_intent;

      if (
        status === 'requires_action' ||
        status === 'requires_payment_method'
      ) {
        this.props.stripe
          .confirmCardPayment(client_secret)
          .then(function(result) {
            if (result.error) {
              // Display error message in your UI.
              // The card was declined (i.e. insufficient funds, card has expired, etc)
              this.showCardError(result.error);
            } else {
              // Show a success message to your customer
              this.confirmSubscription(subscription.id);
            }
          });
      } else {
        // No additional information was needed
        // Show a success message to your customer
        this.orderComplete(subscription);
      }
    } else {
      this.orderComplete(subscription);
    }
  };

  confirmSubscription = subscriptionId => {
    return fetch(backend + 'subscription', {
      method: 'post',
      headers: {
        'Content-type': 'application/json'
      },
      body: JSON.stringify({
        subscriptionId: subscriptionId
      })
    })
      .then(function(response) {
        return response.json();
      })
      .then(function(subscription) {
        this.orderComplete(subscription);
      });
  };

  handleSubmit = ev => {
    ev.preventDefault();

    if (this.props.stripe) {
      this.setState({ loading: true });

      let user = dataHelpers.getUser();
      const cardElement = this.props.elements.getElement('cardNumber');
      this.props.stripe
        .createPaymentMethod({
          type: 'card',
          card: cardElement,
          billing_details: { email: user.email }
        })
        .then(result => {
          if (result.paymentMethod) {
            let paymentMethod = result.paymentMethod;
            console.log('Received Stripe PaymentMethod:', paymentMethod);

            fetch(backend + 'create-customer', {
              method: 'post',
              headers: {
                'Content-Type': 'application/json'
              },
              body: JSON.stringify({
                userId: user.id,
                email: user.email,
                paymentMethod: paymentMethod.id,
                planId: '1_month'
              })
            })
              .then(response => {
                return response.json();
              })
              .then(result => {
                if (result.error) {
                  this.setState({
                    loading: false,
                    errorMessage: result.error.message
                  });
                } else {
                  this.handleSubscription(result);
                }
              });
          } else {
            console.error(result.error);
          }
        });
    } else {
      console.log("Stripe.js hasn't loaded yet.");
    }
  };

  render() {
    return (
      <form onSubmit={this.handleSubmit}>
        {/* <label>
          Владелец карты
          <CardElement {...createOptions(this.props.fontSize)} />
        </label> */}
        <label>
          {i18n('card-number')}
          <CardNumberElement {...createOptions(this.props.fontSize)} />
        </label>
        <label className="half-field">
          {i18n('expire-date')}
          <CardExpiryElement {...createOptions(this.props.fontSize)} />
        </label>
        <label className="half-field right">
          {i18n('cvc-code')}
          <CardCVCElement {...createOptions(this.props.fontSize)} />
        </label>
        <label className="error-message">{this.state.errorMessage}</label>
        {this.state.loading ? (
          <span>
            <LoaderItem />
          </span>
        ) : (
          <></>
        )}

        <div>
          <Button
            className="modal-btn-close"
            variant="default"
            onClick={this.props.onHide}
          >
            {i18n('cancel')}
          </Button>
          <Button
            type="submit"
            className="modal-btn-exit"
            onClick={this.props.onPurchase}
            variant="default"
          >
            {i18n('buy')}
          </Button>
        </div>
      </form>
    );
  }
}

const SplitForm = injectStripe(_SplitForm);

class BuySubscriptionModal extends Component {
  render() {
    return (
      <Modal
        show={this.props.show}
        aria-labelledby="contained-modal-title-vcenter"
        className="modal-buy-subscription"
        centered
      >
        <Modal.Header className="modal-header-exercise">
          <Modal.Title
            className="modal-title-exercise"
            id="contained-modal-title-vcenter"
          >
            <div className="modal-header-content">
              <label> {i18n('buy-subsciption-title')} </label>
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="modal-body-buy-subscription">
          <p> {i18n('buy-details')} </p>
          <StripeProvider
            apiKey="pk_test_uRl2FTdsXCB4NEqwnvIUFCYP00kA13HGoV"
            locale="ru"
          >
            <Elements tag="Subscription">
              <SplitForm {...this.props} />
            </Elements>
          </StripeProvider>
        </Modal.Body>
      </Modal>
    );
  }
}

export default BuySubscriptionModal;
