/*
английский = ENG
арабский = ARA
испанский = ESL
итальянский = ITA
китайский = CHI
немецкий = DEU
португальский =  POR
русский = RUS
турецкий = TUR
французский = FRA
*/

import ARA from './AR.json';
import DEU from './DE.json';
import ENG from './EN.json';
import ESL from './ES.json';
import FRA from './FR.json';
import ITA from './IT.json';
import POR from './PT.json';
import RUS from './RU.json';
import TUR from './TR.json';
import CHI from './ZH.json';

import dataHelpers from '../utils/dataHelpers';

const defaultLanguage = 'ENG';
var languageCode = null;

export function setLanguage(code) {
  languageCode = code ? code.toUpperCase() : defaultLanguage;
  localStorage.setItem('ui-language', languageCode);
}

export default function i18n(key) {
  var result;

  if (!languageCode) {
    languageCode = dataHelpers.getUiLanguage();
  }

  switch (languageCode) {
    case 'RU':
      result = RUS[key];
      break;
    case 'AR':
      result = ARA[key];
      break;
    case 'ZH':
      result = CHI[key];
      break;
    case 'DE':
      result = DEU[key];
      break;
    case 'FR':
      result = FRA[key];
      break;
    case 'IT':
      result = ITA[key];
      break;
    case 'PT':
      result = POR[key];
      break;
    case 'ES':
      result = ESL[key];
      break;
    case 'TR':
      result = TUR[key];
      break;
    default:
      result = ENG[key];
      break;
  }

  if (result === undefined) {
    result = ENG[key];
  }
  if (result === undefined) {
    result = '[no translation]';
  }

  return result;
}
