import React, { Component } from 'react';
import i18n from '../../../../translation/translation';

class Type11 extends Component {
  constructor() {
    super();
    this.state = {
      help: ''
    };
  }

  userAnswer = e => {
    // e.target.value
    if (this.state.help.length <= 0) {
      this.setState({ help: '' }, () =>
        this.props.selectedValue(11, this.state.help)
      );
    }

    this.props.selectedValue(11, this.state.help);
  };

  initiateHelp = () => {
    const exercise = this.props.exercise;
    let initialAnswer = exercise.type11_correct_answers;
    let answer = null;
    let answers = [];

    try {
      answers = initialAnswer.split(/[;]\s*/);
      if (answers.length > 0) {
        answer = answers[0]; // always use first answer for type 11
      } else {
        return;
      }
    } catch (e) {
      answer = initialAnswer;
    }

    this.setState(
      {
        help: answer
      },
      () => this.userAnswer()
    );
  };

  render() {
    return (
      <div className="exercise-card-type11">
        <textarea
          rows="4"
          className="ex-text-area11"
          // onChange={this.userAnswer}
          onChange={e =>
            this.setState({ help: e.target.value }, () => this.userAnswer())
          }
          value={this.state.help}
        ></textarea>
        <p
          className="ex-help-link clickable"
          onClick={() => this.initiateHelp()}
        >
          {' '}
          {i18n('need-help')}{' '}
        </p>
      </div>
    );
  }
}

export default Type11;
