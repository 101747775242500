import React, { Component } from 'react';
import '../dashboard.css';
import i18n from '../../../translation/translation';
import SwitchSetting from './SwitchSetting';

class SoundsAndVoice extends Component {
  render() {
    const arr = [
      i18n('microphone'),
      i18n('voice-guidance'),
      i18n('sound-effects')
    ];
    const switchItem = [];

    arr.forEach(name =>
      switchItem.push(<SwitchSetting class="sv" key={name} id={name} />)
    );

    return (
      <div className="card sounds-exit-card">
        <div className="status-header general-header">
          {' '}
          {i18n('sounds-microphone-setting')}{' '}
        </div>
        <div className="collapse show">
          <div className="card-body">
            <div className="switch-div">{switchItem}</div>

            <button className="btn btn-default general-reset-btn">
              {i18n('save')}
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default SoundsAndVoice;
