import React from 'react';
import lang_orange from '../../../images/lang_orange.svg';
import lang_pink from '../../../images/lang_pink.svg';
import { setLanguageAction } from '../../../redux-actions/miscActions';
import { loadCombinedAction } from '../../../redux-actions/dataActions';
import dataHelpers from '../../../utils/dataHelpers';

class LanguageSelector extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false
    };
    this.languages = dataHelpers.getUiLanguages();
  }

  getCode = lang => {
    for (const key in this.languages) {
      const value = this.languages[key];
      if (value === lang) {
        return key;
      }
    }
    return 'EN';
  };

  selectLanguage(element) {
    let lang = element.currentTarget.innerText;
    let code = this.getCode(lang);
    this.props.dispatch(setLanguageAction(code));
    let user = JSON.parse(localStorage.getItem('user'));
    if (user) {
      this.props.dispatch(loadCombinedAction(user.id));
    }
    setTimeout(this.hidePopup.bind(this), 100);
  }

  showPopup() {
    this.setState({ show: true });
  }

  hidePopup() {
    this.setState({ show: false });
  }

  render() {
    var languageCode = dataHelpers.getUiLanguage();
    let language = this.languages[languageCode];
    var items = [];
    for (const key in this.languages) {
      const value = this.languages[key];
      items.push(
        <li key={value} onClick={this.selectLanguage.bind(this)}>
          {value}
        </li>
      );
    }

    return (
      <div
        className={
          'lang-selector clickable' + (this.props.general ? ' general' : '')
        }
        onClick={this.showPopup.bind(this)}
      >
        <img
          src={this.props.general ? lang_pink : lang_orange}
          alt="lang selector"
        />
        <p>{language}</p>
        <ul
          onMouseLeave={this.hidePopup.bind(this)}
          style={{ display: this.state.show ? 'block' : 'none' }}
        >
          {items}
        </ul>
      </div>
    );
  }
}

export default LanguageSelector;
