import React from 'react';
import program_ic from '../../../images/program_ic.svg';
import notification_ic from '../../../images/notification_ic.svg';
import rating_ic from '../../../images/rating_ic.svg';
import { backend } from '../../../config';

import { Nav, Navbar } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Avatar from '../Avatar';
import LanguageSelector from './LanguageSelector';

class UserHeader extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      random: 0
    };
    window.onMyNotification = this.onChange.bind(this);
  }

  componentWillUnmount() {
    window.onMyNotification = null;
  }

  onChange() {
    console.log('received notify update');
    this.setState({
      random: this.state.random + 1
    });
  }

  render() {
    var json = localStorage.getItem('notifications') || '[]';
    var items = JSON.parse(json);
    var read = Number(localStorage.getItem('read-notifications') || '0');

    let unreadCount = items.length - read;
    return (
      <>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="mr-auto"> </Nav>
          <Nav>
            <div className="center-ic">
              <Link
                to={{
                  pathname: '/select-program',
                  state: {
                    isLogged: this.props.isLogged,
                    redirectDashboard: false,
                    fromHeader: true
                  }
                }}
              >
                <img className="program" alt="program" src={program_ic} />
              </Link>

              <Link
                to={{
                  pathname: '/select-course',
                  state: {
                    isLogged: this.props.isLogged,
                    redirectDashboard: false
                  }
                }}
              >
                <img
                  className="course"
                  alt="course"
                  src={
                    backend +
                    (
                      this.props.package || {
                        icon: 'images/topics/default.png'
                      }
                    ).icon
                  }
                />
              </Link>
            </div>

            <LanguageSelector {...this.props} />

            <div className="notification-container">
              {unreadCount > 0 ? (
                <div className="red-oval">
                  <span>{unreadCount}</span>
                </div>
              ) : (
                <></>
              )}
              <img
                className="notification clickable"
                onClick={() =>
                  this.props.viewHandler('notification', 'userstatus')
                }
                src={notification_ic}
                alt="notification_ic"
              />
            </div>
            <img
              className="ranking clickable"
              onClick={() => this.props.viewHandler('ratings', 'userstatus')}
              style={{ cursor: 'pointer' }}
              src={rating_ic}
              alt="rating_ic"
            />
            {/* <div className='circle'> */}
            <Avatar {...this.props} />
            {/* </div> */}
          </Nav>
        </Navbar.Collapse>
      </>
    );
  }
}

export default UserHeader;
