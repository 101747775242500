import config from '../config.json';
import { getCookie } from '../utils/cookies';
import dataHelpers from '../utils/dataHelpers';

export const dataService = request => {
  const DATA_API_ENDPOINT =
    config.backendUrl +
    'languages?filter={"order":"order ASC","where":{"enabled":true},"include":{"relation":"packages","scope":{"where":{"enabled":true},"order":"order ASC","include":{"relation":"programs","scope":{"where":{"enabled":true},"order":"order ASC","include":{"relation":"topics","scope":{"where":{"enabled":true},"order":"order ASC","include":{"where":{"enabled":true},"order":"order ASC","relation":"lessons","scope":{"where":{"enabled":true},"order":"order ASC"}}}}}}}}}';

  const parameters = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json'
    }
  };

  return fetch(DATA_API_ENDPOINT, parameters)
    .then(response => {
      return response.json();
    })
    .then(json => {
      return json;
    });
};

export const exercisesService = payload => {
  const DATA_API_ENDPOINT =
    config.backendUrl +
    'lessons/' +
    payload.lessonId +
    '/exercises?filter={"order":"order ASC","where":{"enabled":true}}';

  const parameters = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json'
    }
  };

  return fetch(DATA_API_ENDPOINT, parameters)
    .then(response => {
      return response.json();
    })
    .then(json => {
      return json;
    });
};

export const blitzService = payload => {
  let filter = {
    where: {
      enabled: true,
      blitz: true,
      lessonId: {
        inq: payload.lessonIds
      }
    }
  };
  const DATA_API_ENDPOINT =
    config.backendUrl + 'exercises?filter=' + JSON.stringify(filter);

  const parameters = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json'
    }
  };

  return fetch(DATA_API_ENDPOINT, parameters)
    .then(response => {
      return response.json();
    })
    .then(json => {
      return json;
    });
};

export const userService = userId => {
  const DATA_API_ENDPOINT =
    config.backendUrl +
    'users/' +
    userId +
    '?filter={"include":["statistics", "settings", "learningProgress"]}';

  const accessToken = getCookie('accessToken');
  const parameters = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: accessToken
    }
  };

  return fetch(DATA_API_ENDPOINT, parameters)
    .then(response => {
      return response.json();
    })
    .then(json => {
      return json;
    });
};

export const statusService = languageId => {
  const DATA_API_ENDPOINT =
    config.backendUrl +
    'status' +
    '?filter={"where":{"enabled":true, "languageId": "' +
    languageId +
    '"}}';

  const parameters = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json'
    }
  };

  return fetch(DATA_API_ENDPOINT, parameters)
    .then(response => {
      return response.json();
    })
    .then(json => {
      return json;
    });
};

export const saveProgressService = payload => {
  const progress = payload.progress;
  const DATA_API_ENDPOINT =
    config.backendUrl + 'learningProgress/' + progress.id + '/replace';

  progress.timestamp = Math.floor(new Date().getTime() / 1000);

  const parameters = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(progress)
  };

  return fetch(DATA_API_ENDPOINT, parameters)
    .then(response => {
      return response.json();
    })
    .then(json => {
      return json;
    });
};

export const resetProgressService = async payload => {
  const progress = payload.user.learningProgress;
  const statistics = payload.user.statistics;

  const DATA_API_ENDPOINT =
    config.backendUrl + 'learningProgress/' + progress.id + '/replace';
  progress.timestamp = Math.floor(new Date().getTime() / 1000);
  progress.selected = [];

  const parameters = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(progress)
  };

  await fetch(DATA_API_ENDPOINT, parameters);

  const putStatsUrl = config.backendUrl + 'statistics/' + statistics.id;

  statistics.points = 0;
  statistics.days = 0;
  statistics.daysContinuous = 0;
  statistics.errorCount = 0;
  statistics.rightAnswers = 0;
  statistics.words = 0;
  statistics.weekActivity = [false, false, false, false, false, false, false];

  await fetch(putStatsUrl, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      'X-Access-Token': getCookie('accessToken')
    },
    body: JSON.stringify(statistics)
  });

  return true;
};

export const saveSettingsService = payload => {
  const settings = payload.settings;
  const DATA_API_ENDPOINT =
    config.backendUrl + 'settings/' + settings.id + '/replace';

  settings.timestamp = Math.floor(new Date().getTime() / 1000);

  const parameters = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(settings)
  };

  return fetch(DATA_API_ENDPOINT, parameters)
    .then(response => {
      return response.json();
    })
    .then(json => {
      return json;
    });
};

export const saveStatisticsService = async payload => {
  let user = await userService(payload.statistics.userId);
  var stats = user.statistics;

  const newStats = payload.statistics;
  const dayOfWeek = (new Date().getDay() || 7) - 1;
  var weekActivity = newStats.weekActivity;
  weekActivity[dayOfWeek] = true;

  stats.points = newStats.points;
  stats.days += newStats.days;
  stats.daysContinuous = newStats.daysContinuous;
  stats.words = newStats.words;
  stats.weekActivity = weekActivity;
  stats.rightAnswers += newStats.rightAnswers;
  stats.errorCount += newStats.errorCount;
  stats.timestamp = newStats.timestamp;

  const putStatsUrl = config.backendUrl + 'statistics/' + stats.id;

  await fetch(putStatsUrl, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      'X-Access-Token': getCookie('accessToken')
    },
    body: JSON.stringify(stats)
  });

  return user;
};

export const leaderboardService = () => {
  const DATA_API_ENDPOINT =
    config.backendUrl + 'leaderboard?filter={"limit":100}';

  const parameters = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json'
    }
  };

  return fetch(DATA_API_ENDPOINT, parameters)
    .then(response => {
      return response.json();
    })
    .then(json => {
      return json;
    });
};

export const subscriptionService = subscriptionId => {
  const DATA_API_ENDPOINT = config.backend + 'subscription';

  let user = dataHelpers.getUser();
  let userId = user ? user.id : '';

  const parameters = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      subscriptionId,
      userId
    })
  };

  return fetch(DATA_API_ENDPOINT, parameters)
    .then(response => {
      return response.json();
    })
    .then(json => {
      return json;
    });
};

export const cancelSubscriptionService = subscriptionId => {
  const DATA_API_ENDPOINT = config.backend + 'cancel-subscription';

  const parameters = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      subscriptionId
    })
  };

  return fetch(DATA_API_ENDPOINT, parameters)
    .then(response => {
      return response.json();
    })
    .then(json => {
      return json;
    });
};

export const configService = () => {
  const DATA_API_ENDPOINT = config.backendUrl + 'get-config';

  const parameters = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json'
    }
  };

  return fetch(DATA_API_ENDPOINT, parameters)
    .then(response => {
      return response.json();
    })
    .then(json => {
      return json;
    });
};
