import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { AppContext } from '../main/AppContext';

import {
  loadCombinedAction,
  saveProgressAction
} from '../../redux-actions/dataActions';
import dataHelpers from '../../utils/dataHelpers';

import Header from '../common/Header';
import Footer from '../common/Footer';
import Loader from '../common/Loader';
import RegisterStepHeader from './RegisterStepHeader';
import RegisterButton from './RegisterButton';
import RegisterSecondStep from './RegisterSecondStep';
import i18n from '../../translation/translation';

class SelectProgram extends Component {
  hasData = false;
  hasUser = false;

  rearm = false;

  state = {
    redirectToLevel: false,
    programSelected: undefined
  };

  handleSelectProgram = program => {
    this.setState({
      programSelected: program
    });
  };

  handleSaveEvent = e => {
    const program = this.state.programSelected;
    if (!program) return;

    var response = this.props.response.data.response;
    var progress = response.user.learningProgress;
    var selected = progress.selected || [];
    let user = response.user;
    let languages = response.languages;

    let last = dataHelpers.getSelectedProgress(user);
    if (!last) {
      return <Redirect to="/select-course" />;
    }

    const course = dataHelpers.getPackageById(languages, last.packageId);
    const topics = program.topics.filter(i => i.type === 0);
    const topic = topics.length ? topics[0] : null;
    if (!topic) return;
    var found = false;

    for (let i = selected.length - 1; i >= 0; i--) {
      let item = selected[i];
      item.selected = false;
      if (item.packageId === course.id && item.programId === program.id) {
        if (!found) {
          found = true;
          item.selected = true;
          if (!item.topicId) {
            item.topicId = topic.id;
          }
        }
      }
    }
    if (!found) {
      selected.push({
        packageId: course.id,
        languageId: course.languageId,
        programId: program.id,
        topicId: topic.id,
        selected: true
      });
    }

    this.rearm = true;
    this.props.dispatch(saveProgressAction(progress));
  };

  handleStepChange = e => {
    // eslint-disable-next-line
    this.state.redirectToLevel = true;
    this.handleSaveEvent();
  };

  handleDashboardView = (left, right) => {
    this.setState({
      leftPanel: left,
      rightPanel: right,
      redirectDashboard: true
    });
  };

  render() {
    if (this.state.redirectDashboard) {
      return (
        <Redirect
          to={{
            pathname: '/dashboard',
            state: {
              leftPanel: this.state.leftPanel,
              rightPanel: this.state.rightPanel
            }
          }}
        />
      );
    }
    if (this.props.response.progress.hasOwnProperty('response') && this.rearm) {
      //TODO: update progress in user data
      //this.context.user.learningProgress = this.props.response.progress.response;
      if (!this.props.location.state.fromHeader && this.state.redirectToLevel) {
        return <Redirect to="/select-level" />;
      } else {
        return <Redirect to="/dashboard" />;
      }
    }

    if (!this.props.response.data.hasOwnProperty('response')) {
      let user = JSON.parse(localStorage.getItem('user'));
      if (user) {
        this.props.dispatch(loadCombinedAction(user.id));
        return <Loader />;
      } else {
        return <Redirect to="/login" />;
      }
    } else {
      var response = this.props.response.data.response;

      let user = response.user;
      let languages = response.languages;

      let last = dataHelpers.getSelectedProgress(user);
      var packge, program;
      if (last) {
        packge = dataHelpers.getPackageById(languages, last.packageId);
        program = dataHelpers.getProgramById(languages, last.programId);
        if (
          (!program || !last.topicId) &&
          !this.props.location.state.fromHeader
        )
          last = null;
      }

      return (
        <>
          <Header
            {...this.props}
            data={user}
            package={packge}
            program={program}
            isLogged={last != null}
            // rightLayoutHandler={this.handleRightLayoutView.bind(this)}
            viewHandler={this.handleDashboardView.bind(this)}
          />
          <RegisterStepHeader title={i18n('select-program')} />
          <RegisterSecondStep
            onProgramClick={this.handleSelectProgram.bind(this)}
            package={packge}
          />
          {!last ? (
            <RegisterButton
              handleClickEvent={this.handleStepChange.bind(this)}
              {...this.state}
            />
          ) : (
            <RegisterButton
              handleClickEvent={this.handleSaveEvent.bind(this)}
              step={4}
            />
          )}
          <Footer />
        </>
      );
    }
  }
}

SelectProgram.contextType = AppContext;

const mapStateToProps = response => ({ response });

export default connect(mapStateToProps)(SelectProgram);
