import React from 'react';

const Sentence = props => {
  return (
    <>
      <button
        className={props.element ? `ex-sentence-btn` : `ex-sentence-hidden`}
        key={props.element}
        disabled={props.element ? false : true}
        onClick={() => props.handleDiselect(props.element)}
      >
        {props.element ? props.element : ` `}
      </button>
    </>
  );
};

export default Sentence;
