import config from '../config.json';

export const registerUserService = request => {
  const REGISTER_API_ENDPOINT = config.backendUrl + 'users/register';

  const parameters = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(request.user)
  };

  return fetch(REGISTER_API_ENDPOINT, parameters)
    .then(response => {
      return response.json();
    })
    .then(json => {
      return json;
    });
};

export const loginUserService = request => {
  const LOGIN_API_ENDPOINT =
    config.backendUrl + (request.user.network ? 'users/social' : 'users/login');

  const parameters = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(request.user)
  };

  return fetch(LOGIN_API_ENDPOINT, parameters)
    .then(response => {
      return response.json();
    })
    .then(json => {
      return json;
    });
};

export const logoutUserService = request => {
  const LOGOUT_API_ENDPOINT = config.backendUrl + 'users/logout';

  const accessToken = request.accessToken;
  const parameters = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: accessToken
    }
  };

  return fetch(LOGOUT_API_ENDPOINT, parameters).then(response => {
    return { success: response.status === 204 }; //response.json();
  });
  // .ten(json => {
  //   return json;
  // });
};

export const resetPasswordService = request => {
  const RESET_API_ENDPOINT = config.backendUrl + 'users/reset';

  const parameters = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(request.user)
  };

  return fetch(RESET_API_ENDPOINT, parameters).then(response => {
    return { status: response.status };
  });
};

export const changePasswordService = (request, accessToken) => {
  const API_ENDPOINT = config.backendUrl + 'users/change-password';

  const parameters = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: accessToken
    },
    body: JSON.stringify(request)
  };

  return fetch(API_ENDPOINT, parameters).then(response => {
    return { status: response.status };
  });
};

export const updateProfileService = request => {
  const API_ENDPOINT =
    config.backendUrl + 'users/' + request.user.id + '/update';

  const accessToken = request.accessToken;
  const parameters = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: accessToken
    },
    body: JSON.stringify(request.user)
  };

  return fetch(API_ENDPOINT, parameters)
    .then(response => {
      return response.json();
    })
    .then(json => {
      return json;
    });
};
