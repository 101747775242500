import React, { Component } from 'react';
import '../dashboard.css';
import i18n from '../../../translation/translation';
import back_ic from '../../../images/back_ic.svg';
import ReactHtmlParser from 'react-html-parser';

class Rules extends Component {
  render() {
    let lang = (localStorage.getItem('course-lang') || 'EN').toLowerCase();
    let infohtml = this.props.topicLink.replace(
      /"speak:\/\/(.*?)"/g,
      '"javascript:cloodeeSpeak(\'' + lang + "', '$1')\""
    );
    return (
      <div className="card topic-rules">
        <div className="status-header">
          <img
            style={{ opacity: '.5' }}
            src={back_ic}
            alt="back"
            onClick={() => this.props.viewHandler('default', 'userstatus')}
          />
          <span> {i18n('rules')} </span>
        </div>

        <div className="topic-rules-body">{ReactHtmlParser(infohtml)}</div>
      </div>
    );
  }
}

export default Rules;
