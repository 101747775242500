import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import end_lesson_avatar from '../../../images/end_lesson_avatar.svg';
import { Link } from 'react-router-dom';
import i18n from '../../../translation/translation';
import './completion.css';
import Sound from 'react-sound';
import { backendAudio } from '../../../config';
import dataHelpers from '../../../utils/dataHelpers';
import { loadCombinedAction } from '../../../redux-actions/dataActions';

class EndLesson extends Component {
  constructor(props) {
    super(props);
    this.state = {
      topics: null,
      topicName: null,
      lessons: null,
      nextLesson: null,
      lastLesson: null,
      remainingLesson: null,
      remainingItem: null,
      progress: 0,
      prevProgress: 0
    };
  }

  componentDidMount = () => {
    let topics = this.props.location.state.topics;
    if (!topics) {
      console.error('FIXME');
      return;
    }

    let currentTopic = this.props.location.state.currentTopic;
    let lessons = this.props.location.state.lessons;
    let filteredLesson = lessons.filter(i => i.type === 0);
    let lessonLength = filteredLesson.length;
    let currentLesson = lessons.filter(
      i => i.id === this.props.location.state.currentLessonId
    );
    let remainingLesson = lessonLength;

    // eslint-disable-next-line
    for (let i of filteredLesson) {
      if (currentLesson[0].order >= i.order) {
        remainingLesson -= 1;
      }
    }

    let progressbar = 100 / lessonLength;
    let progress = progressbar * (lessonLength - remainingLesson);
    let previousLesson = remainingLesson + 1;
    let prevProgressbar = 100 / previousLesson;
    let prevProgress = prevProgressbar * (lessonLength - previousLesson);

    this.setState({
      topics: topics,
      topicName: currentTopic.name,
      lessons: lessons,
      progress: progress,
      remainingItem: remainingLesson,
      prevProgress: prevProgress
    });

    // eslint-disable-next-line
    for (let element of filteredLesson) {
      if (currentLesson[0].order >= element.order) {
        continue;
      } else {
        this.setState({ nextLesson: element });
        break;
      }
    }
  };

  componentDidUpdate = (prevProps, prevState) => {
    if (prevState.prevProgress !== this.state.progress) {
      setTimeout(() => {
        this.setState({
          prevProgress: this.state.progress
        });
      }, 0);
    }
  };

  handleNextLesson = () => {
    let languages = this.props.response.data.response.languages;
    let lesson = dataHelpers.getLessonById(
      languages,
      this.props.location.state.currentLessonId
    );
    let item = dataHelpers.getNextProgressItem(
      dataHelpers.getUser(),
      languages,
      lesson
    );

    if (item.finished) {
      this.props.history.push('/dashboard', {
        leftPanel: 'default',
        rightPanel: 'userstatus'
      });
      return;
    }

    let program = dataHelpers.getProgramById(languages, item.programId);
    let topic = dataHelpers.getTopicById(languages, item.topicId);

    this.props.history.push('/exercise', {
      topics: program.topics,
      currentTopic: topic,
      lessons: topic.lessons,
      currentLessonId: item.lessonId
    });
  };

  handleNextTopic = () => {
    this.handleNextLesson();
  };

  render() {
    let res = dataHelpers.checkData(this.props);
    if (res) {
      return <Redirect to={res} />;
    }

    return (
      <div className="end-lesson-container">
        <div className="end-lesson-div" align="center">
          <label className="end-lesson-lbl"> {i18n('completion')} </label>
          <img
            className="end-lesson-img"
            src={end_lesson_avatar}
            alt="end-lesson-avatar"
          />
          <label className="topic-lbl"> {this.state.topicName} </label>
          <div className="progress el-progress-container">
            <div
              className="progress-bar el-progress"
              // value of lessons and progress covered
              style={{ width: this.state.prevProgress + `%` }}
            ></div>
          </div>
          <label>
            {this.state.remainingItem > 0
              ? dataHelpers.num2str18n(
                  this.state.remainingItem,
                  'remains-case'
                ) +
                ' ' +
                this.state.remainingItem +
                ' ' +
                dataHelpers.num2str18n(this.state.remainingItem, 'lesson-case')
              : i18n('lessons-completed')}
          </label>
          <button
            className="next-lesson-btn"
            onClick={
              this.state.progress >= 100
                ? () => this.handleNextTopic()
                : () => this.handleNextLesson()
            }
          >
            {this.state.progress >= 100
              ? i18n('next-topic')
              : i18n('next-lesson')}
          </button>
          <Link to="/">
            <label
              className="return-lnk"
              onClick={() => {
                let user = dataHelpers.getUser();
                this.props.dispatch(loadCombinedAction(user.id));
              }}
            >
              {' '}
              {i18n('return-menu')}{' '}
            </label>{' '}
          </Link>
        </div>

        <Sound
          url={backendAudio + 'congrats.mp3'}
          playStatus={Sound.status.PLAYING}
        />
      </div>
    );
  }
}

const mapStateToProps = response => ({ response });

export default connect(mapStateToProps)(EndLesson);
