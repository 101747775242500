import React, { Component } from 'react';
import './dashboard.css';
import i18n from '../../translation/translation';
import { backend } from '../../config';
import dataHelpers from '../../utils/dataHelpers';

class UserStatusInfo extends Component {
  render() {
    return (
      <div className="right-info-top">
        <div
          className="right-info-wrapper"
          onClick={() => this.props.viewHandler('userstatus', 'userstatus')}
        >
          <div className="right-info-img">
            <span>
              <img
                className="user-status-img"
                src={backend + this.props.status.icon}
                alt="status"
              />
            </span>
          </div>

          <div className="right-info-text">
            <div className="right-info-maintext">
              <span>
                {this.props.status.name}
                <b style={{ color: '#F29400' }}>
                  {' '}
                  {this.props.data.statistics.points}{' '}
                </b>
                {dataHelpers.num2str18n(
                  this.props.data.statistics.points,
                  'points-case'
                )}
              </span>
            </div>
            <div className="right-info-subtext">
              <span>
                {i18n('to-next-status') +
                  ' ' +
                  this.props.status.toNextStatus +
                  ' ' +
                  dataHelpers.num2str18n(
                    this.props.status.toNextStatus,
                    'points-case'
                  )}
              </span>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default UserStatusInfo;
