import React, { Component } from 'react';
import DayCheckbox from '../../register/DayCheckbox';
import i18n from '../../../translation/translation';
import SwitchSetting from './SwitchSetting';
import InputMask from 'react-input-mask';
import { saveSettingsAction } from '../../../redux-actions/dataActions';
import MessageModal from './MessageModal';
import '../dashboard.css';

class Reminders extends Component {
  constructor(props) {
    super(props);
    let settings = this.props.reminders;

    this.state = {
      switchReminder: settings.motivationNotification,
      inputTime: this.formatTime(settings.notificationTime),
      days: settings.notificationDays
    };
    this.rearm = false;
  }

  handleSave = () => {
    let params = this.state;
    var settings = this.props.reminders;
    settings.motivationNotification = params.switchReminder;
    settings.notificationDays = params.days;

    var p = params.inputTime.split(':');
    var min = p.pop() || 0;
    var hr = p.pop() || 0;

    let localDate = new Date(2020, 1, 1, hr, min);

    let m = localDate.getUTCHours() * 60 + localDate.getUTCMinutes();
    settings.notificationTime = m;

    this.rearm = true;
    this.props.dispatch(saveSettingsAction(settings));
  };

  formatTime(notificationTime) {
    let hr = Math.floor(notificationTime / 60);
    let min = notificationTime % 60;

    function pad(num, size) {
      var s = '00' + num;
      return s.substr(s.length - size);
    }

    let utcDate = new Date(Date.UTC(2020, 1, 1, hr, min));

    let localTime =
      pad(utcDate.getHours(), 2) + ':' + pad(utcDate.getMinutes(), 2);

    return localTime;
  }

  handleCheck = (index, check) => {
    var newDays = this.state.days;
    newDays[index] = check;
    this.setState({ days: newDays });
  };

  handleSwitch = e => {
    this.setState({ switchReminder: !this.state.switchReminder });
  };

  handleTimeChange = e => {
    this.setState({
      inputTime: e.target.value
    });
  };

  render() {
    var showMessage = false;

    if (this.rearm && this.props.response.settings.hasOwnProperty('response')) {
      this.rearm = false;
      showMessage = true;
    }

    return (
      <>
        <div className="card reminders-card">
          <div className="status-header general-header">
            {i18n('reminder-settings')}
          </div>
          <div className="collapse show">
            <div className="card-body general-info-body reminders-body">
              <h5> {i18n('workout-reminders')}</h5>
              <hr className="general-divider"></hr>

              <div className="reminders-area-div">
                <div className="reminders-left">
                  <label>{i18n('choose-day-label')}</label>

                  <label>{i18n('specify-time-label')}</label>
                </div>

                <div className="reminders-right">
                  <DayCheckbox
                    {...this.props}
                    notificationDays={this.state.days}
                    handleCheck={this.handleCheck.bind(this)}
                  />
                  <InputMask
                    className="step4-input reminders-input"
                    type="text"
                    aria-describedby="basic-addon1"
                    name="reminder"
                    id="reminder"
                    mask="99:99"
                    maskChar="_"
                    value={this.state.inputTime}
                    onChange={this.handleTimeChange.bind(this)}
                    placeholder={i18n('reminder-placeholder')}
                  />
                </div>
              </div>

              <h5> {i18n('motivational-notification')}</h5>
              <hr className="general-divider"></hr>

              <div className="reminders-motivation-div">
                <SwitchSetting
                  class="reminders"
                  checked={this.state.switchReminder}
                  onChange={this.handleSwitch.bind(this)}
                  id={i18n('on-off-switch-motivation')}
                />
              </div>

              <button
                onClick={this.handleSave.bind(this)}
                className="btn btn-default general-reset-btn"
              >
                {i18n('save')}
              </button>
            </div>
          </div>
        </div>

        <MessageModal
          {...this.props}
          title={i18n('save-title')}
          message={i18n('reminders-saved')}
          onHide={() => {
            this.rearm = false;
            this.setState({});
          }}
          show={showMessage}
        />
      </>
    );
  }
}

export default Reminders;
