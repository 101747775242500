import React, { Component } from 'react';

import { AppContext } from '../main/AppContext';

import TrainingThemes from './TrainingThemes';
import ThemesHeader from './ThemesHeader';

import dataHelpers from '../../utils/dataHelpers';

import './dashboard.css';

class Training extends Component {
  lessonPositions = [];
  currentLessonId = undefined;

  loadLessonFromTopic(param, currentPos, completed) {
    const lessonList = param.lessons;
    const lessons = [];

    let onlyHelp = lessonList.filter(function(item) {
      return item.type === 1;
    });

    let onlyLessons = lessonList.filter(function(item) {
      return item.type === 0;
    });

    let onlyWords = lessonList.filter(function(item) {
      return item.type === 2;
    });

    onlyHelp.forEach(lesson => {
      lessons.push(
        <TrainingThemes
          key={lesson.id}
          {...lesson}
          complete={false}
          available={this.props.enabled}
          outerdata={this.props}
        />
      );
    });

    onlyLessons.forEach(lesson => {
      let pos = this.lessonPositions[lesson.id];
      let complete = pos < currentPos;
      let available = pos <= currentPos && this.props.enabled;
      lessons.push(
        <TrainingThemes
          key={lesson.id}
          {...lesson}
          topicComplete={completed}
          complete={complete}
          available={available}
          outerdata={this.props}
        />
      );
    });

    onlyWords.forEach(lesson => {
      let pos = this.lessonPositions[lesson.id];
      let complete = pos < currentPos;
      let available = pos <= currentPos && this.props.enabled;
      lessons.push(
        <TrainingThemes
          key={lesson.id}
          {...lesson}
          topicComplete={completed}
          complete={complete}
          available={available}
          outerdata={this.props}
        />
      );
    });

    return lessons;
  }

  render() {
    var response = this.props.response.data.response;
    let user = response.user;
    let topic = this.props.topic || [];
    let lessons = topic.lessons || [];
    let selected = dataHelpers.getSelectedProgress(user);

    let onlyLessons = lessons.filter(i => i.type === 0);

    // eslint-disable-next-line
    for (const [i, lesson] of onlyLessons.entries()) {
      this.lessonPositions[lesson.id] = i;
    }

    let currentLessonId = selected.lessonId;
    let pos = this.lessonPositions[currentLessonId] || -1 + 1;
    let total = onlyLessons.length;
    let completed = pos + 1;

    return (
      <>
        <div className="accordion" id="theme-accord">
          <div className="card">
            <div
              className="card-header"
              id="headingOne2"
              data-toggle="collapse"
              data-target={`#collapse` + this.props.topic.id}
              aria-expanded="true"
              aria-controls={`#collapse` + this.props.topic.id}
            >
              <ThemesHeader
                {...this.props}
                completed={completed}
                total={total}
              />
            </div>
            <div
              id={`collapse` + this.props.topic.id}
              className="collapse"
              aria-labelledby="headingOne2"
              data-parent="#theme-accord"
            >
              <div className="card-body theme-body">
                {this.loadLessonFromTopic(
                  this.props.topic,
                  pos,
                  this.props.complete
                )}
              </div>
            </div>
          </div>
        </div>

        <br></br>
      </>
    );
  }
}

Training.contextType = AppContext;

export default Training;
