import React, { Component } from 'react';
import './dashboard.css';
import i18n from '../../translation/translation';
import ExerciseLogic from '../dashboard/exercise/ExerciseLogic';
import dataHelpers from '../../utils/dataHelpers';

class TrainingDaysInfo extends Component {
  render() {
    let items = [];
    let count = 0;

    let ts = this.props.data.statistics.timestamp;
    let wn = ExerciseLogic.getWeekNumber(new Date(ts * 1000));
    let cw = ExerciseLogic.getWeekNumber(new Date());

    var state = [];
    if (wn[1] !== cw[1]) {
      state[i18n('day-mon')] = false;
      state[i18n('day-tue')] = false;
      state[i18n('day-wed')] = false;
      state[i18n('day-thr')] = false;
      state[i18n('day-fri')] = false;
      state[i18n('day-sat')] = false;
      state[i18n('day-sun')] = false;
    } else {
      const activity = ExerciseLogic.getWeekActivity(
        this.props.data.statistics
      );
      state[i18n('day-mon')] = activity[0];
      state[i18n('day-tue')] = activity[1];
      state[i18n('day-wed')] = activity[2];
      state[i18n('day-thr')] = activity[3];
      state[i18n('day-fri')] = activity[4];
      state[i18n('day-sat')] = activity[5];
      state[i18n('day-sun')] = activity[6];
    }

    const entries = Object.entries(state);

    // eslint-disable-next-line
    for (const [label, activity] of entries) {
      items.push(
        <span
          key={`key_` + label}
          className={activity ? `rounded-circle marked-days` : `rounded-circle`}
        >
          {' '}
          {label}{' '}
        </span>
      );

      //previously count = count -> self assignment es-lint warning.
      activity ? count++ : (this.count = count);
    }

    return (
      <div className="right-info-bottom">
        <div className="right-bottom-header">
          <h5 className="mb-5"> {i18n('training-days')} </h5>
          <div className="test">{items}</div>
          <p align="center" className="mt-3">
            {' '}
            <b style={{ color: '#F29400' }}> {count} </b> {/*TODO: i18n */}
            {dataHelpers.num2str18n(count, 'active-case') + ' '}
            {dataHelpers.num2str18n(count, 'days-case')} {i18n('this-week')}
          </p>
        </div>

        <hr className="right-divider"></hr>

        <div className="right-bottom-body">
          <h5 className="mb-5"> {i18n('statistics')} </h5>
          <div className="right-bottom-body-texts">
            <p>
              {i18n('words-learned')}
              <b style={{ color: '#F29400' }}>
                {' '}
                {this.props.data.statistics.words}{' '}
              </b>{' '}
            </p>
            <p>
              {i18n('total-training-days')}
              <b style={{ color: '#F29400' }}>
                {' '}
                {this.props.data.statistics.days}{' '}
              </b>{' '}
            </p>
            <p>
              {i18n('continuing-education')}
              <b style={{ color: '#F29400' }}>
                {' '}
                {this.props.data.statistics.daysContinuous}{' '}
              </b>{' '}
            </p>
            <p>
              {i18n('given-correct')}
              <b style={{ color: '#F29400' }}>
                {' '}
                {this.props.data.statistics.rightAnswers}{' '}
              </b>{' '}
            </p>
            <p>
              {i18n('mistakes-made')}
              <b style={{ color: '#F29400' }}>
                {' '}
                {this.props.data.statistics.errorCount}{' '}
              </b>{' '}
            </p>
          </div>
        </div>
      </div>
    );
  }
}

export default TrainingDaysInfo;
