import React, { Component } from 'react';

import { Button } from 'react-bootstrap';
import i18n from '../../translation/translation';

class RegisterButton extends Component {
  render() {
    return (
      <div align="center">
        <Button
          style={{ backgroundColor: '#683da1' }}
          onClick={this.props.handleClickEvent}
          className="RegisterButton"
        >
          {this.props.step === 4 ? i18n('select') : i18n('further-btn')}
        </Button>
      </div>
    );
  }
}

export default RegisterButton;
