export const REGISTER_USER = 'REGISTER_USER';
export const REGISTER_USER_SUCCESS = 'REGISTER_USER_SUCCESS';
export const REGISTER_USER_ERROR = 'REGISTER_USER_ERROR';

export const LOGIN_USER = 'LOGIN_USER';
export const LOGIN_USER_SUCCESS = 'LOGIN_USER_SUCCESS';
export const LOGIN_USER_ERROR = 'LOGIN_USER_ERROR';

export const LOGOUT_USER = 'LOGOUT_USER';
export const LOGOUT_USER_SUCCESS = 'LOGOUT_USER_SUCCESS';
export const LOGOUT_USER_ERROR = 'LOGOUT_USER_ERROR';

export const RESET_PASSWORD = 'RESET_PASSWORD';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_ERROR = 'RESET_PASSWORD_ERROR';

export const LOAD_DATA = 'LOAD_DATA';
export const LOAD_DATA_SUCCESS = 'LOAD_DATA_SUCCESS';
export const LOAD_DATA_ERROR = 'LOAD_DATA_ERROR';

export const LOAD_USER_DATA = 'LOAD_USER_DATA';
export const LOAD_USER_DATA_SUCCESS = 'LOAD_USER_DATA_SUCCESS';
export const LOAD_USER_DATA_ERROR = 'LOAD_USER_DATA_ERROR';

export const LOAD_COMBINED_DATA = 'LOAD_COMBINED_DATA';
export const LOAD_COMBINED_DATA_SUCCESS = 'LOAD_COMBINED_DATA_SUCCESS';
export const LOAD_COMBINED_DATA_ERROR = 'LOAD_COMBINED_DATA_ERROR';

export const SAVE_PROGRESS_DATA = 'SAVE_PROGRESS_DATA';
export const SAVE_PROGRESS_DATA_SUCCESS = 'SAVE_PROGRESS_DATA_SUCCESS';
export const SAVE_PROGRESS_DATA_ERROR = 'SAVE_PROGRESS_DATA_ERROR';

export const RESET_PROGRESS_DATA = 'RESET_PROGRESS_DATA';
export const RESET_PROGRESS_DATA_SUCCESS = 'RESET_PROGRESS_DATA_SUCCESS';
export const RESET_PROGRESS_DATA_ERROR = 'RESET_PROGRESS_DATA_ERROR';

export const SAVE_SETTINGS_DATA = 'SAVE_SETTINGS_DATA';
export const SAVE_SETTINGS_DATA_SUCCESS = 'SAVE_SETTINGS_DATA_SUCCESS';
export const SAVE_SETTINGS_DATA_ERROR = 'SAVE_SETTINGS_DATA_ERROR';

export const SAVE_STATISTICS_DATA = 'SAVE_STATISTICS_DATA';
export const SAVE_STATISTICS_DATA_SUCCESS = 'SAVE_STATISTICS_DATA_SUCCESS';
export const SAVE_STATISTICS_DATA_ERROR = 'SAVE_STATISTICS_DATA_ERROR';

export const LOAD_LEADERBOARD = 'LOAD_LEADERBOARD';
export const LOAD_LEADERBOARD_SUCCESS = 'LOAD_LEADERBOARD_SUCCESS';
export const LOAD_LEADERBOARD_ERROR = 'LOAD_LEADERBOARD_ERROR';

export const LOAD_EXERCISES = 'LOAD_EXERCISES';
export const LOAD_EXERCISES_SUCCESS = 'LOAD_EXERCISES_SUCCESS';
export const LOAD_EXERCISES_ERROR = 'LOAD_EXERCISES_ERROR';

export const LOAD_BLITZ = 'LOAD_BLITZ';
export const LOAD_BLITZ_SUCCESS = 'LOAD_BLITZ_SUCCESS';
export const LOAD_BLITZ_ERROR = 'LOAD_BLITZ_ERROR';

export const CHANGE_PASSWORD = 'CHANGE_PASSWORD';
export const CHANGE_PASSWORD_SUCCESS = 'CHANGE_PASSWORD_SUCCESS';
export const CHANGE_PASSWORD_ERROR = 'CHANGE_PASSWORD_ERROR';

export const UPDATE_PROFILE = 'UPDATE_PROFILE';
export const UPDATE_PROFILE_SUCCESS = 'UPDATE_PROFILE_SUCCESS';
export const UPDATE_PROFILE_ERROR = 'UPDATE_PROFILE_ERROR';

export const GET_SUBSCRIPTION = 'GET_SUBSCRIPTION';
export const GET_SUBSCRIPTION_SUCCESS = 'GET_SUBSCRIPTION_SUCCESS';
export const GET_SUBSCRIPTION_ERROR = 'GET_SUBSCRIPTION_ERROR';

export const CANCEL_SUBSCRIPTION = 'CANCEL_SUBSCRIPTION';
export const CANCEL_SUBSCRIPTION_SUCCESS = 'CANCEL_SUBSCRIPTION_SUCCESS';
export const CANCEL_SUBSCRIPTION_ERROR = 'CANCEL_SUBSCRIPTION_ERROR';

export const AD_SHOW = 'AD_SHOW';
export const AD_SHOW_SUCCESS = 'AD_SHOW_SUCCESS';

export const SET_LANGUAGE = 'SET_LANGUAGE';
export const SET_LANGUAGE_SUCCESS = 'SET_LANGUAGE_SUCCESS';

export const REFRESH_DATA = 'REFRESH_DATA';
export const REFRESH_DATA_SUCCESS = 'REFRESH_DATA_SUCCESS';
