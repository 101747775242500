import React, { Component } from 'react';

import lesson_available_ic from '../../images/lesson_available_ic.svg';
import lesson_complete_ic from '../../images/lesson_complete_ic.svg';
import lesson_non_ic from '../../images/lesson_not_available_ic.svg';

import rules_ic from '../../images/rules_ic.svg';
import rules_non_ic from '../../images/rules_not_available_ic.svg';

import extra_words_ic from '../../images/extra_words_ic.svg';
import extra_words_non_ic from '../../images/extra_words_not_available_ic.svg';

import lock_ic from '../../images/lock_ic.png';
import { AppContext } from '../main/AppContext';
import PopoverStickOnHover from './PopoverStickOnHover';
import './dashboard.css';

import { Popover } from 'react-bootstrap';
import i18n from '../../translation/translation';
import dataHelpers from '../../utils/dataHelpers';

class TrainingThemes extends Component {
  imageDisplay = (complete, available) => {
    switch (this.props.type) {
      case 0:
        if (available && !complete) {
          return (
            <img
              className="theme-item-img mb-2 lesson-a-img"
              alt="lesson-img"
              src={lesson_available_ic}
              onClick={() =>
                this.popOverLinkHandler(this.props.type, available)
              }
            />
          );
        } else if (!available && !complete) {
          return (
            <img
              className="theme-item-img mb-2 lesson-n-img"
              alt="lesson-non-img"
              src={lesson_non_ic}
            />
          );
        } else if (available && complete) {
          return (
            <img
              className="theme-item-img mb-2 lesson-c-img"
              alt="lesson-complete-img"
              src={lesson_complete_ic}
              onClick={() =>
                this.popOverLinkHandler(this.props.type, available)
              }
            />
          );
        }
        break;

      case 1:
        if (available || complete) {
          return (
            <img
              className="theme-item-img mb-2 rules-img"
              alt="rules-img"
              src={rules_ic}
              onClick={() =>
                this.popOverLinkHandler(this.props.type, available)
              }
            />
          );
        } else {
          return (
            <img
              className="theme-item-img mb-2 rules-non-img"
              alt="rules-img"
              src={rules_non_ic}
            />
          );
        }

      case 2:
        if (available || complete) {
          return (
            <img
              className="theme-item-img mb-2 lesson-non-c-img"
              alt="lesson-complete-img"
              src={extra_words_ic}
              onClick={() =>
                this.popOverLinkHandler(this.props.type, available)
              }
            />
          );
        } else {
          return (
            <img
              className="theme-item-img mb-2"
              alt="lesson-complete-img"
              src={extra_words_non_ic}
            />
          );
        }

      default:
        return <div></div>;
    }
  };

  popOverLinkHandler = (type, complete) => {
    if (type === 1) {
      this.props.outerdata.viewHandler.viewHandler('topic-rules', 'userstatus');
      this.props.outerdata.viewHandler.handleTopicLink(this.props.html);
    } else if (type === 2) {
      this.props.outerdata.viewHandler.history.push('/exercise', {
        topics: this.props.outerdata.viewHandler.program.topics,
        currentTopic: this.props.topicId,
        lessons: this.props.outerdata.topic.lessons,
        currentLessonId: this.props.id
      });
    } else {
      //Push to exercise page
      if (
        dataHelpers.isPremium(this.props.outerdata) ||
        dataHelpers.hasFreeLessons(this.props.outerdata)
      ) {
        this.props.outerdata.viewHandler.history.push('/exercise', {
          topics: this.props.outerdata.viewHandler.program.topics,
          currentTopic: this.props.topicId,
          lessons: this.props.outerdata.topic.lessons,
          currentLessonId: this.props.id
        });
      } else {
        this.props.outerdata.viewHandler.history.push('/limit', {});
      }
    }
  };

  render() {
    let complete = this.props.complete || this.props.topicComplete;
    let available = this.props.available || complete;

    return (
      <PopoverStickOnHover
        onMouseEnter={() => {}}
        key="auto"
        placement="bottom"
        component={
          <Popover.Content>
            <h4 className="mt-4"> {this.props.name} </h4>
            {this.props.description}
            <hr className="popover-divider"></hr>
            {available ? (
              <p className="popover-link">{i18n('click-to-start')}</p>
            ) : (
              <img className="lock-img" alt="lock" src={lock_ic} />
            )}
          </Popover.Content>
        }
      >
        <div className="theme-item" align="center">
          {this.imageDisplay(complete, available)}
          <br />
          <span> {this.props.name} </span>
        </div>
      </PopoverStickOnHover>
    );
  }
}

TrainingThemes.contextType = AppContext;

export default TrainingThemes;
