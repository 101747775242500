import React, { Component } from 'react';
import i18n from '../../translation/translation';

class DayCheckbox extends Component {
  constructor(props) {
    super(props);
    this.state = {
      labels: [],
      days: []
    };

    this.state.labels[0] = i18n('day-mon');
    this.state.labels[1] = i18n('day-tue');
    this.state.labels[2] = i18n('day-wed');
    this.state.labels[3] = i18n('day-thr');
    this.state.labels[4] = i18n('day-fri');
    this.state.labels[5] = i18n('day-sat');
    this.state.labels[6] = i18n('day-sun');

    const on = props.notificationDays;
    for (var i of [0, 1, 2, 3, 4, 5, 6]) {
      this.state.days[i] = on[i] || false;
    }

    this.handleCheck.bind(this);
  }

  handleCheck = (index, check) => {
    var newDays = this.state.days;
    newDays[index] = !check;
    this.setState({ days: newDays });
  };

  render() {
    const items = [];

    for (const [index, label] of this.state.labels.entries()) {
      items.push(
        <li key={label}>
          <label className="day-btn">
            <input
              className="day-cb"
              type="checkbox"
              value={label}
              checked={this.state.days[index]}
              onChange={() => {
                this.handleCheck(index, this.state.days[index]);
                this.props.handleCheck(index, this.state.days[index]);
              }}
            />
            <span> {label} </span>
          </label>
        </li>
      );
    }

    return (
      <div className="step4-btn-input-switch-wrapper">
        <ul
          className="nav nav-tabs"
          role="tablist"
          style={{ borderBottom: '0px' }}
        >
          {items}
        </ul>
      </div>
    );
  }
}

export default DayCheckbox;
