import React from 'react';

const Sentence = props => {
  let index = !props.index ? 0 : props.index;
  return (
    <>
      <button
        className={props.element ? `ex-sentence-btn` : `ex-sentence-hidden`}
        key={props.element}
        onClick={() => props.handleDeselect(props.element, index)}
        disabled={props.disabled}
      >
        {props.element ? props.element : ` `}
      </button>
    </>
  );
};

export default Sentence;
