import React, { Component } from 'react';
import Course from './Course';

import { backend } from '../../config';

class CourseSelection extends Component {
  onLanguageSelected(item) {
    this.props.onLanguageClick(item);
  }

  render() {
    const items = [];

    for (const [index, value] of this.props.lang.packages.entries()) {
      items.push(
        <Course {...this.props} key={value.id} index={index} course={value} />
      );
    }

    return (
      <>
        <div className="card">
          <div
            className={
              'card-header course-header' +
              (this.props.languageSelected === this.props.lang.id
                ? ' card-header-active'
                : '')
            }
            role="tab"
            href={`#div-` + this.props.lang.id}
            onClick={() => this.onLanguageSelected(this.props.lang.id)}
          >
            <h5 className="collapse-title">
              <span className="collapsesvg">
                <img
                  className="collapse-svg-img"
                  alt="collapse-svg"
                  src={backend + this.props.lang.icon}
                ></img>
              </span>
              <span
                className="collapsed align-middle"
                href={`#div-` + this.props.lang.id}
                aria-expanded="false"
                aria-controls="collapseTwo"
              >
                {this.props.lang.name}
              </span>
            </h5>
          </div>

          <div
            id={`div-` + this.props.lang.id}
            className="collapse"
            role="tabpanel"
            aria-labelledby="headingTwo"
            data-parent="#accordion"
          >
            {items}
          </div>
        </div>
        <br></br>
      </>
    );
  }
}

export default CourseSelection;
