import React, { Component } from 'react';
import { backend } from '../../../../config';
import PlayBtn from '../PlayBtn';
import ExerciseLogic from '../ExerciseLogic';

class Type1 extends Component {
  render() {
    const exercise = this.props.exercise;
    return (
      <div className="exercise-card">
        <div className="exercise-subcard" align="center">
          <img alt="exercise" src={backend + exercise.type1_image} />
        </div>

        <div className="exercise-subcard-label" align="center">
          <p> {exercise.type1_word} </p>
        </div>

        <PlayBtn
          audio_file={ExerciseLogic.getAudioUrl(
            this.props.language,
            exercise.type1_word
          )}
        />
      </div>
    );
  }
}

export default Type1;
