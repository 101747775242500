import React, { Component } from 'react';
import { Button } from 'react-bootstrap';
import { showAdAction } from '../../redux-actions/miscActions';
import i18n from '../../translation/translation';
import './fakemodal.css';
import dataHelpers from '../../utils/dataHelpers';
import { backendUrl } from '../../config';

class FakeModalAd extends Component {
  constructor(props) {
    super(props);
    this.display = 'none';
    this.onClose = this.onClose.bind(this);
    this.onRemoveAd = this.onRemoveAd.bind(this);
    this.updateAds = this.updateAds.bind(this);
    this.iRef = React.createRef();
  }

  componentDidMount() {
    this.updateAds();
  }

  updateAds() {
    let that = this;
    fetch(
      backendUrl +
        'banners/random?platform=Web&languageId=5ca6ced67a3327464a22b914',
      {
        method: 'get',
        headers: {
          'Content-type': 'application/json'
        }
      }
    )
      .then(function(response) {
        return response.json();
      })
      .then(function(json) {
        console.log(json);
        const iNode = that.iRef.current;
        iNode.src = json.url;
      });
  }

  onClose() {
    this.props.dispatch(showAdAction(false));
    //this.props.history.push('/dashboard');
    window.location.href = '/dashboard';
  }

  onRemoveAd() {
    this.props.dispatch(showAdAction(false));
    this.props.history.push('/dashboard', {
      leftPanel: 'subscription',
      rightPanel: 'userstatus'
    });
  }

  render() {
    var show = this.props.response.misc.showAd;

    if (dataHelpers.isPremium(this.props)) show = false;

    this.display = show ? 'block' : 'none';

    return (
      <>
        <div
          className="fade modal-backdrop show"
          style={{ display: this.display }}
        ></div>
        <div
          style={{
            display: this.display,
            position: 'absolute',
            top: '0px',
            bottom: '0px',
            left: '0px',
            right: '0px'
          }}
        >
          <div
            style={{
              display: 'block',
              position: 'relative',
              margin: 'auto',
              zIndex: '10000',
              top: '50%',
              left: '50%',
              marginTop: '-315px',
              marginLeft: '-555px'
            }}
          >
            <div
              className="modal-content"
              style={{
                width: '1110px',
                height: '630px'
              }}
            >
              {/* <AdSense.Google
                id="ad-block"
                client="ca-pub-8110124847476152"
                slot="5932551982"
                style={{ display: 'block' }}
                format="square"
                responsive="true"
              /> */}

              <iframe
                title="Ads"
                ref={this.iRef}
                className="ad-iframe"
                src=""
              ></iframe>

              <img
                src="/static/media/close_ic.c27d0875.svg"
                className="ex-modal-close"
                alt="close"
                style={{
                  position: 'absolute',
                  top: '36px',
                  right: '14px',
                  width: '24px',
                  height: '24px'
                }}
                onClick={this.onClose}
              />

              <Button
                className="modal-btn-remove-ads"
                onClick={this.onRemoveAd}
                variant="default"
              >
                {i18n('remove-ads')}
              </Button>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default FakeModalAd;
