import { combineReducers } from 'redux';
import register from './registerReducer';
import login from './loginReducer';
import logout from './logoutReducer';
import reset from './resetReducer';
import data from './dataReducer';
import progress from './progressReducer';
import settings from './settingsReducer';
import statistics from './statisticsReducer';
import leaderboard from './leaderboardReducer';
import exercises from './exercisesReducer';
import profile from './profileReducer';
import misc from './miscReducer';
import subscription from './subscriptionReducer';
import { connectRouter } from 'connected-react-router';

const rootReducer = history =>
  combineReducers({
    router: connectRouter(history),
    register,
    login,
    logout,
    reset,
    data,
    progress,
    settings,
    statistics,
    leaderboard,
    exercises,
    profile,
    misc,
    subscription
  });

export default rootReducer;
