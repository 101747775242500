import React, { Component } from 'react';
import speach_ic from '../../../images/speach_ic.svg';
import './exercise.css';

class PlayBtnSimple extends Component {
  constructor(props) {
    super(props);
    this.audioRef = React.createRef();
    this.playAudio = this.playAudio.bind(this);
  }

  playAudio() {
    const audioEl = this.audioRef.current;
    const promise = audioEl.play();
    if (promise) {
      promise.catch(err => {
        console.error(err);
      });
    }
  }

  render() {
    return (
      <>
        <img
          onClick={this.playAudio}
          className={this.props.className}
          src={this.props.src || speach_ic}
          alt="speach"
        />

        <audio ref={this.audioRef} className="audio-element">
          <source src={this.props.audio_file}></source>
        </audio>
      </>
    );
  }
}

export default PlayBtnSimple;
