import React, { Component } from 'react';
import Course from './Course';
import { backend } from '../../config';
import i18n from '../../translation/translation';

class RegisterFirstStep2 extends Component {
  onLanguageSelected(item) {
    this.props.onLanguageClick(item);
  }

  render() {
    const items = [];
    let languageId = this.props.languageSelected;
    let language = this.props.languages.find(i => i.id === languageId);

    for (const [index, value] of language.packages.entries()) {
      items.push(
        <Course
          {...this.props}
          key={value.id}
          index={index}
          course={value}
          img={backend + value.icon}
        />
      );
    }

    return (
      <div className="center-content">
        <h5 className="step-two-subtitle1">{i18n('i-speak')}</h5>
        <div className="card step-two-card">
          <div
            className="card-header step-two-header"
            role="tab"
            href={`#div-` + language.id}
            onClick={() => this.onLanguageSelected(null)}
          >
            <h5 className="collapse-title">
              <span className="collapsesvg">
                <img
                  className="collapse-svg-img"
                  alt="collapse-svg"
                  src={backend + language.icon}
                ></img>
              </span>
              <span
                className="collapsed align-middle step-two-title"
                href={`#div-` + language.id}
                aria-expanded="false"
                aria-controls="collapseTwo"
              >
                {language.name}
              </span>
            </h5>
          </div>
        </div>
        <div className="step-two-line"></div>
        <h5 className="step-two-subtitle2">{i18n('i-want-to-learn')}</h5>
        {items}
      </div>
    );
  }
}

export default RegisterFirstStep2;
