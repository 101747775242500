import React, { Component } from 'react';
import { backend } from '../../../../config';
import avatar_ic from '../../../../images/avatar_ic.svg';

class Type3 extends Component {
  render() {
    const subcards = [];
    const exercise = this.props.exercise;

    exercise.type3_image.forEach((img, index) => {
      subcards.push(
        <label className="type3_label" key={index}>
          <input
            type="radio"
            name="option1"
            className="type3_option"
            onClick={() => this.props.selectedValue(3, index)}
          />
          <div className="ex-subcard-type3">
            <img
              src={backend + img}
              onError={e => (e.target.src = avatar_ic)}
              alt="type3-img"
            />
            <p> {exercise.type3_translation[index]} </p>
          </div>
        </label>
      );
    });

    return (
      <div className="exercise-card-type3" align="center">
        {subcards}
      </div>
    );
  }
}

export default Type3;
