import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import Header from '../common/Header';
import Footer from '../common/Footer';
import { registerUserAction } from '../../redux-actions/authActions';
import { setCookie } from '../../utils/cookies';

import { Button, InputGroup, FormControl } from 'react-bootstrap';
import i18n from '../../translation/translation';

class RegisterPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      errorMessage: ''
    };
  }
  onHandleRegistration = event => {
    event.preventDefault();

    let name = event.target.name.value.trim();
    let email = event.target.email.value.trim();
    let password = event.target.password.value.trim();
    let age = Number(event.target.age.value.trim());

    if (name.length < 1) {
      this.setState({
        errorMessage: i18n('error-name-not-set')
      });
      return false;
    }

    if (!age) {
      this.setState({
        errorMessage: i18n('error-age-not-set')
      });
      return false;
    }

    if (email.length < 1) {
      this.setState({
        errorMessage: i18n('error-email-not-set')
      });
      return false;
    }

    if (/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(email) !== true) {
      this.setState({
        errorMessage: i18n('error-email-not-valid')
      });
      return false;
    }

    if (password.length < 1) {
      this.setState({
        errorMessage: i18n('error-password-not-set')
      });
      return false;
    }

    if (password.length < 6) {
      this.setState({
        errorMessage: i18n('error-password-too-short')
      });
      return false;
    }

    this.setState({
      errorMessage: ''
    });

    const data = {
      name,
      age,
      email,
      password
    };

    this.props.dispatch(registerUserAction(data));
  };

  componentDidMount() {
    document.title = i18n('registration');
  }

  render() {
    let message, isSuccess;

    if (this.props.response.register.hasOwnProperty('response')) {
      var response = this.props.response.register.response;
      isSuccess = response && response.accessToken;
      if (isSuccess) {
        let THREE_MONTHS = 3 * 30 * 24; // hrs
        setCookie('accessToken', response.accessToken, THREE_MONTHS);
        localStorage.setItem('user', JSON.stringify(response.user));
      } else {
        switch (response.error.code) {
          case 'EMAIL_ALREADY_EXISTS':
            message = i18n('user-exists');
            break;
          default:
            message = response.error.message;
        }
      }
    }

    return (
      <>
        <Header {...this.props} toMain={true} />
        <div className="PageContent">
          <div className={'LogContainer'}>
            <h3 align="center" className="RegisterLogHeaderTitle">
              {i18n('registration')}
            </h3>
            <form onSubmit={this.onHandleRegistration}>
              <div align="center">
                <InputGroup className="LogInputField">
                  <FormControl
                    type="text"
                    name="name"
                    id="name"
                    className="LogInputControl"
                    placeholder={i18n('what-is-your-name')}
                  />
                </InputGroup>
                <InputGroup className="LogInputField">
                  <FormControl
                    type="number"
                    name="age"
                    id="age"
                    className="LogInputControl"
                    placeholder={i18n('how-old')}
                  />
                </InputGroup>

                <InputGroup className="LogInputField">
                  <FormControl
                    type="text"
                    name="email"
                    id="email"
                    className="LogInputControl"
                    placeholder={i18n('your-email')}
                  />
                </InputGroup>
                <InputGroup className="LogInputField">
                  <FormControl
                    type="password"
                    name="password"
                    id="password"
                    className="LogInputControl"
                    placeholder={i18n('create-password')}
                  />
                </InputGroup>

                <Button
                  // bsStyle=""
                  style={{ backgroundColor: '#683da1' }}
                  // bsClass="btn"
                  className="RegisterButton"
                  type="submit"
                >
                  {i18n('further-btn')}
                </Button>

                {!isSuccess ? (
                  <p align="center" className="error-text">
                    {this.state.errorMessage || message}
                  </p>
                ) : (
                  <Redirect to="/select-course" />
                )}
              </div>
            </form>
          </div>
        </div>
        <Footer />
      </>
    );
  }
}

const mapStateToProps = response => ({
  response
});

export default connect(mapStateToProps)(RegisterPage);
