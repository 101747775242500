import React, { Component } from 'react';
import gift_ic from '../../../images/gift_ic.svg';
import i18n from '../../../translation/translation';
import { Link } from 'react-router-dom';
import '../completion/completion.css';

class LessonLimit extends Component {
  handleClick() {
    console.log('preved');
    this.props.history.push('/dashboard', {
      leftPanel: 'subscription',
      rightPanel: 'userstatus'
    });
  }

  render() {
    return (
      <div className="op-container">
        <div className="end-lesson-div" align="center">
          <img className="op-img" src={gift_ic} alt="gift_ic" />
          <label className="sc-lbl"> {i18n('sorry')} </label>
          <label className="usc-msg">{i18n('no_more_free_lessons')}</label>
          <button className="sc-btn" onClick={this.handleClick.bind(this)}>
            {' '}
            {i18n('try')}{' '}
          </button>
          <Link to="/dashboard">
            <p className="op-nt">{i18n('no-thanks')}</p>
          </Link>
        </div>
      </div>
    );
  }
}
export default LessonLimit;
