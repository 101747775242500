import React, { Component } from 'react';
import speach_round_ic from '../../../../images/speach_round_ic.svg';
import PlayBtnSimple from '../PlayBtnSimple';
import ExerciseLogic from '../ExerciseLogic';

import Sentence from './type10/Sentence';

class Type10 extends Component {
  state = {
    options: null,
    sentence: null,
    translation: null
  };

  handleOptionDisplay = () => {
    return this.state.options;
  };

  handleOptionSelect = (sentence, options, param) => {
    const nullArea = sentence.find(element => element.props.element === null);
    const optionArea = options.find(element => element.key === param);

    if (nullArea !== undefined) {
      if (nullArea.props.element === null) {
        //re set sentence (button) when first instance of null is found
        sentence[sentence.indexOf(nullArea)] = (
          <Sentence
            key={param}
            index={options.indexOf(optionArea)}
            clickable={true}
            handleDeselect={this.handleSentenceDeselect.bind(this)}
            element={param}
          />
        );

        //re set the options (button) when first instance of null is found
        options[options.indexOf(optionArea)] = (
          <button key={param} className="ex-options-btn option-hidden" disabled>
            {'      '}
          </button>
        );
      }
    }

    this.setState({
      sentence: sentence,
      options: options
    });
  };

  handleSentenceDeselect = (param, index) => {
    let options = this.state.options;
    let sentence = this.state.sentence;

    options[index] = (
      <button
        className="ex-options-btn"
        key={param}
        onClick={() =>
          this.handleOptionSelect(
            this.state.sentence,
            this.state.options,
            param
          )
        }
      >
        {param}
      </button>
    );

    sentence[
      sentence.indexOf(
        sentence.find(element => element.props.element === param)
      )
    ] = (
      <Sentence
        disabled={true}
        handleDeselect={this.handleSentenceDeselect.bind(this)}
        element={null}
      />
    );

    this.setState({
      options: options,
      sentence: sentence
    });
  };

  handleSentenceResponse = () => {
    // this.props.selectedValue(10, this.state.sentence)
    let sentence = [];

    this.state.sentence.forEach(element =>
      sentence.push(element.props.element)
    );

    return this.props.selectedValue(10, sentence);
  };

  componentDidMount = () => {
    const exercise = this.props.exercise;
    let optionData = exercise.type10_options[0].split('; ');
    let sentenceData = exercise.type10_sentence.split(' ');
    let options = [];
    let sentence = [];

    // Set array of options as individual buttons
    optionData.forEach(element => {
      options.push(
        <button
          className="ex-options-btn"
          key={element}
          onClick={() => {
            this.handleOptionSelect(
              this.state.sentence,
              this.state.options,
              element
            );
            this.handleSentenceResponse();
          }}
        >
          {element}
        </button>
      );
    });

    exercise.type10_missing_words.forEach(missing => {
      sentenceData[sentenceData.indexOf(missing)] = null;
    });

    // populate array of sentence data into sentence.
    sentenceData.forEach((element, index) => {
      sentence.push(
        <Sentence
          key={'type10-sentence-' + index}
          disabled={true}
          handleDeselect={this.handleSentenceDeselect.bind(this)}
          element={element}
        />
      );
    });

    this.setState({
      options: options,
      sentence: sentence,
      translation: exercise.type10_translation
    });
  };

  render() {
    const exercise = this.props.exercise;
    return (
      <div className="exercise-card-type10">
        <p>
          <PlayBtnSimple
            className="speach_round"
            alt="speach_ic"
            src={speach_round_ic}
            audio_file={ExerciseLogic.getAudioUrl(
              this.props.language,
              exercise.type10_sentence
            )}
          />
          {this.state.translation}
        </p>

        <div className="ex-sentence" align="center">
          {this.state.sentence}
        </div>

        <div className="ex-options" align="center">
          <p>{this.state.options}</p>
        </div>
      </div>
    );
  }
}

export default Type10;
