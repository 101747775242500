import React, { Component } from 'react';

import PlayBtnSimple from '../PlayBtnSimple';
import ExerciseLogic from '../ExerciseLogic';

import speach_round_ic from '../../../../images/speach_round_ic.svg';
import mic_btn from '../../../../images/mic_btn_normal.svg';
import i18n from '../../../../translation/translation';

class Type14 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      transcript: ''
    };
  }
  startRecongnition = () => {
    console.log('start');
    this.setState({
      transcript: ''
    });
    this.props.recognition.lang = this.mapToBcp47(this.props.language);
    this.props.resetTranscript();
    this.props.startListening();
  };

  finishRecongnition = () => {
    let text = this.props.transcript;
    console.log(text);
    this.setState({
      transcript: text
    });
    this.props.stopListening();
    this.props.selectedValue(14, text);
  };

  mapToBcp47 = lang => {
    if (lang === 'en') return 'en-US';
    if (lang === 'es') return 'es-ES';
    return 'en-US';
  };

  render() {
    const exercise = this.props.exercise;
    return (
      <div className="exercise-card-type14">
        <p>
          <PlayBtnSimple
            className="speach_round"
            alt="speach_ic"
            src={speach_round_ic}
            audio_file={ExerciseLogic.getAudioUrl(
              this.props.language,
              exercise.type14_sentence
            )}
          />
          {exercise.type14_sentence}
        </p>

        <p className="type14-recognized">{this.state.transcript}</p>

        <div className="exercise-subcard-type14">
          <div className="exercise-soundcard">
            <img
              className="type14-img"
              src={mic_btn}
              alt="mic_btn"
              draggable={false}
              onMouseDown={this.startRecongnition.bind(this)}
              onMouseUp={this.finishRecongnition.bind(this)}
            />
          </div>
          <p style={{ marginTop: '6px' }}> {i18n('record-hold-label')} </p>
        </div>

        <p
          className="clickable"
          onClick={() => {
            this.props.selectedValue(14, 'cannot-talk');
          }}
        >
          {' '}
          {i18n('cannot-talk')}{' '}
        </p>
      </div>
    );
  }
}

export default Type14;
