import React from 'react';
import { connect } from 'react-redux';
import '../main/dot-pulse.css';

let Loader = () => (
  <>
    <div className="mb-auto"></div>
    <div className="d-flex justify-content-center Loader">
      <div className="dot-pulse"></div>
    </div>
    <div className="mt-auto"></div>
  </>
);

const mapStateToProps = () => ({});
Loader = connect(
  mapStateToProps,
  null
)(Loader);
export default Loader;
