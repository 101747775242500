import React, { Component } from 'react';

class Program extends Component {
  handleClick = e => {
    this.props.onProgramClick(this.props.program);
  };

  render() {
    return (
      <div className="program-card" align="center">
        <label>
          <input
            type="radio"
            name="option1"
            className="card-input-element"
            onClick={this.handleClick}
          />
          <div className="card-choice card bg-default card-input">
            <div className="card-body text-center">
              <h4 className="program-card-header">{this.props.program.name}</h4>
              <p className="program-card-text">
                {' '}
                {this.props.program.description}
              </p>
            </div>
          </div>
        </label>
      </div>
    );
  }
}

export default Program;
