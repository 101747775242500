import React, { Component } from 'react';
import reg1_ic from '../../images/reg1_ic.svg';
import reg2_ic from '../../images/reg2_ic.svg';
import i18n from '../../translation/translation';

class RegisterThirdStep extends Component {
  handleClickKnowBasics() {
    this.props.onSelectLevel(true);
  }

  handleClickStart() {
    this.props.onSelectLevel(false);
  }

  render() {
    return (
      <div className="step3-layout">
        <div className="card-deck" align="center">
          <div align="center">
            <label>
              <input
                type="radio"
                name="option2"
                className="step3-card-input-element"
              />
              <div className="step3-card-choice card bg-default step3-card-input">
                <div
                  className="card-body text-center"
                  onClick={this.handleClickKnowBasics.bind(this)}
                >
                  <img src={reg1_ic} alt="basic" />

                  <h4 className="step3-card-footer">{i18n('i-know-basics')}</h4>
                </div>
              </div>
            </label>
          </div>

          <div align="center">
            <label>
              <input
                type="radio"
                name="option2"
                className="step3-card-input-element"
              />
              <div className="step3-card-choice card bg-default step3-card-input">
                <div
                  className="card-body text-center"
                  onClick={this.handleClickStart.bind(this)}
                >
                  <img src={reg2_ic} alt="start" />

                  <h4 className="step3-card-footer">
                    {i18n('start-beginning')}
                  </h4>
                </div>
              </div>
            </label>
          </div>
        </div>
      </div>
    );
  }
}

export default RegisterThirdStep;
