import * as types from './index';

export const registerUserAction = user => {
  return {
    type: types.REGISTER_USER,
    user
  };
};

export const loginUserAction = user => {
  return {
    type: types.LOGIN_USER,
    user
  };
};

export const logoutUserAction = accessToken => {
  return {
    type: types.LOGOUT_USER,
    accessToken
  };
};

export const resetPasswordAction = user => {
  return {
    type: types.RESET_PASSWORD,
    user
  };
};

export const changePasswordAction = user => {
  return {
    type: types.CHANGE_PASSWORD,
    user
  };
};

export const updateProfileAction = (user, accessToken) => {
  return {
    type: types.UPDATE_PROFILE,
    user,
    accessToken
  };
};
