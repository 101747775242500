import { takeLatest, takeEvery } from 'redux-saga/effects';
import {
  registerSaga,
  loginSaga,
  logoutSaga,
  resetPasswordSaga,
  changePasswordSaga,
  updateProfileSaga
} from './authenticationSaga';

import {
  loadDataSaga,
  loadUserSaga,
  loadCombinedDataSaga,
  saveUserProgressSaga,
  resetUserProgressSaga,
  loadLeaderboardSaga,
  saveUserSettingsSaga,
  saveUserStatisticsSaga,
  loadExercisesSaga,
  blitzExercisesSaga
} from './dataSaga';

import {
  adSaga,
  setLanguage,
  subscriptionSaga,
  cancelSubscriptionSaga,
  refreshDataSaga
} from './miscSaga';

import * as types from '../redux-actions';

export default function* watchUserAuthentication() {
  yield takeLatest(types.REGISTER_USER, registerSaga);
  yield takeLatest(types.LOGIN_USER, loginSaga);
  yield takeLatest(types.LOGOUT_USER, logoutSaga);
  yield takeLatest(types.RESET_PASSWORD, resetPasswordSaga);

  yield takeEvery(types.CHANGE_PASSWORD, changePasswordSaga);
  yield takeEvery(types.UPDATE_PROFILE, updateProfileSaga);

  yield takeLatest(types.LOAD_DATA, loadDataSaga);
  yield takeLatest(types.LOAD_USER_DATA, loadUserSaga);
  yield takeLatest(types.LOAD_COMBINED_DATA, loadCombinedDataSaga);

  yield takeLatest(types.LOAD_LEADERBOARD, loadLeaderboardSaga);
  yield takeLatest(types.LOAD_EXERCISES, loadExercisesSaga);
  yield takeLatest(types.LOAD_BLITZ, blitzExercisesSaga);

  yield takeEvery(types.SAVE_PROGRESS_DATA, saveUserProgressSaga);
  yield takeEvery(types.RESET_PROGRESS_DATA, resetUserProgressSaga);
  yield takeEvery(types.SAVE_SETTINGS_DATA, saveUserSettingsSaga);
  yield takeEvery(types.SAVE_STATISTICS_DATA, saveUserStatisticsSaga);

  yield takeLatest(types.AD_SHOW, adSaga);
  yield takeLatest(types.SET_LANGUAGE, setLanguage);
  yield takeLatest(types.GET_SUBSCRIPTION, subscriptionSaga);
  yield takeEvery(types.CANCEL_SUBSCRIPTION, cancelSubscriptionSaga);
  yield takeEvery(types.REFRESH_DATA, refreshDataSaga);
}
