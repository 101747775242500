import React, { Component } from 'react';
import { AppContext } from '../../main/AppContext';
import { loadLeaderboardAction } from '../../../redux-actions/dataActions';
import i18n from '../../../translation/translation';

import UserRating from './UserRating';
import AllUserRating from './AllUserRating';
import LoaderItem from '../../common/LoaderItem';
import '../dashboard.css';

class RatingList extends Component {
  render() {
    var response = this.props.response.data.response;
    let user = response.user;

    if (!this.props.response.leaderboard.hasOwnProperty('response')) {
      this.props.dispatch(loadLeaderboardAction());
      return <LoaderItem />;
    } else {
      let leaderboard = this.props.response.leaderboard.response;

      //TODO: find user's leaderboard item
      let userItem = leaderboard
        .filter(function(item) {
          return item.userId === user.id;
        })
        .slice(-1)[0] || { order: '' };

      return (
        <>
          <h5 className="mb-3"> {i18n('your-rating')} </h5>

          {/* User Rating Component */}
          <UserRating user={user} rank={userItem.order} />

          <p className="rating-table-text mb-5">{i18n('rating-update')}</p>

          <h5 className="mb-3"> {i18n('common-rating')} </h5>
          {/* All User Rating Component */}
          <AllUserRating {...this.props} leaderboard={leaderboard} />
        </>
      );
    }
  }
}

RatingList.contextType = AppContext;

export default RatingList;
