import React, { Component } from 'react';
import i18n from '../../translation/translation';
import InputMask from 'react-input-mask';
import RegisterButton from './RegisterButton';

class RegisterFourthStep extends Component {
  constructor() {
    super();
    this.labels = [
      i18n('day-mon'),
      i18n('day-tue'),
      i18n('day-wed'),
      i18n('day-thr'),
      i18n('day-fri'),
      i18n('day-sat'),
      i18n('day-sun')
    ];
    this.state = {
      switchReminder: false,
      inputTime: '',
      days: [false, false, false, false, false, false, false]
    };
  }

  handleCheck = (index, check) => {
    var newDays = this.state.days;
    newDays[index] = !check;
    this.setState({ days: newDays });
  };

  handleReminderTime = e => {
    this.setState({ inputTime: e.target.value });
  };

  handleSwitch = param => {
    this.setState({ switchReminder: !param });
  };

  handleSaveData() {
    this.props.handleData(this.state);
  }

  render() {
    const items = [];

    // eslint-disable-next-line
    for (const [index, label] of this.labels.entries()) {
      items.push(
        <li key={'day-key-' + index}>
          <label className="day-btn">
            <input
              className="day-cb"
              onChange={() => this.handleCheck(index, this.state.days[index])}
              type="checkbox"
              value={label}
            />
            <span> {label} </span>
          </label>
        </li>
      );
    }

    return (
      <div>
        <div className="step4-card-layout">
          <div className="step4-card">
            <div className="step4-card-header">
              <h5> {i18n('workout-reminders')} </h5>
            </div>
            <div className="step4-card-body">
              <div>
                <div className="step4-right-col">
                  <p className="step4-card-text">{i18n('choose-day-label')}</p>
                  <p className="step4-card-text">
                    {i18n('specify-time-label')}
                  </p>
                  <p className="step4-card-text">
                    {i18n('on-off-switch-motivation')}
                  </p>
                </div>
                <div className="step4-left-col">
                  <div className="step4-btn-input-switch-wrapper">
                    <ul
                      className="nav nav-tabs"
                      role="tablist"
                      style={{ borderBottom: '0px' }}
                    >
                      {items}
                    </ul>
                    <div className="tab-content">
                      <div>
                        <InputMask
                          className="step4-input reminders-input"
                          type="text"
                          aria-describedby="basic-addon1"
                          name="reminder"
                          id="reminder"
                          mask="99:99"
                          maskChar="_"
                          placeholder={i18n('reminder-placeholder')}
                          onChange={this.handleReminderTime}
                        />

                        <div
                          style={{ paddingTop: '22px' }}
                          className="custom-control custom-switch"
                        >
                          <input
                            type="checkbox"
                            className="custom-control-input"
                            onChange={() =>
                              this.handleSwitch(this.state.switchReminder)
                            }
                            id="switch"
                          />
                          <label
                            className="custom-control-label"
                            htmlFor="switch"
                          ></label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <RegisterButton
          {...this.state}
          handleClickEvent={this.handleSaveData.bind(this)}
        />
      </div>
    );
  }
}

export default RegisterFourthStep;
