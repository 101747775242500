import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import '../dashboard.css';
import i18n from '../../../translation/translation';
import warning from '../../../../src/images/ic-warning.svg';
import SubscriptionCard from './SubscriptionCard';
import BuySubscriptionModal from './BuySubscriptionModal';
import RefundPolicyModal from './RefundPolicyModal';
import dataHelpers from '../../../utils/dataHelpers';
import {
  subscriptionAction,
  cancelSubscriptionAction
} from '../../../redux-actions/miscActions';
import Loader from '../../common/Loader';

class Subscription extends Component {
  constructor() {
    super();

    this.initialState = {
      stripeModal: false,
      refundModal: false,
      cancelSubscription: false,
      currentSubscription: i18n('free'),
      cardClass: 'subscription-card',
      subscriptionTime: '∞'
    };

    this.state = this.initialState;
  }

  handleSubscription = param => {
    //this.setStripeModal(true);
  };

  setStripeModal = show => this.setState({ stripeModal: show });

  setRefundModal = show => this.setState({ refundModal: show });

  handleSubscriptionReset = () => {
    let user = dataHelpers.getUser();
    if (user && user.subscriptionId) {
      this.props.dispatch(cancelSubscriptionAction(user.subscriptionId));
      this.setState(this.initialState);
    }
  };

  static getDerivedStateFromProps(props, state) {
    if (props.response.subscription.hasOwnProperty('response')) {
      let subscription = props.response.subscription.response;
      var sub = 'free';
      switch (subscription.plan.id) {
        case '1_month':
          sub = '1m';
          break;
        case '3_month':
          sub = '3m';
          break;
        case '1_year':
        default:
          sub = '1y';
          break;
      }

      var remain = '∞';
      var cancelSubscription = true;
      if (subscription.status === 'canceled') {
        let end = subscription.current_period_end;
        let now = new Date().getTime() / 1000;
        let seconds = end - now;
        let days = Math.floor(seconds / (60 * 60 * 24));
        if (days > 0) {
          remain = days;
        } else {
          sub = 'free';
          remain = '∞';
        }
        cancelSubscription = false;
      }

      return {
        ...state,
        cancelSubscription,
        currentSubscription: i18n(sub),
        cardClass:
          sub === 'free' ? 'subscription-card' : 'subscription-card-subscribed',
        subscriptionTime: remain
      };
    }

    return null;
  }

  render() {
    if (!this.props.response.subscription.hasOwnProperty('response')) {
      let user = dataHelpers.getUser();
      if (user && user.subscriptionId) {
        this.props.dispatch(subscriptionAction(user.subscriptionId));
        return <Loader isLogged={true} />;
      }
    }

    const duration = ['1m', '3m', '1y'];
    const sub = [];

    duration.forEach(sub_duration =>
      sub.push(
        <SubscriptionCard
          handleSubscription={this.handleSubscription.bind(this)}
          key={sub_duration}
          dur={sub_duration}
        />
      )
    );

    return (
      <>
        <div className={`card ` + this.state.cardClass}>
          <div className="status-header general-header">
            {i18n('subscription-information')}
          </div>
          <div className="subscription-container">
            <div className="card-body subscription-body">
              <label>{i18n('current-subscription')} </label>
              <button disabled> {this.state.currentSubscription} </button>
              <br />
              <label> {i18n('day-left')} </label>
              <input type="text" value={this.state.subscriptionTime} disabled />
              <label
                className="refund-policy"
                onClick={() => {
                  this.setRefundModal(true);
                }}
              >
                {' '}
                {i18n('refund-policy')}{' '}
              </label>{' '}
              {/* {this.state.cancelSubscription ? (
                <div className="unsubscription-div">
                  <label> {i18n('unsubscribe')} </label>
                  <hr className="general-divider"></hr>
                  <div className="reset-warning-div">
                    <div className="warning-block">
                      {' '}
                      <img src={warning} alt="warning"></img>
                    </div>

                    <div className="general-reset-div">
                      <span className="unsubsribe-span">
                        {' '}
                        {i18n('cancel-subscription-label')}{' '}
                      </span>
                      <button
                        onClick={this.handleSubscriptionReset}
                        className="btn btn-default unsubscribe-btn"
                      >
                        {i18n('unsubscribe')}
                      </button>
                    </div>
                  </div>
                </div>
              ) : null} */}
            </div>
          </div>
        </div>

        <div className="subscriptions">
          <label> {i18n('choose-subscription')} </label>
          {sub}
        </div>

        {/* Modal for Subscription  */}
        <BuySubscriptionModal
          show={this.state.stripeModal}
          history={this.props.history}
          onHide={() => {
            this.setStripeModal(false);
          }}
          onQuit={() => {
            this.setStripeModal(false);
          }}
        />
        {/* Modal for Subscription  */}
        <RefundPolicyModal
          show={this.state.refundModal}
          history={this.props.history}
          onHide={() => {
            this.setRefundModal(false);
          }}
          onQuit={() => {
            this.setRefundModal(false);
          }}
        />
      </>
    );
  }
}

export default Subscription;
