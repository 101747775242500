import * as types from './index';

export const loadDataAction = user => {
  return {
    type: types.LOAD_DATA,
    user
  };
};

export const loadExercisesAction = lessonId => {
  return {
    type: types.LOAD_EXERCISES,
    lessonId
  };
};

export const blitzExercisesAction = lessonIds => {
  return {
    type: types.LOAD_BLITZ,
    lessonIds
  };
};

export const loadUserAction = userId => {
  return {
    type: types.LOAD_USER_DATA,
    userId
  };
};

export const loadCombinedAction = userId => {
  return {
    type: types.LOAD_COMBINED_DATA,
    userId
  };
};

export const saveProgressAction = progress => {
  return {
    type: types.SAVE_PROGRESS_DATA,
    progress
  };
};

export const resetProgressAction = user => {
  return {
    type: types.RESET_PROGRESS_DATA,
    user
  };
};

export const saveSettingsAction = settings => {
  return {
    type: types.SAVE_SETTINGS_DATA,
    settings
  };
};

export const saveStatisticsAction = statistics => {
  return {
    type: types.SAVE_STATISTICS_DATA,
    statistics
  };
};

export const loadLeaderboardAction = () => {
  return {
    type: types.LOAD_LEADERBOARD
  };
};
