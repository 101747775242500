import React, { Component } from 'react';
import i18n from '../../../translation/translation';

export default class SubscriptionCard extends Component {
  state = {
    color: ''
  };

  componentDidMount() {
    switch (this.props.dur) {
      case '1m':
        this.setState({ color: 'blue' });
        break;
      case '3m':
        this.setState({ color: 'orange' });
        break;
      default:
        this.setState({ color: 'green' });
        break;
    }
  }

  render() {
    return (
      <div className={`subscription-sub-card sub-` + this.state.color}>
        <div className="subscription-sub">
          <div className="subscription-sub-1">
            <label> {i18n(this.props.dur)} </label>
            <p> {i18n('remove-ads')} </p>
          </div>

          <input
            className={
              `subscription-sub-input sub-` + this.state.color + `-inp`
            }
            type="text"
            value={i18n(this.props.dur + `-amount`)}
            disabled
          />
        </div>

        <button
          onClick={() => this.props.handleSubscription(this.props.dur)}
          className={`subscription-sub-button sub-` + this.state.color + `-btn`}
        >
          {i18n('buy-subscription')}
        </button>
      </div>
    );
  }
}
