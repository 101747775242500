import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import close_ic from '../../../images/close_ic.svg';
import info_ic from '../../../images/info_ic.svg';
import './exercise.css';
import ExerciseLogic from './ExerciseLogic';
import ExerciseRule from './ExerciseRule';
import ExerciseCards from './ExerciseCards';
import ExitExercise from './ExerciseExit';
import ExerciseFooter from './ExerciseFooter';
import Loader from '../../common/Loader';
import i18n from '../../../translation/translation';
import { showAdAction } from '../../../redux-actions/miscActions';
import SpeechRecognition from 'react-speech-recognition';

import { CSSTransition, TransitionGroup } from 'react-transition-group';

import {
  loadExercisesAction,
  blitzExercisesAction,
  loadCombinedAction
} from '../../../redux-actions/dataActions';
import dataHelpers from '../../../utils/dataHelpers';

class Exercise extends Component {
  constructor(props) {
    super(props);

    if (
      !dataHelpers.isPremium(this.props) &&
      !dataHelpers.hasFreeLessons(this.props)
    ) {
      window.location.href = '/limit';
    }

    this.isBlitz = this.props.location.state
      ? !!this.props.location.state.blitzTopics
      : false;
    this.givenAnswers = null;

    this.state = {
      modalShow: false,
      modalExit: false,
      modalAd: false,
      shouldShowAd: false,
      lastOne: false,
      current: 0,
      progress: 0,
      allowableMistakes: this.isBlitz ? 3 : null,
      type2_value: null,
      type3_selected: null,
      type4_selected: null,
      type5_selected: null,
      type6_selected: null,
      type7_selected: null,
      type8_selected: null,
      type9_input: null,
      type10_input: null,
      type11_input: null,
      type12_selected: null,
      type13_input: null,
      type14_input: null,
      type15_value: null,
      footerView: 'default',
      blitzExercises: null
    };

    this.resetStats = false;
    this.reloadExercises();
    // debug
    //localStorage.setItem('cannot-talk', 0);
  }

  resetAnswers() {
    this.setState({
      type2_value: null,
      type3_selected: null,
      type4_selected: null,
      type5_selected: null,
      type6_selected: null,
      type7_selected: null,
      type8_selected: null,
      type9_input: null,
      type10_input: null,
      type11_input: null,
      type12_selected: null,
      type13_input: null,
      type14_input: null,
      type15_value: null
    });
  }

  reloadExercises() {
    this.givenAnswers = null;

    if (this.props.location.state.blitzTopics) {
      var lessonIds = [];
      for (const topic of this.props.location.state.blitzTopics) {
        for (const lesson of topic.lessons) {
          lessonIds.push(lesson.id);
        }
      }
      this.props.dispatch(blitzExercisesAction(lessonIds));
    } else {
      let lessonId = this.props.location.state.currentLessonId;
      this.props.dispatch(loadExercisesAction(lessonId));
    }
  }

  getNextIndex = param => {
    if (this.correctAnswers() === param.length) {
      return null;
    }
    if (param.length - this.correctAnswers() === 1) {
      this.setState({
        lastOne: !this.state.lastOne
      });
    }
    var next = this.state.current;
    do {
      next++;
      if (next >= param.length) {
        next = 0;
      }
    } while (this.givenAnswers[next]);
    return next;
  };

  setModalShow = param => {
    this.setState({ modalShow: param });
  };

  setModalExit = param => {
    this.setState({ modalExit: param });
  };

  setModalAd = param => {
    this.props.dispatch(showAdAction(true));
  };

  setCurrent = param => {
    this.setState({ current: param });
  };

  setProgress = param => {
    this.setState({ progress: param });
  };

  setBlitz = () => {
    this.setState({ allowableMistakes: 3 });
  };

  correctAnswers = () => {
    var count = 0;
    for (const answer of this.givenAnswers) {
      if (answer) {
        count++;
      }
    }
    return count;
  };

  handleProgress = () => {
    var exercises = this.props.response.exercises.response;
    let stepSize = 95 / exercises.length;
    let progress = 5 + stepSize * this.correctAnswers();
    this.setProgress(progress);
  };

  userAnswers = (type, value) => {
    switch (type) {
      case 2:
        this.setState({ type2_value: value });
        break;
      case 3:
        this.setState({ type3_selected: value });
        break;
      case 4:
        this.setState({ type4_selected: value });
        break;
      case 5:
        this.setState({ type5_selected: value });
        break;
      case 6:
        this.setState({ type6_selected: value });
        break;
      case 7:
        this.setState({ type7_selected: value });
        break;
      case 8:
        this.setState({ type8_selected: value });
        break;
      case 9:
        this.setState({ type9_input: value });
        break;
      case 10:
        this.setState({ type10_input: value });
        break;
      case 11:
        this.setState({ type11_input: value });
        break;
      case 12:
        this.setState({ type12_selected: value });
        break;
      case 13:
        this.setState({ type13_input: value });
        break;
      case 14:
        if (value === 'cannot-talk') {
          this.setState({ footerView: 'cannot-talk' });
        } else {
          this.setState({ type14_input: value });
        }
        break;
      case 15:
        this.setState({ type15_value: value });
        break;
      default:
        return;
    }
  };

  saveLessonProgress = param => {
    let filtered = this.getLessons().filter(
      i => i.id === this.props.location.state.currentLessonId
    );
    let currentLesson = filtered ? filtered[0] : undefined;

    if (this.isBlitz) {
      let lastExercise = param.slice(-1)[0];
      currentLesson = dataHelpers.getLessonById(
        this.props.response.data.response.languages,
        lastExercise.lessonId
      );
    }

    let lessonLearned = dataHelpers.lessonHasLearned(
      this.props.response.data.response,
      currentLesson
    );

    let topicLearned =
      lessonLearned &&
      dataHelpers.topicHasLearned(
        this.props.response.data.response,
        currentLesson
      );

    if (topicLearned) {
      ExerciseLogic.onFinishedTopic();
    }

    if (
      currentLesson.type === 2 &&
      !localStorage.getItem('ex2-' + currentLesson.id)
    ) {
      lessonLearned = true;
      localStorage.setItem('ex2-' + currentLesson.id, '1');
      ExerciseLogic.onMoreWords();
    }

    if (lessonLearned) {
      let statuses = this.props.response.data.response.status;
      let hasNewStatus = ExerciseLogic.hasNewStatus(statuses);
      let startPoints = ExerciseLogic.getNumber('startPoints');
      let points = startPoints + ExerciseLogic.getNumber('addPoints');
      let newStatus = dataHelpers.getStatusByPoints(points, statuses);

      dataHelpers.useFreeLesson(this.props);

      ExerciseLogic.commitStatitics(
        this.props,
        this.props.response.data.response.user,
        currentLesson
      );

      if (this.isBlitz) {
        let errorCount = ExerciseLogic.getNumber('errorCount');
        if (errorCount <= 4) {
          return this.props.history.push('/successful', {
            isBlitz: true
          });
        } else {
          return this.props.history.push('/unsuccessful');
        }
      }

      let endLessonState = {
        topics: this.props.location.state.topics,
        currentTopic: this.props.location.state.currentTopic,
        lessons: this.props.location.state.lessons,
        currentLessonId: this.props.location.state.currentLessonId
      };

      if (currentLesson.type === 2) {
        return this.props.history.push('/dashboard');
      } else if (hasNewStatus) {
        let isBlitz = false;
        return this.props.history.push('/successful', {
          newStatus,
          endLessonState,
          isBlitz
        });
      } else {
        return this.props.history.push('/endlesson', endLessonState);
      }
    } else {
      return this.props.history.push('/dashboard');
    }
  };

  resetFooterView = param => {
    this.resetAnswers();
    if (this.correctAnswers() === param.length) {
      this.saveLessonProgress(param);
    } else {
      this.setState({
        footerView: 'default',
        current: this.getNextIndex(param)
      });
    }
  };

  equalCaseInsensitive = (a, b) => {
    if (typeof a !== 'string' || typeof b !== 'string') {
      return a === b;
    } else {
      return a.toLowerCase() === b.toLowerCase();
    }
  };

  getLessons() {
    if (this.props.location.state.blitzTopics) {
      var lessons = [];
      for (let topic of this.props.location.state.blitzTopics) {
        lessons = lessons.concat(topic.lessons);
      }
      return lessons;
    } else {
      return this.props.location.state.lessons;
    }
  }

  render() {
    if (!this.props.location.state) {
      return <Redirect to="/" />;
    }

    if (this.isBlitz && this.state.allowableMistakes < 1) {
      this.props.history.push('/unsuccessful');
      return <Loader />;
    }

    if (!this.props.response.exercises.hasOwnProperty('response')) {
      return <Loader />;
    }

    if (!this.props.response.data.hasOwnProperty('response')) {
      this.props.dispatch(loadCombinedAction(dataHelpers.getUser().id));
      return <Loader />;
    }

    if (!this.resetStats) {
      let points = this.props.response.data.response.user.statistics.points;
      ExerciseLogic.resetLessonPoints(points);
      this.resetStats = true;
    }

    let lang = (localStorage.getItem('course-lang') || 'EN').toLowerCase();
    let lessons = this.getLessons();
    let info = lessons.filter(l => l.type === 1);
    var infohtml = info.length ? info[0].html || '' : '';
    infohtml = infohtml.replace(
      /"speak:\/\/(.*?)"/g,
      '"javascript:cloodeeSpeak(\'' + lang + "', '$1')\""
    );

    var exercises = this.props.response.exercises.response;
    let progress = 5;
    let cannotTalkTime = Number(localStorage.getItem('cannot-talk') || '0');
    let cannotTalk = new Date().getTime() - cannotTalkTime < 3600000;

    if (!this.props.browserSupportsSpeechRecognition || cannotTalk) {
      exercises = exercises.filter(item => item.type !== 14);
      if (this.state.current >= exercises.length) {
        // have to modify state directly to avoid loop
        // eslint-disable-next-line
        this.state.current = exercises.length - 1;
      }
    }

    if (!this.givenAnswers) {
      this.givenAnswers = exercises.map(() => false);
    }
    let exercise = exercises[this.state.current];

    const handleSkipExercise = () => {
      this.givenAnswers[this.state.current] = true;
      this.handleProgress();
      this.resetAnswers();
      const next = this.getNextIndex(exercises);
      if (next !== null) {
        this.setCurrent(next);
      } else {
        this.resetFooterView(exercises);
      }
    };

    const handleAnswers = (type, status) => {
      let footerView = 'default';

      switch (type) {
        case 1:
          this.givenAnswers[this.state.current] = true;
          handleSkipExercise();
          this.handleProgress();
          ExerciseLogic.onAddWord();
          break;
        case 2:
          footerView =
            status === undefined
              ? this.state.type2_value
              : this.equalCaseInsensitive(status, this.state.type2_value);
          break;
        case 3:
          footerView = exercise.type3_isCorrect[this.state.type3_selected]
            ? true
            : false;
          break;
        case 4:
          footerView = exercise.type4_isCorrect[this.state.type4_selected]
            ? true
            : false;
          break;
        case 5:
          footerView = exercise.type5_isCorrect[this.state.type5_selected]
            ? true
            : false;
          break;
        case 6:
          footerView = exercise.type6_isCorrect[this.state.type6_selected]
            ? true
            : false;
          break;
        case 7:
          this.givenAnswers[this.state.current] = true;
          handleSkipExercise();
          this.handleProgress();
          break;
        case 8:
          footerView = exercise.type8_isCorrect[this.state.type8_selected]
            ? true
            : false;
          break;
        case 9:
          footerView =
            this.state.type9_input !== null
              ? this.state.type9_input.join(' ') ===
                exercise.type9_in_correct_order
              : false;
          break;
        case 10:
          footerView =
            this.state.type10_input !== null
              ? this.equalCaseInsensitive(
                  this.state.type10_input.join(' '),
                  exercise.type10_sentence
                )
              : false;
          break;
        case 11:
          footerView = false;
          let answers = exercise.type11_correct_answers.replace('; ', ', ');
          let correctData = answers.split(', ');
          // eslint-disable-next-line
          for (let correct of correctData) {
            footerView = this.equalCaseInsensitive(
              correct,
              this.state.type11_input
            );

            if (footerView) {
              break;
            }
          }

          break;
        case 12:
          footerView = exercise.type12_isCorrect[this.state.type12_selected]
            ? true
            : false;
          break;
        case 13:
          footerView = false;
          // eslint-disable-next-line
          for (let correct of exercise.type13_correct_answers) {
            footerView = this.equalCaseInsensitive(
              correct,
              this.state.type13_input
            );

            if (footerView) {
              break;
            }
          }

          break;
        case 14:
          footerView = this.equalCaseInsensitive(
            exercise.type14_sentence,
            this.state.type14_input
          );
          break;
        case 15:
          footerView =
            status === undefined
              ? this.state.type15_value
              : this.equalCaseInsensitive(status, this.state.type15_value);
          break;
        default:
          return;
      }

      if (footerView === true) {
        this.givenAnswers[this.state.current] = true;
        this.setState({ footerView: 'correct' });
        this.handleProgress();
        if (!this.isBlitz) {
          ExerciseLogic.onCorrectAnswer();
        }
      } else if (footerView === false) {
        this.setState({
          footerView: 'incorrect',
          allowableMistakes:
            this.state.allowableMistakes - (this.isBlitz ? 1 : 0)
        });
        ExerciseLogic.onIncorrectAnswer();
      } else {
        this.setState({ footerView: 'default' });
      }
    };

    const cardLabelHandler = exercise => {
      switch (exercise.type) {
        case 1:
          return i18n('type1-label');
        case 2:
          return i18n('type2-label');
        case 3:
          return exercise.type3_word;
        case 4:
          return i18n('choose') + ' «' + exercise.type4_word + '»';
        case 5:
          return i18n('choose') + ' «' + exercise.type5_word + '»';
        case 6:
          return i18n('type6-label');
        case 7:
          return i18n('type7-label');
        case 8:
          return i18n('type8-label');
        case 9:
          return i18n('type9-label');
        case 10:
          return i18n('type10-label');
        case 11:
          return exercise.type11_question;
        case 12:
          return i18n('type12-label');
        case 13:
          return i18n('type13-label');
        case 14:
          return i18n('type14-label');
        case 15:
          return exercise.type15_title;
        default:
          return;
      }
    };

    return (
      <>
        <div className="exercise-header">
          <div className="ex-header-close">
            <img
              src={close_ic}
              alt="close"
              onClick={() => this.setModalExit(true)}
            />
          </div>
          <span className="ex-header-spacer"></span>
          <div className="progress ex-header-progress">
            <div
              className="progress-bar bg-warning ex-bg-warning"
              role="progressbar"
              aria-valuenow={this.state.progress || progress}
              aria-valuemin="0"
              aria-valuemax="100"
              style={{
                width: (this.state.progress || progress) + '%'
              }}
            >
              .
            </div>
          </div>
          <span className="ex-header-spacer"></span>

          <div className="ex-header-info">
            <img
              src={info_ic}
              alt="info"
              onClick={() => this.setModalShow(true)}
            />
          </div>
        </div>
        <div className="exercise-wrapper">
          <TransitionGroup className="exercise-body-wrapper">
            <CSSTransition
              key={exercises[this.state.current].id + '-' + this.state.lastOne}
              timeout={500}
              classNames="slide"
            >
              <div className="exercisebody">
                <h5> {cardLabelHandler(exercises[this.state.current])} </h5>
                <ExerciseCards
                  {...this.props}
                  selectedValue={this.userAnswers.bind(this)}
                  exercise={exercise}
                  language={lang}
                />
              </div>
            </CSSTransition>
          </TransitionGroup>
        </div>

        <ExerciseFooter
          isBlitz={this.isBlitz}
          answers={this.state}
          data={this.state.current}
          exercises={exercises}
          languages={this.props.response.data.response.languages}
          footerView={this.state.footerView}
          allowableMistakes={this.state.allowableMistakes}
          resetFooterView={this.resetFooterView.bind(this)}
          handleAnswers={handleAnswers.bind(this)}
          handleSkipData={handleSkipExercise.bind(this)}
          handleProgress={this.handleProgress.bind(this)}
        />

        <div className="ex-preload-images"></div>

        {/* <FakeModalAd {...this.props} /> */}

        {/* Modal for Info */}
        <ExerciseRule
          show={this.state.modalShow}
          info={infohtml}
          onHide={() => this.setModalShow(false)}
        />
        {/* Modal for Exit  */}
        <ExitExercise
          show={this.state.modalExit}
          history={this.props.history}
          onHide={() => {
            this.setModalExit(false);
          }}
          onQuit={() => {
            let premium = dataHelpers.isPremium(this.props);
            if (!premium && this.state.shouldShowAd) {
              // this.setModalExit(false);
              // this.setModalAd(true);
              window.location.href = '/dashboard';
            } else {
              window.location.href = '/dashboard';
              // this.props.history.push('/dashboard', {
              //   leftPanel: 'default',
              //   rightPanel: 'userstatus'
              // });
            }
          }}
        />
      </>
    );
  }
}

const speechOptions = {
  autoStart: false
};

const mapStateToProps = response => ({ response });

export default connect(mapStateToProps)(
  SpeechRecognition(speechOptions)(Exercise)
);
