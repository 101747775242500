import React, { Component } from 'react';
import '../dashboard.css';

import { Modal, Button } from 'react-bootstrap';
import i18n from '../../../translation/translation';

class ExerciseReportConfirm extends Component {
  render() {
    return (
      <>
        <Modal
          {...this.props}
          aria-labelledby="contained-modal-title-vcenter"
          className="report-confirm-modal"
          centered
        >
          <Modal.Header className="modal-header-reset">
            <Modal.Title
              className="modal-title-exit"
              id="contained-modal-title-vcenter"
            >
              {i18n('report-an-error').toUpperCase()}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="modal-body-exercise modal-confirm-body">
            <p>{i18n('deal-message')}</p>

            <span style={{ float: 'right', marginBottom: '0px' }}>
              <Button
                className="modal-btn-exit"
                variant="default"
                onClick={this.props.onHide}
              >
                {i18n('done')}
              </Button>
            </span>
          </Modal.Body>
        </Modal>
      </>
    );
  }
}

export default ExerciseReportConfirm;
