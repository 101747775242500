import React, { Component } from 'react';
import './exercise.css';
import i18n from '../../../translation/translation';

class ExerciseBtn extends Component {
  render() {
    let buttonDisplay = () => {
      if (this.props.btnType === 1 || this.props.btnType === 7) {
        // for types 1 and 7 as identical
        return i18n('further');
      } else if (this.props.btnType === 2 || this.props.btnType === 15) {
        // for types 2 and 15 as identical
        return i18n('right');
      } else {
        // for types 3,4,5,6,8,9,10,11,12,13,14
        return i18n('verify');
      }
    };

    let exercise = this.props.exercises[this.props.data];
    let type = exercise.type;
    return (
      // Button display on footer normal
      <button
        className="btn btn-default ex-further-btn"
        onClick={() => this.props.handleAnswers(this.props.btnType)}
        disabled={
          type === 3 && this.props.answers.type3_selected === null
            ? true
            : type === 4 && this.props.answers.type4_selected === null
            ? true
            : type === 5 && this.props.answers.type5_selected === null
            ? true
            : type === 6 && this.props.answers.type6_selected === null
            ? true
            : type === 7 && this.props.answers.type7_selected === null
            ? true
            : type === 8 && this.props.answers.type8_selected === null
            ? true
            : type === 9 && this.props.answers.type9_input === null
            ? true
            : type === 10 && this.props.answers.type10_input === null
            ? true
            : type === 11 && this.props.answers.type11_input === null
            ? true
            : type === 12 && this.props.answers.type12_selected === null
            ? true
            : type === 13 && this.props.answers.type13_input === null
            ? true
            : type === 14 && this.props.answers.type14_input === null
            ? true
            : false
        }
      >
        {buttonDisplay()}
      </button>
    );
  }
}

export default ExerciseBtn;
