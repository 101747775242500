import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { AppContext } from '../main/AppContext';

import { loadCombinedAction } from '../../redux-actions/dataActions';
import dataHelpers from '../../utils/dataHelpers';

import Loader from '../common/Loader';
import Header from '../common/Header';
import Footer from '../common/Footer';
import FooterLayout from '../common/footer/FooterLayout';

class Info extends Component {
  state = {
    redirectDashboard: false
  };

  handleDashboardView = (left, right) => {
    this.setState({
      leftPanel: left,
      rightPanel: right,
      redirectDashboard: !this.state.redirectDashboard
    });
  };

  render() {
    if (this.state.redirectDashboard) {
      return (
        <Redirect
          to={{
            pathname: '/',
            state: {
              leftPanel: this.state.leftPanel,
              rightPanel: this.state.rightPanel
            }
          }}
        />
      );
    }

    if (!this.props.response.data.hasOwnProperty('response')) {
      let user = JSON.parse(localStorage.getItem('user'));
      if (user) {
        this.props.dispatch(loadCombinedAction(user.id));
        return <Loader />;
      } else {
        return (
          <>
            <Header
              {...this.props}
              isLogged={false}
              viewHandler={this.handleDashboardView.bind(this)}
            />
            <FooterLayout {...this.props} />
            <Footer />
          </>
        );
      }
    } else {
      var response = this.props.response.data.response;

      localStorage.setItem('user', JSON.stringify(response.user));

      let user = response.user;
      let languages = response.languages;

      let last = dataHelpers.getSelectedProgress(user);
      var packge, program;
      if (last) {
        packge = dataHelpers.getPackageById(languages, last.packageId);
        program = dataHelpers.getProgramById(languages, last.programId);
      }

      return (
        <>
          <Header
            {...this.props}
            data={user}
            package={packge}
            program={program}
            isLogged={last != null}
            viewHandler={this.handleDashboardView.bind(this)}
          />
          <FooterLayout {...this.props} />
          <Footer />
        </>
      );
    }
  }
}

Info.contextType = AppContext;

const mapStateToProps = response => ({ response });

export default connect(mapStateToProps)(Info);
