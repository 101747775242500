import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { logoutUserAction } from '../../redux-actions/authActions';
//import { setLanguageAction } from '../../redux-actions/miscActions';
import { getCookie, deleteCookie } from '../../utils/cookies';
import { AppContext } from '../main/AppContext';

class Logout extends Component {
  render() {
    let accessToken = getCookie('accessToken');
    if (accessToken != null) {
      this.props.dispatch(logoutUserAction(accessToken));

      deleteCookie('accessToken');
      let lang = localStorage.getItem('ui-language');
      let free = localStorage.getItem('free-lessons');
      localStorage.clear();
      localStorage.setItem('ui-language', lang);
      localStorage.setItem('free-lessons', free);
    }

    if (this.props.response.logout.hasOwnProperty('response')) {
      // eslint-disable-next-line
      var response = this.props.response.logout.response;
    }

    return accessToken == null ? <Redirect to="/login" /> : <></>;
  }
}

Logout.contextType = AppContext;

const mapStateToProps = response => ({ response });

export default connect(mapStateToProps)(Logout);
