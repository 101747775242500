import { put, call } from 'redux-saga/effects';
import dataHelpers from '../utils/dataHelpers';

import {
  dataService,
  userService,
  statusService,
  saveProgressService,
  resetProgressService,
  leaderboardService,
  saveSettingsService,
  saveStatisticsService,
  exercisesService,
  blitzService,
  configService
} from '../services/dataService';

import * as types from '../redux-actions';

export function* loadDataSaga(payload) {
  try {
    const response = yield call(dataService, payload);
    yield put({ type: types.LOAD_DATA_SUCCESS, response });
  } catch (error) {
    yield put({ type: types.LOAD_DATA_ERROR, error });
  }
}

export function* loadExercisesSaga(payload) {
  try {
    const response = yield call(exercisesService, payload);
    yield put({ type: types.LOAD_EXERCISES_SUCCESS, response });
  } catch (error) {
    yield put({ type: types.LOAD_EXERCISES_ERROR, error });
  }
}

export function* blitzExercisesSaga(payload) {
  try {
    const response = yield call(blitzService, payload);
    yield put({ type: types.LOAD_BLITZ_SUCCESS, response });
  } catch (error) {
    yield put({ type: types.LOAD_BLITZ_ERROR, error });
  }
}

export function* loadUserSaga(payload) {
  try {
    const response = yield call(userService, payload.userId);
    yield put({ type: types.LOAD_USER_DATA_SUCCESS, response });
  } catch (error) {
    yield put({ type: types.LOAD_USER_DATA_ERROR, error });
  }
}

export function* loadStatusSaga(payload) {
  try {
    const response = yield call(userService, payload.userId);
    yield put({ type: types.LOAD_USER_DATA_SUCCESS, response });
  } catch (error) {
    yield put({ type: types.LOAD_USER_DATA_ERROR, error });
  }
}

export function* loadLeaderboardSaga(payload) {
  try {
    const response = yield call(leaderboardService, payload.userId);
    yield put({ type: types.LOAD_LEADERBOARD_SUCCESS, response });
  } catch (error) {
    yield put({ type: types.LOAD_LEADERBOARD_ERROR, error });
  }
}

export function* loadCombinedDataSaga(payload) {
  try {
    const languages = yield call(dataService, {});
    const user = yield call(userService, payload.userId);
    const status = yield call(
      statusService,
      dataHelpers.getPreferredLanguageId(user, languages)
    );
    const config = yield call(configService, {});

    const response = { languages, user, status, config };
    yield put({ type: types.LOAD_COMBINED_DATA_SUCCESS, response });
  } catch (error) {
    yield put({ type: types.LOAD_COMBINED_DATA_ERROR, error });
  }
}

export function* saveUserProgressSaga(payload) {
  try {
    const response = yield call(saveProgressService, payload);

    yield put({ type: types.SAVE_PROGRESS_DATA_SUCCESS, response });
  } catch (error) {
    yield put({ type: types.SAVE_PROGRESS_DATA_ERROR, error });
  }
}

export function* resetUserProgressSaga(payload) {
  try {
    const response = yield call(resetProgressService, payload);

    yield put({ type: types.RESET_PROGRESS_DATA_SUCCESS, response });
  } catch (error) {
    yield put({ type: types.RESET_PROGRESS_DATA_ERROR, error });
  }
}

export function* saveUserSettingsSaga(payload) {
  try {
    const response = yield call(saveSettingsService, payload);

    yield put({ type: types.SAVE_SETTINGS_DATA_SUCCESS, response });
  } catch (error) {
    yield put({ type: types.SAVE_SETTINGS_DATA_ERROR, error });
  }
}

export function* saveUserStatisticsSaga(payload) {
  try {
    const response = yield call(saveStatisticsService, payload);

    yield put({ type: types.SAVE_STATISTICS_DATA_SUCCESS, response });
  } catch (error) {
    yield put({ type: types.SAVE_STATISTICS_DATA_ERROR, error });
  }
}
