import React, { Component } from 'react';
import speach_round_ic from '../../../../images/speach_round_ic.svg';
import i18n from '../../../../translation/translation';
import PlayBtnSimple from '../PlayBtnSimple';
import ExerciseLogic from '../ExerciseLogic';

class Type13 extends Component {
  constructor() {
    super();
    this.state = {
      help: null,
      index: 0
    };
  }

  userAnswer = () => {
    if (this.state.help.length <= 0) {
      this.setState({ help: null }, () =>
        this.props.selectedValue(13, this.state.help)
      );
    }

    this.props.selectedValue(13, this.state.help);
  };

  initiateHelp = () => {
    const exercise = this.props.exercise;
    let initialAnswer = exercise.type13_correct_answers;
    let answer = [];

    try {
      let splitWords = initialAnswer[0].split(' ');

      for (let i = 0; i <= this.state.index; i++) {
        answer.push(splitWords[i]);
      }
    } catch (e) {
      answer = initialAnswer;
    }

    answer = answer.join(' ');

    this.setState(
      {
        help: answer.trim(),
        index: this.state.index + 1
      },
      () => this.userAnswer()
    );
  };

  render() {
    const exercise = this.props.exercise;
    return (
      <div className="exercise-card-type13">
        <p>
          <PlayBtnSimple
            className="speach_round"
            alt="speach_ic"
            src={speach_round_ic}
            audio_file={ExerciseLogic.getAudioUrl(
              this.props.language,
              exercise.type13_word_or_phrase
            )}
          />
          {i18n('listen-more')}
        </p>
        <textarea
          rows="4"
          className="ex-text-area13"
          onChange={event =>
            this.setState({ help: event.target.value }, () => this.userAnswer())
          }
          value={this.state.help || ''}
        ></textarea>
        <p className="ex-help-link" onClick={() => this.initiateHelp()}>
          {' '}
          {i18n('need-help')}{' '}
        </p>
      </div>
    );
  }
}

export default Type13;
