import React, { Component } from 'react';
import speach_round_ic from '../../../../images/speach_round_ic.svg';
import radio_unchecked from '../../../../images/radio_unchecked.svg';
import radio_checked from '../../../../images/radio_checked.svg';
import PlayBtnSimple from '../PlayBtnSimple';
import ExerciseLogic from '../ExerciseLogic';

class Type8 extends Component {
  constructor() {
    super();
    this.initialState = {
      choice1: false,
      choice2: false,
      choice3: false,
      choice4: false,
      selectedWord: null
    };

    this.state = this.initialState;
  }

  handleRadioCheck = (exercise, param) => {
    this.setState(this.initialState);

    let answer = exercise.type8_answer[param];

    switch (param) {
      case 0:
        this.setState({ choice1: true, selectedWord: answer });
        this.props.selectedValue(8, param);
        break;
      case 1:
        this.setState({ choice2: true, selectedWord: answer });
        this.props.selectedValue(8, param);
        break;
      case 2:
        this.setState({ choice3: true, selectedWord: answer });
        this.props.selectedValue(8, param);
        break;
      case 3:
        this.setState({ choice4: true, selectedWord: answer });
        this.props.selectedValue(8, param);
        break;
      default:
        return;
    }
  };

  handleRadioButton = (exercise, param) => {
    switch (param) {
      case 0:
        return (
          <img
            alt="type8_option"
            className="type6_option"
            src={this.state.choice1 ? radio_checked : radio_unchecked}
            onClick={() => this.handleRadioCheck(exercise, param)}
          />
        );

      case 1:
        return (
          <img
            alt="type8_option"
            className="type6_option"
            src={this.state.choice2 ? radio_checked : radio_unchecked}
            onClick={() => this.handleRadioCheck(exercise, param)}
          />
        );

      case 2:
        return (
          <img
            alt="type8_option"
            className="type6_option"
            src={this.state.choice3 ? radio_checked : radio_unchecked}
            onClick={() => this.handleRadioCheck(exercise, param)}
          />
        );

      case 3:
        return (
          <img
            alt="type8_option"
            className="type6_option"
            src={this.state.choice4 ? radio_checked : radio_unchecked}
            onClick={() => this.handleRadioCheck(exercise, param)}
          />
        );

      default:
        return;
    }
  };

  handleSentence = data => {
    let filterSentence = data.type8_answer[data.type8_isCorrect.indexOf(true)];
    let sentence = data.type8_sentence.toLowerCase().split(' ');

    // eslint-disable-next-line
    for (let i of sentence) {
      let ix = sentence.indexOf(i.toLowerCase());
      if (
        i.toLowerCase() ===
        sentence[sentence.indexOf(filterSentence.toLowerCase())]
      ) {
        sentence[ix] = this.state.selectedWord ? (
          <span key={data.id + '-1-' + i + '-' + ix}>
            {this.state.selectedWord}
          </span>
        ) : (
          <span
            key={data.id + '-2-' + i + '-' + ix}
            style={{
              width: '35px',
              display: 'inline-block',
              borderBottom: `1px solid #FFD14C`
            }}
          ></span>
        );
      } else {
        sentence[ix] = <span key={data.id + '-3-' + i + '-' + ix}> {i} </span>;
      }
    }

    return sentence;
  };

  render() {
    const subcards = [];
    const exercise = this.props.exercise;

    exercise.type8_answer.forEach((param, index) => {
      subcards.push(
        <div
          className="div-radio"
          key={index}
          onClick={() => this.handleRadioCheck(exercise, index)}
        >
          <label className="type6_label">
            {this.handleRadioButton(exercise, index)}
            {param}
          </label>
          <hr className="exercise-type-divider"></hr>
        </div>
      );
    });

    return (
      <div className="exercise-card-type8">
        <p> {exercise.type8_translation} </p>
        <p>
          <PlayBtnSimple
            src={speach_round_ic}
            className="speach_round"
            audio_file={ExerciseLogic.getAudioUrl(
              this.props.language,
              exercise.type8_sentence
            )}
          />
          {this.handleSentence(exercise)}
        </p>
        {subcards}
      </div>
    );
  }
}

export default Type8;
