import React, { Component } from 'react';
import done_ic from '../../images/done_ic.svg';
import './dashboard.css';
import { backend } from '../../config';

class ThemesHeader extends Component {
  render() {
    let percent = this.props.total
      ? (this.props.completed / this.props.total) * 100
      : 0;
    return (
      <>
        <span className="theme-img-icon">
          <img
            className="theme-img"
            alt="topic-icon"
            src={
              backend +
              (this.props.enabled
                ? this.props.topic.icon
                : this.props.topic.iconBw)
            }
          />
        </span>
        <span className="theme-card-text">{this.props.topic.name}</span>

        {!this.props.enabled ? (
          <span />
        ) : this.props.complete ? (
          <span className="theme-right-icon">
            <img className="theme-done" alt="done" src={done_ic} />
          </span>
        ) : (
          <div className="theme-progress-div">
            <span style={{ float: 'right' }}>
              {' '}
              {this.props.completed}/{this.props.total}{' '}
            </span>
            <div className="progress theme-progress-bar">
              <div
                className="progress-bar bg-warning"
                role="progressbar"
                style={{ width: percent }}
                aria-valuenow={percent}
                aria-valuemin="0"
                aria-valuemax="100"
              ></div>
            </div>
          </div>
        )}
      </>
    );
  }
}

export default ThemesHeader;
