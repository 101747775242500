import React, { Component } from 'react';

class Type5 extends Component {
  render() {
    const subcards = [];
    const exercise = this.props.exercise;

    exercise.type5_translation.forEach((param, index) => {
      subcards.push(
        <label className="type5_label" key={index}>
          <input
            type="radio"
            name="type5_option"
            className="type5_option"
            onClick={() => this.props.selectedValue(5, index)}
          />
          <div className="ex-subcard-type5">
            <p className="type5_translation"> {param} </p>
          </div>
        </label>
      );
    });

    return (
      <div className="exercise-card-type4" align="center">
        {subcards}
      </div>
    );
  }
}

export default Type5;
