import * as types from '../redux-actions';

export default function(state = [], action) {
  const response = action.response;

  switch (action.type) {
    case types.GET_SUBSCRIPTION_SUCCESS:
      return { ...state, response };
    case types.GET_SUBSCRIPTION_ERROR:
      return { ...state, response };
    case types.CANCEL_SUBSCRIPTION_SUCCESS:
      return { ...state, response };
    case types.CANCEL_SUBSCRIPTION_ERROR:
      return { ...state, response };
    case types.LOGOUT_USER:
      return { ...undefined, undefined };
    default:
      return state;
  }
}
