import React, { Component } from 'react';
import { backend } from '../../../../config';
import PlayBtn from '../PlayBtn';
import separator_ic from '../../../../images/separator_ic.png';
import ExerciseLogic from '../ExerciseLogic';

class Type2 extends Component {
  render() {
    const exercise = this.props.exercise;
    return (
      <div className="exercise-card">
        <div className="exercise-subcard" align="center">
          <img alt="exercise" src={backend + exercise.type2_image} />
        </div>

        <div
          className="exercise-subcard-label"
          align="center"
          onLoad={() => this.props.selectedValue(2, exercise.type2_isCorrect)}
        >
          <p> {exercise.type2_translation} </p>
          <img
            className="ex-2-separator"
            src={separator_ic}
            alt="arrow-right"
          />
          <p> {exercise.type2_word}</p>
        </div>

        <PlayBtn
          audio_file={ExerciseLogic.getAudioUrl(
            this.props.language,
            exercise.type2_word
          )}
        />
      </div>
    );
  }
}

export default Type2;
