import { put, call } from 'redux-saga/effects';

import * as types from '../redux-actions';
import { setLanguage as i18n_setLanguage } from '../translation/translation';
import {
  subscriptionService,
  cancelSubscriptionService
} from '../services/dataService';

export function* adSaga(show) {
  yield put({ type: types.AD_SHOW_SUCCESS, show });
}

export function* setLanguage(language) {
  i18n_setLanguage(language.language);
  yield put({ type: types.SET_LANGUAGE_SUCCESS, language });
}

export function* subscriptionSaga(payload) {
  const response = yield call(subscriptionService, payload.subscriptionId);
  yield put({ type: types.GET_SUBSCRIPTION_SUCCESS, response });
}

export function* cancelSubscriptionSaga(payload) {
  const response = yield call(
    cancelSubscriptionService,
    payload.subscriptionId
  );
  yield put({ type: types.CANCEL_SUBSCRIPTION_SUCCESS, response });
}

export function* refreshDataSaga() {
  const response = true;
  yield put({ type: types.REFRESH_DATA_SUCCESS, response });
}
