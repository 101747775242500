import React, { Component } from 'react';
import { connect } from 'react-redux';
import { resetPasswordAction } from '../../redux-actions/authActions';
import Header from '../common/Header';
import Footer from '../common/Footer';

import { InputGroup, Button, FormControl } from 'react-bootstrap';
import i18n from '../../translation/translation';

class PasswordRecovery extends Component {
  onHandleReset = event => {
    event.preventDefault();

    let email = event.target.email.value;

    const data = {
      email
    };

    this.props.dispatch(resetPasswordAction(data));
  };

  componentDidMount() {
    document.title = i18n('restore-password');
  }

  render() {
    var isSuccess = false;
    var hasResponse = false;
    var message;

    if (this.props.response.reset.hasOwnProperty('response')) {
      hasResponse = true;
      var response = this.props.response.reset.response;
      if (response) {
        isSuccess = response && response.status === 204;
        message = isSuccess
          ? i18n('restore-link-sent')
          : i18n('such-user-not-found');
      } else {
        message = i18n('server-not-responding');
      }
    }

    return (
      <>
        <Header {...this.props} />
        <div className="PageContent">
          <div className="LogContainer">
            {/* className="LogContainer" md={{ span: 4, offset: 4 }}> */}
            <h3 align="center" className="PassChangeLogHeaderTitle">
              {i18n('restore-password')}
            </h3>

            <form className="Inputs" onSubmit={this.onHandleReset}>
              <div align="center">
                <InputGroup className="LogInputField">
                  <FormControl
                    id="email"
                    name="email"
                    type="email"
                    aria-label={i18n('enter-your-email')}
                    aria-describedby="basic-addon2"
                    placeholder={i18n('enter-your-email')}
                    className="LogInputControl"
                  />
                </InputGroup>
              </div>
              <div align="center">
                <Button
                  // bsStyle=""
                  style={{ backgroundColor: '#683da1' }}
                  // bsClass="btn"
                  type="submit"
                  className="PasswordChangeButton"
                >
                  {i18n('restore-cap')}
                </Button>
              </div>
            </form>
            <div>
              {hasResponse ? (
                <p
                  align="center"
                  className="LinkRestoreText"
                  style={{ color: '#EB1F7F' }}
                >
                  {message}
                </p>
              ) : (
                <p></p>
              )}
            </div>
          </div>
        </div>
        <Footer />
      </>
    );
  }
}

const mapStateToProps = response => ({
  response
});

export default connect(mapStateToProps)(PasswordRecovery);
