import React, { useState } from 'react';
import '../dashboard.css';
import Exit from './Exit';
import i18n from '../../../translation/translation';

// class UserSettings extends Component {
const UserSettings = props => {
  // render() {
  const [modalShow, setModalShow] = useState(false);

  return (
    <>
      <div className="right-info-top">
        <div className="vertical-menu">
          <span
            className={props.page === 'general' ? 'active' : ''}
            onClick={() => props.viewHandler('general')}
          >
            {i18n('general-info')}
          </span>
          <span
            className={props.page === 'photo' ? 'active' : ''}
            onClick={() => props.viewHandler('photo')}
          >
            {i18n('profile-photo')}
          </span>
          <span
            className={props.page === 'subscription' ? 'active' : ''}
            onClick={() => props.viewHandler('subscription')}
          >
            {i18n('subscription')}
          </span>
          {/* <span
            className={props.page === 'sounds' ? 'active' : ''}
            onClick={() => props.viewHandler('sounds')}
          >
            {' '}
            Звуки и голос{' '}
          </span> */}
          <span
            className={props.page === 'reminders' ? 'active' : ''}
            onClick={() => props.viewHandler('reminders')}
          >
            {i18n('reminders')}
          </span>
          <span
            className={props.page === 'exit' ? 'active' : ''}
            // data-toggle="modal" data-target="#exampleModalCenter"
            // onClick={() => this.props.viewHandler('exit')}
            onClick={() => setModalShow(true)}
          >
            {i18n('exit')}
          </span>
        </div>
      </div>

      <Exit show={modalShow} onHide={() => setModalShow(false)} />
    </>
  );
};
// }

export default UserSettings;
