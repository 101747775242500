import React, { Component } from 'react';
// import back_ic from '../../../images/back_ic.svg';
import '../dashboard.css';
import StatusProgress from './StatusProgress';

import tree_01 from '../../../images/tree_01.svg';
import tree_02 from '../../../images/tree_02.svg';
import tree_03 from '../../../images/tree_03.svg';
import tree_04 from '../../../images/tree_04.svg';
import tree_05 from '../../../images/tree_05.svg';
import road from '../../../images/road.svg';
import { backend } from '../../../config';
import BackBtn from '../BackBtn';
import i18n from '../../../translation/translation';

class StatusList extends Component {
  statusList(param, userPoints) {
    const statusDiv = [];
    let topPos = 99;

    param.forEach(function(element, index) {
      let passed = element.points <= userPoints;
      statusDiv.push(
        <div
          key={'status-item-' + index}
          className="status-img-div"
          style={{
            top: topPos + 'px',
            left: index % 2 === 0 ? '21px' : '9px'
          }}
        >
          <div
            style={{
              textAlign: index % 2 === 0 ? 'left' : 'right',
              marginLeft: index % 2 === 0 ? '0px' : '-175px',
              width: '470px'
            }}
          >
            <img
              alt="status"
              style={{
                float: index % 2 === 0 ? 'left' : 'right',
                marginLeft: index % 2 === 0 ? '0px' : '20px',
                marginRight: index % 2 === 0 ? '20px' : '0px',
                height: '50px',
                width: '50px'
              }}
              src={backend + (passed ? element.icon : element.iconBw)}
            />
            <span className="status-title">
              <strong> {element.name} </strong>
            </span>
            <span className={'status-point' + (passed ? ' passed' : '')}>
              {' '}
              {element.points}{' '}
            </span>
            <span className="status-point-lbl"> {i18n('points')} </span>
          </div>
        </div>
      );

      topPos += 85;
    });
    return statusDiv;
  }

  leftTrees() {
    const leftTree = [];

    [tree_05, tree_03].forEach(function(tree) {
      let splt = tree.split('_');
      leftTree.push(
        <img
          alt={tree}
          key={tree}
          className={'tree_' + splt[1].slice(0, 2)}
          src={tree}
        />
      );
    });

    return leftTree;
  }

  rightTrees() {
    const rightTree = [];

    [tree_04, tree_02, tree_01].forEach(tree => {
      let splt = tree.split('_');
      rightTree.push(
        <img
          alt={tree}
          key={tree}
          className={'tree_' + splt[1].slice(0, 2)}
          src={tree}
        />
      );
    });

    return rightTree;
  }

  render() {
    let items = this.props.status || [];
    let userPoints = this.props.data.statistics.points;
    var passed = -1;
    // eslint-disable-next-line
    for (const element of items) {
      if (element.points <= userPoints) passed++;
    }

    let progress = passed * (100 / 14);

    return (
      <div className="card" style={{ height: 1648 }}>
        <div className="status-header">
          {/* <img
            style={{ opacity: '.5' }}
            src={back_ic}
            alt="back"
            onClick={() => this.props.viewHandler('default', 'userstatus')}
          /> */}
          <BackBtn {...this.props} />
          <span> {i18n('your-status')} </span>
        </div>
        <div className="collapse show">
          <div className="card-body status-body">
            <div className="status-list-left" align="left">
              {this.leftTrees()}
              {this.rightTrees()}
              {this.leftTrees()}
            </div>
            <div className="status-list-center" align="center">
              <div>
                <img className="road-img" alt="road-progress" src={road} />
                <StatusProgress progress={progress} />
                <div id="progress-top-1"></div>
                <div id="progress-top-2"></div>
                <div id="progress-bottom"></div>
              </div>

              {this.statusList(
                this.props.status,
                this.props.data.statistics.points
              )}
            </div>
            <div className="status-list-right">
              {this.rightTrees()}
              {this.leftTrees()}
              {this.rightTrees()}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default StatusList;
