import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';

import {
  loadCombinedAction,
  saveSettingsAction
} from '../../redux-actions/dataActions';
import { subscriptionAction } from '../../redux-actions/miscActions';

import dataHelpers from '../../utils/dataHelpers';
import Header from '../common/Header';
import Footer from '../common/Footer';
import DashboardRightPanel from './DashboardRightPanel';
import DashboardLeftPanel from './DashboardLeftPanel';
import Loader from '../common/Loader';
import { getCookie } from '../../utils/cookies';

import './dashboard.css';
import { AppContext } from '../main/AppContext';

class DashboardLayout extends Component {
  hasData = false;
  hasUser = false;
  hasStatus = false;

  constructor(props) {
    super(props);
    if (props.location.state !== undefined) {
      this.state = {
        leftPanel: props.location.state.leftPanel,
        rightPanel: props.location.state.rightPanel,
        topicLink: ''
      };
    } else {
      this.state = {
        leftPanel: 'dashboard',
        rightPanel: 'userstatus',
        topicLink: ''
      };
    }
  }

  componentDidMount() {
    document.title = 'Cloodee';
  }

  updateToken = () => {
    let pushToken = localStorage.getItem('push-token');
    if (pushToken) {
      let now = new Date().getTime();
      let pushTokenSent = Number(
        localStorage.getItem('push-token-sent') || '0'
      );
      let INTERVAL = 1 * 60 * 1000;
      if (now - pushTokenSent > INTERVAL) {
        localStorage.setItem('push-token-sent', now);
        this.sendToken(pushToken);
      }
    }
  };

  sendToken = token => {
    var response = this.props.response.data.response;
    var settings = response.user.settings;
    var offset = new Date().getTimezoneOffset();
    settings.pushToken = token;
    settings.uiLanguage = dataHelpers.getUiLanguage();
    settings.zoneOffset = offset;
    this.props.dispatch(saveSettingsAction(settings));
  };

  handleDashboardView = (left, right) => {
    if (left === 'default' && right === 'userstatus') {
      let user = dataHelpers.getUser();
      this.props.dispatch(loadCombinedAction(user.id));
    }
    this.setState({
      leftPanel: left,
      rightPanel: right
    });
  };

  handleTopicLink = rhtml => {
    this.setState({
      topicLink: rhtml
    });
  };

  render() {
    let accessToken = getCookie('accessToken');
    if (!accessToken) {
      return <Redirect to="/login" />;
    }

    if (!this.props.response.data.hasOwnProperty('response')) {
      let user = dataHelpers.getUser();
      if (user) {
        this.props.dispatch(loadCombinedAction(user.id));
        return <Loader isLogged={accessToken != null} />;
      } else {
        return <Redirect to="/login" />;
      }
    } else {
      var dataResponse = this.props.response.data.response;

      if (!dataResponse || dataResponse.user.error) {
        return <Redirect to="/login" />;
      }
      // TODO: handle error

      localStorage.setItem('user', JSON.stringify(dataResponse.user));

      let subcription = dataResponse.user.subscriptionId;
      if (
        subcription &&
        !this.props.response.subscription.hasOwnProperty('response')
      ) {
        this.props.dispatch(
          subscriptionAction(dataResponse.user.subscriptionId)
        );
        return <Loader isLogged={true} />;
      }
    }

    var response = this.props.response.data.response;

    let userData = response.user;
    let languages = response.languages;
    let statuses = response.status;
    let userStatus = dataHelpers.getUserStatus(userData, statuses);

    let last = dataHelpers.getSelectedProgress(userData);
    if (!last) {
      return <Redirect to="/select-course" />;
    }

    let packge = dataHelpers.getPackageById(languages, last.packageId);
    let program = dataHelpers.getProgramById(languages, last.programId);
    let topic = dataHelpers.getTopicById(languages, last.topicId);

    if (!packge) {
      return <Redirect to="/select-course" />;
    } else if (!program) {
      return <Redirect to="/select-program" />;
    } else if (!topic) {
      if (program.topics.length > 0) {
        topic = program.topics[0];
      }
    }

    localStorage.setItem(
      'course-lang',
      program.languageCode.substring(0, 2).toLowerCase()
    );

    this.updateToken();

    return (
      <>
        <Header
          {...this.props}
          data={userData}
          package={packge}
          program={program}
          isLogged={true}
          topAligned={true}
          // rightLayoutHandler={this.handleRightLayoutView.bind(this)}
          viewHandler={this.handleDashboardView.bind(this)}
        />
        <div className="panel-layout">
          <DashboardLeftPanel
            {...this.props}
            data={userData}
            status={statuses}
            package={packge}
            program={program}
            topic={topic}
            topicLink={this.state.topicLink}
            page={this.state.leftPanel}
            viewHandler={this.handleDashboardView.bind(this)}
            handleTopicLink={this.handleTopicLink.bind(this)}
          />
          <DashboardRightPanel
            {...this.props}
            data={userData}
            status={userStatus}
            page={this.state.leftPanel}
            rightPanel={this.state.rightPanel}
            viewHandler={this.handleDashboardView.bind(this)}
          />
        </div>
        <Footer
          data={userData}
          package={packge}
          page={this.state.leftPanel}
          viewHandler={this.handleDashboardView.bind(this)}
        />

        <div className="lesson-preload-images"></div>

        {/* <FakeModalAd {...this.props} /> */}
      </>
    );
  }
}

DashboardLayout.contextType = AppContext;

const mapStateToProps = response => ({ response });

export default connect(mapStateToProps)(DashboardLayout);
