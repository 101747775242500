import React, { Component } from 'react';
import failed_ic from '../../../images/failed_ic.svg';
import i18n from '../../../translation/translation';
import './completion.css';
import Sound from 'react-sound';
import { backendAudio } from '../../../config';

class UnsuccessfulCompletion extends Component {
  onContinue() {
    this.props.history.push('/dashboard');
  }

  render() {
    return (
      <div className="usc-container">
        <div className="end-lesson-div" align="center">
          <img className="usc-img" src={failed_ic} alt="failed_ic" />
          <label className="sc-lbl"> {i18n('sorry')} </label>
          <label className="usc-msg">{i18n('course-part')}</label>
          <button className="sc-btn" onClick={this.onContinue.bind(this)}>
            {i18n('continue')}
          </button>{' '}
        </div>
        <Sound
          url={backendAudio + 'failed.mp3'}
          playStatus={Sound.status.PLAYING}
        />
      </div>
    );
  }
}
export default UnsuccessfulCompletion;
