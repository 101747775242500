import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { AppContext } from '../main/AppContext';

import {
  loadCombinedAction,
  saveSettingsAction
} from '../../redux-actions/dataActions';
import dataHelpers from '../../utils/dataHelpers';

import Header from '../common/Header';
import Footer from '../common/Footer';
import Loader from '../common/Loader';
import RegisterStepHeader from './RegisterStepHeader';
import RegisterFourthStep from './RegisterFourthStep';
import i18n from '../../translation/translation';

class SelectReminders extends Component {
  hasData = false;
  hasUser = false;

  rearm = false;

  state = {};

  handleSaveEvent = params => {
    var response = this.props.response.data.response;
    var settings = response.user.settings;
    settings.motivationNotification = params.switchReminder;
    settings.notificationDays = params.days;

    var p = params.inputTime.split(':');
    var m = 0;
    var h = 1;

    while (params.inputTime.length > 0 && p.length > 0) {
      m += h * parseInt(p.pop(), 10);
      h *= 60;
    }

    settings.notificationTime = m;

    this.rearm = true;
    this.props.dispatch(saveSettingsAction(settings));
  };

  handleDashboardView = (left, right) => {
    this.setState({
      leftPanel: left,
      rightPanel: right,
      redirectDashboard: true
    });
  };

  render() {
    if (this.state.redirectDashboard) {
      return (
        <Redirect
          to={{
            pathname: '/',
            state: {
              leftPanel: this.state.leftPanel,
              rightPanel: this.state.rightPanel
            }
          }}
        />
      );
    }
    if (this.props.response.settings.hasOwnProperty('response') && this.rearm) {
      //TODO: update settings in user data
      //this.context.user.settings = this.props.response.settings.response;
      return <Redirect to="/" />;
    }

    if (!this.props.response.data.hasOwnProperty('response')) {
      let user = JSON.parse(localStorage.getItem('user'));
      if (user) {
        this.props.dispatch(loadCombinedAction(user.id));
        return <Loader />;
      } else {
        return <Redirect to="/login" />;
      }
    } else {
      var response = this.props.response.data.response;

      localStorage.setItem('user', JSON.stringify(response.user));

      let user = response.user;
      let languages = response.languages;

      let last = dataHelpers.getSelectedProgress(user);
      var packge, program;
      if (last) {
        packge = dataHelpers.getPackageById(languages, last.packageId);
        program = dataHelpers.getProgramById(languages, last.programId);
        if (!program) last = null;
      }

      return (
        <>
          <Header
            {...this.props}
            data={user}
            package={packge}
            program={program}
            isLogged={last != null}
            viewHandler={this.handleDashboardView.bind(this)}
          />
          <RegisterStepHeader title={i18n('reminder-settings')} />
          <RegisterFourthStep
            {...this.state}
            handleData={this.handleSaveEvent.bind(this)}
          />
          <Footer />
        </>
      );
    }
  }
}

SelectReminders.contextType = AppContext;

const mapStateToProps = response => ({ response });

export default connect(mapStateToProps)(SelectReminders);
