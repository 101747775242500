import React, { Component } from 'react';
import './exercise.css';
import { Modal } from 'react-bootstrap';
import close_ic from '../../../images/close_ic.svg';
import i18n from '../../../translation/translation';
import ReactHtmlParser from 'react-html-parser';

class ExerciseRule extends Component {
  render() {
    return (
      <Modal
        show={this.props.show}
        aria-labelledby="contained-modal-title-vcenter"
        className="modal-exercise"
        centered
      >
        <Modal.Header className="modal-header-exercise">
          <Modal.Title
            className="modal-title-exercise"
            id="contained-modal-title-vcenter"
          >
            <div className="modal-header-content">
              <img
                src={close_ic}
                className="ex-modal-close"
                alt="close"
                onClick={this.props.onHide}
              />
              <label> {i18n('topic-rules')} </label>
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="modal-body-exercise no-scroll">
          <div className="exercise-rules-wrapper">
            <div className="exercise-rules-wrapper-inner">
              {ReactHtmlParser(this.props.info)}
            </div>
          </div>
        </Modal.Body>
      </Modal>
    );
  }
}

export default ExerciseRule;
