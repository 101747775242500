import React, { useState } from 'react';
import './exercise.css';
import i18n from '../../../translation/translation';
import ExerciseBtn from './ExerciseBtn';
import correct_ic from '../../../images/correct_ic.svg';
import incorrect_ic from '../../../images/incorrect_ic.svg';
import ExerciseSkip from './ExerciseSkip';
import ExerciseReport from './ExerciseReport';
import ExerciseReportConfirm from './ExerciseReportConfirm';
import Sound from 'react-sound';
import { backendAudio, backendUrl } from '../../../config';
import dataHelpers from '../../../utils/dataHelpers';

const ExerciseFooter = props => {
  const [modalShow, setModalShow] = useState(false);
  const [modalConfirm, setModalConfirm] = useState(false);
  const [skipConfirm, setSkipConfirm] = useState(false);
  const [playState, setPlayState] = useState(Sound.status.PLAYING);

  const onFinishPlay = () => {
    setPlayState(Sound.status.STOPPED);
  };

  const confirmModal = params => {
    setModalShow(false);
    let exercise = props.exercises[props.data];
    let lesson = dataHelpers.getLessonById(props.languages, exercise.lessonId);
    let topic = dataHelpers.getTopicById(props.languages, lesson.topicId);
    let program = dataHelpers.getProgramById(props.languages, topic.programId);
    let packge = dataHelpers.getPackageById(props.languages, program.packageId);
    var problems = '';

    if (params.problem_one) {
      problems +=
        ' Проблема с ответом. Я знаю, что указываю правильный вариант, но мне говорят это не так.;';
    }
    if (params.problem_two) {
      problems +=
        ' Проблема с заданием. Я не вижу задание или какую то его часть.;';
    }
    if (params.problem_three) {
      problems +=
        ' Проблема с текстом. Тексты в этом упражнении имеют грамматические ошибки или проблемы с написанием.;';
    }
    if (params.problem_four) {
      problems += ' Другое. Проверьте это упражнение, с ним есть проблема.;';
    }

    let text =
      packge.name +
      ' -> ' +
      program.name +
      ' -> ' +
      topic.name +
      ' -> ' +
      lesson.name +
      ' -> ' +
      'N' +
      props.data +
      ' - ' +
      problems;

    (async () => {
      let payload = {
        text
      };
      try {
        var res = await fetch(backendUrl + 'feedback', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(payload)
        });
        var json = await res.json();
        console.log(json);
        if (json.id) {
          setModalConfirm(true);
        }
      } catch (err) {}
    })();
  };

  const getType3Answer = exercise => {
    for (const [i, correct] of exercise.type3_isCorrect.entries()) {
      if (correct) return exercise.type3_translation[i];
    }
    return '';
  };

  const answerKeys = () => {
    let exercise = props.exercises[props.data];
    let type = exercise.type;

    switch (type) {
      case 1:
        return exercise.type1_word;
      case 2:
        return; // exercise.type2_word;
      case 3:
        return getType3Answer(exercise);
      case 4:
        return exercise.type4_word;
      case 5:
        return exercise.type5_translation[
          exercise.type5_isCorrect.indexOf(true)
        ];
      case 6:
        return exercise.type6_word;
      case 7:
        return;
      case 8:
        return exercise.type8_answer[exercise.type8_isCorrect.indexOf(true)];
      case 9:
        return exercise.type9_in_correct_order;
      case 10:
        return exercise.type10_sentence;
      case 11:
        return exercise.type11_correct_answers;
      case 12:
        return exercise.type12_translation[
          exercise.type12_isCorrect.indexOf(true)
        ];
      case 13:
        return exercise.type13_word_or_phrase;
      case 14:
        return exercise.type14_sentence;
      case 15:
        return;
      default:
        return;
    }
  };

  const footerDisplay = () => {
    let exercise = props.exercises[props.data];
    let type = exercise.type;
    let points_added = props.isBlitz
      ? ''
      : '+1 ' + dataHelpers.num2str18n(1, 'points-case');
    let cannotTalkTime = Number(localStorage.getItem('cannot-talk') || '0');
    let cannotTalk =
      type === 14 && new Date().getTime() - cannotTalkTime < 3600000;

    if (props.footerView === 'cannot-talk' || cannotTalk) {
      return (
        <div className="exercise-footer footer-correct">
          <div className="exercise-footer-content">
            {/* for microphone-off area */}
            <div className="footer-correct-message">
              <p> {i18n('microphone-off')} </p>
              <p> {i18n('on-in-a-hour')} </p>
            </div>

            {/* button area for continue and report */}
            <div className="footer-btn-div" align="center">
              <button
                className="btn btn-default continue-btn c-btn"
                onClick={() => {
                  props.resetFooterView(props.exercises);
                  localStorage.setItem('cannot-talk', new Date().getTime());
                  setPlayState(Sound.status.PLAYING);
                }}
              >
                {i18n('continue')}
              </button>
            </div>
          </div>
          <Sound
            url={backendAudio + 'correct.mp3'}
            playStatus={playState}
            onFinishedPlaying={onFinishPlay}
          />
        </div>
      );
    } else if (props.footerView === 'correct') {
      return (
        <div className="exercise-footer footer-correct">
          <div className="exercise-footer-content">
            <img className="correct-ic" src={correct_ic} alt="correct_ic" />
            <p className="correct-point"> {points_added} </p>

            {/* for correct message area */}
            <div className="footer-correct-message">
              <p> {i18n('correct-message')} </p>
              <p> {answerKeys()} </p>
            </div>

            {/* button area for continue and report */}
            <div className="footer-btn-div" align="center">
              <button
                className="btn btn-default continue-btn c-btn"
                onClick={() => {
                  setPlayState(Sound.status.PLAYING);
                  props.resetFooterView(props.exercises);
                  //props.handleProgress();
                }}
              >
                {i18n('continue')}
              </button>
            </div>
          </div>
          <Sound
            url={backendAudio + 'correct.mp3'}
            playStatus={playState}
            onFinishedPlaying={onFinishPlay}
          />
        </div>
      );
    } else if (props.footerView === 'incorrect') {
      return (
        <>
          <div className="exercise-footer footer-incorrect">
            <div className="exercise-footer-content">
              <img
                className="correct-ic"
                src={incorrect_ic}
                alt="incorrect_ic"
              />

              {!props.allowableMistakes ? (
                ''
              ) : (
                <p className="incorrect-point">
                  {props.isBlitz
                    ? i18n('remain-errors') +
                      ` ` +
                      props.allowableMistakes +
                      ` ` +
                      dataHelpers.num2str18n(
                        props.allowableMistakes,
                        'errors-case'
                      )
                    : ''}
                </p>
              )}

              {/* for incorrect message area */}
              <div className="footer-incorrect-message">
                {type === 2 || type === 4 || type === 15 ? (
                  <p>{i18n('incorrect-message')}</p>
                ) : (
                  <>
                    <p> {i18n('is-correct-label')} </p>
                    <p> {answerKeys()} </p>
                  </>
                )}
              </div>

              {/* button area for continue and report */}
              <div className="footer-btn-div" align="center">
                <button
                  className="btn btn-default continue-btn inc-btn"
                  onClick={() => {
                    props.resetFooterView(props.exercises);
                    setPlayState(Sound.status.PLAYING);
                  }}
                >
                  {i18n('continue')}
                </button>
                <p onClick={() => setModalShow(true)}>
                  {' '}
                  {i18n('report-an-error').toUpperCase()}{' '}
                </p>
              </div>
            </div>
          </div>
          <Sound
            url={backendAudio + `incorrect.mp3`}
            playStatus={playState}
            onFinishedPlaying={onFinishPlay}
          />
        </>
      );
    } else {
      return (
        <div className="exercise-footer">
          <div className="exercise-footer-content">
            {/* for skip area */}
            <p
              onClick={() => {
                setSkipConfirm(true);
                //props.handleSkipData();
              }}
            >
              {i18n('skip')}
            </p>
            {/* button area for different types */}
            {buttonDisplay(props.data)}
          </div>
        </div>
      );
    }
  };

  const buttonDisplay = param => {
    let type = props.exercises[param].type;

    if (type === 1 || type === 7) {
      return <ExerciseBtn btnType={type} {...props} />;
    } else if (type === 2 || type === 15) {
      return (
        <div className="ex-btn-footer-div">
          <button
            className="btn btn-default ex-notproperly-btn"
            onClick={() => {
              props.handleAnswers(type, false);
            }}
          >
            {i18n('not-properly')}
          </button>
          <ExerciseBtn btnType={type} {...props} />
        </div>
      );
    } else {
      //for types 3,4,5,6,8,9,10,11,12,13,14
      return <ExerciseBtn btnType={type} {...props} />;
    }
  };

  return (
    <>
      {footerDisplay()}

      <ExerciseReport
        {...props}
        confirmModal={confirmModal}
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
      <ExerciseReportConfirm
        show={modalConfirm}
        onHide={() => setModalConfirm(false)}
      />
      <ExerciseSkip
        show={skipConfirm}
        onQuit={() => {
          props.handleSkipData();
          setSkipConfirm(false);
        }}
        onHide={() => setSkipConfirm(false)}
      />
    </>
  );
};

export default ExerciseFooter;
