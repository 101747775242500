import React, { Component } from 'react';
import '../common.css';
import i18n from '../../../translation/translation';

class LayoutHeader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      title: null
    };
  }

  render() {
    var title = 'N/A';

    switch (this.props.location) {
      case '/terms':
        title = i18n('terms-of-use');
        break;
      case '/privacy':
        title = i18n('privacy-terms');
        break;
      case '/contacts':
        title = i18n('contacts');
        break;
      default:
        break;
    }

    return <div className="info-header">{title}</div>;
  }
}

export default LayoutHeader;
