import React, { Component } from 'react';
import './exercise.css';
import { Modal, Button } from 'react-bootstrap';
import i18n from '../../../translation/translation';

class ExerciseExit extends Component {
  render() {
    return (
      <Modal
        show={this.props.show}
        aria-labelledby="contained-modal-title-vcenter"
        className="modal-exercise-exit"
        centered
      >
        <Modal.Header className="modal-header-exercise">
          <Modal.Title
            className="modal-title-exercise"
            id="contained-modal-title-vcenter"
          >
            <div className="modal-header-content">
              <label> {i18n('exercise-leave-confirmation')} </label>
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="modal-body-exercise mb-exit">
          <p> {i18n('exercise-leave-message')} </p>
          <span style={{ float: 'right', marginBottom: '0px' }}>
            <Button
              className="modal-btn-close"
              variant="default"
              onClick={this.props.onHide}
            >
              {i18n('cancel')}
            </Button>
            <Button
              className="modal-btn-exit"
              onClick={this.props.onQuit}
              variant="default"
            >
              {i18n('quit')}
            </Button>
          </span>
        </Modal.Body>
      </Modal>
    );
  }
}

export default ExerciseExit;
