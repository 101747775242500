import React, { Component } from 'react';
import { backend } from '../../../../config';
import PlayBtn from '../PlayBtn';
import separator_ic from '../../../../images/separator_ic.png';

class Type15 extends Component {
  render() {
    const exercise = this.props.exercise;

    return (
      <div className="exercise-card">
        <div className="exercise-subcard" align="center">
          <img alt="exercise" src={backend + exercise.type15_image} />
        </div>

        <div
          className="exercise-subcard-label"
          align="center"
          onLoad={() => this.props.selectedValue(15, exercise.type15_isCorrect)}
        >
          <p> {exercise.type15_translation} </p>
          <img
            className="ex-2-separator"
            src={separator_ic}
            alt="arrow-right"
          />
          <p> {exercise.type15_word}</p>
        </div>

        <PlayBtn audio_file="/audio/test.mp3" />
      </div>
    );
  }
}

export default Type15;
