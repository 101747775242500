import * as types from '../redux-actions';

export default function(state = [], action) {
  const response = action.response;

  switch (action.type) {
    case types.LOAD_DATA_SUCCESS:
      return { ...state, response };
    case types.LOAD_DATA_ERROR:
      return { ...state, response };
    case types.LOAD_USER_DATA_SUCCESS:
      return { ...state, response };
    case types.LOAD_USER_DATA_ERROR:
      return { ...state, response };
    case types.LOAD_COMBINED_DATA_SUCCESS:
      return { ...state, response };
    case types.LOAD_COMBINED_DATA_ERROR:
      return { ...state, response };
    case types.SAVE_STATISTICS_DATA_SUCCESS:
      const res_stat = Object.assign({}, state, {
        response: Object.assign({}, state.response, {
          user: response
        })
      });
      return res_stat;
    case types.SAVE_PROGRESS_DATA_SUCCESS:
      const res_progress = Object.assign({}, state, {
        response: Object.assign({}, state.response, {
          user: Object.assign({}, state.response.user, {
            learningProgress: response
          })
        })
      });
      return res_progress;
    case types.UPDATE_PROFILE_SUCCESS:
      const res_profile = Object.assign({}, state, {
        response: Object.assign({}, state.response, {
          user: Object.assign({}, state.response.user, {
            name: response.name
          })
        })
      });
      return res_profile;
    case types.LOGOUT_USER:
      return { ...undefined };
    case types.RESET_PROGRESS_DATA_SUCCESS:
      return { ...undefined };
    default:
      return state;
  }
}
