import React, { Component } from 'react';
import '../dashboard.css';

import UserRating from './UserRating';
class AllUserRating extends Component {
  render() {
    const alluserrating = [];
    // eslint-disable-next-line
    for (const [index, item] of this.props.leaderboard.entries()) {
      let user = item;
      user.statistics = { points: user.points };
      alluserrating.push(
        <UserRating
          key={item.id}
          {...this.props}
          user={user}
          rank={index + 1}
        />
      );
    }

    return <>{alluserrating}</>;
  }
}

export default AllUserRating;
