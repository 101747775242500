import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import ReactHtmlParser from 'react-html-parser';
import './main.css';

import Header from '../common/Header';
import Footer from '../common/Footer';
import MainFlag from './MainFlag';

import phone_ru from '../../images/main_phone_ru.svg';
import phone_en from '../../images/main_phone_en.svg';
import appstore_ic from '../../images/appstore_ic.png';
import googleplay_ic from '../../images/googleplay_ic.png';
import main_cap_ic from '../../images/main_cap_ic.png';
import main_clock_ic from '../../images/main_clock_ic.png';
import main_hello_ic from '../../images/main_hello_ic.png';
import main_user_ic from '../../images/main_user_ic.png';
import flagde from '../../images/flag-de.png';
import flages from '../../images/flag-es.png';
import flagfr from '../../images/flag-fr.png';
import flaguk from '../../images/flag-uk.png';
import i18n from '../../translation/translation';
import dataHelpers from '../../utils/dataHelpers';

class AppStoreButton extends Component {
  render() {
    return (
      <div className="store-button">
        <a href="https://apps.apple.com/us/app/id1478728915">
          <img className="appstore" src={appstore_ic} alt="appstore" />
        </a>
      </div>
    );
  }
}

class GooglePlayButton extends Component {
  render() {
    return (
      <div className="store-button">
        <a href="https://play.google.com/store/apps/details?id=ru.cloodee.app">
          <img className="googleplay" src={googleplay_ic} alt="googleplay" />
        </a>
      </div>
    );
  }
}

class Main extends Component {
  constructor(props) {
    super(props);

    this.state = {
      logged: false
    };
    this.nodes = {};
  }

  componentDidMount() {
    document.title = 'Cloodee';
  }

  render() {
    if (this.props.response.login.hasOwnProperty('response')) {
      var response = this.props.response.login.response;
      if (response && response.accessToken) {
        return (
          <Redirect
            to={{
              pathname: '/',
              state: {
                leftPanel: 'default',
                rightPanel: 'userstatus'
              }
            }}
          />
        );
      }
    }

    let lang = dataHelpers.getUiLanguage();
    let phone_img = lang === 'RU' ? phone_ru : phone_en;

    return (
      <>
        <Header {...this.props} topAligned={true} toMain={true} />
        <div className="part-one">
          <div className="center-column">
            <h3> {i18n('learn-language-anywhere')} </h3>
            <img src={phone_img} className="phone" alt="phone" />
            <p>{ReactHtmlParser(i18n('main-1'))}</p>
            <AppStoreButton />
            <GooglePlayButton />
          </div>
        </div>
        <div className="part-duex">
          <div className="center-column">
            <h3> {i18n('why-they-choose')} </h3>
            <div className="blocks">
              <div className="block block-1">
                <div className="circle">
                  <span className="helper"></span>
                  <img src={main_cap_ic} width={90} alt="cap" />
                </div>
                <h4> {i18n('professional-courses')} </h4>
                <p>{ReactHtmlParser(i18n('main-2'))}</p>
              </div>
              <div className="block block-3">
                <div className="circle">
                  <span className="helper"></span>
                  <img src={main_hello_ic} width={89} alt="hello" />
                </div>
                <h4> {i18n('learn-words')} </h4>
                <p>{ReactHtmlParser(i18n('main-3'))}</p>
              </div>
              <div className="block block-2">
                <div className="circle">
                  <span className="helper"></span>
                  <img
                    src={main_clock_ic}
                    width={71}
                    alt="clock"
                    style={{ paddingRight: '4px' }}
                  />
                </div>
                <h4> {i18n('fast-learning')} </h4>
                <p>{ReactHtmlParser(i18n('main-4'))}</p>
              </div>
            </div>
          </div>
          <div className="part-trois">
            <div className="center-column">
              <h3> {i18n('what-language')} </h3>
              <MainFlag flag={flaguk} language={i18n('main-english')} />
              <MainFlag flag={flagde} language={i18n('main-german')} />
              <MainFlag flag={flagfr} language={i18n('main-french')} />
              <MainFlag flag={flages} language={i18n('main-spain')} />
            </div>
          </div>
          <div className="part-quatre">
            <h3> {i18n('who-we-are')} </h3>

            <div className="left-column">
              <p>{i18n('main-5')}</p>
              <p>{i18n('main-6')}</p>
              <p>{i18n('main-7')}</p>
              <p>{i18n('main-8')}</p>
            </div>
            <div className="right-column">
              <div>
                <div className="vl-divider"></div>
                <div className="vl-text">{i18n('top-service')}</div>
              </div>
              <div>
                <div className="vl-divider"></div>
                <div className="vl-text">
                  {ReactHtmlParser(i18n('prepared-by'))}
                </div>
              </div>
              <div>
                <div className="vl-divider"></div>
                <div className="vl-text">{i18n('we-are-developing')}</div>
              </div>
            </div>
          </div>
          <div className="part-five">
            <div className="center-column">
              <img className="main-user-ic" src={main_user_ic} alt="test" />
              <label>{i18n('get-started')}</label>
              <Link to="/register">
                <button className="try-it"> {i18n('try')} </button>
              </Link>
            </div>
          </div>
          <Footer />
        </div>
      </>
    );
  }
}

const mapStateToProps = response => ({ response });

export default connect(mapStateToProps)(Main);
