import React from 'react';

const Notifications = props => {
  function formatDate(date) {
    if (!date) return '';

    // format the date
    // add leading zeroes to single-digit day/month/hours/minutes
    let d = date;
    d = [
      '0' + d.getDate(),
      '0' + (d.getMonth() + 1),
      '' + d.getFullYear(),
      '0' + d.getHours(),
      '0' + d.getMinutes()
    ].map(component => component.slice(-2)); // take last 2 digits of every component

    // join the components into date
    return d.slice(0, 3).join('.') + ' ' + d.slice(3).join(':');
  }

  let timestamp = formatDate(props.timestamp);

  return (
    <div className="notification-area-div">
      <label>{props.message}</label>
      <p> {timestamp} </p>
      <hr className="general-divider"></hr>
    </div>
  );
};

export default Notifications;
