import React, { Component } from 'react';
import '../dashboard.css';
import check_unchecked from '../../../images/check_unchecked.svg';
import check_checked from '../../../images/check_checked.svg';
import { Modal, Button } from 'react-bootstrap';
import i18n from '../../../translation/translation';

class ResetModal extends Component {
  constructor() {
    super();
    this.state = {
      problem_one: false,
      problem_two: false,
      problem_three: false,
      problem_four: false
    };
  }

  handleCheckbox = param => {
    switch (param) {
      case 1:
        this.setState({ problem_one: !this.state.problem_one });
        break;
      case 2:
        this.setState({ problem_two: !this.state.problem_two });
        break;
      case 3:
        this.setState({ problem_three: !this.state.problem_three });
        break;
      case 4:
        this.setState({ problem_four: !this.state.problem_four });
        break;
      default:
        return;
    }
  };

  render() {
    return (
      <>
        <Modal
          {...this.props}
          aria-labelledby="contained-modal-title-vcenter"
          className="report-modal"
          centered
        >
          <Modal.Header className="modal-header-reset">
            <Modal.Title
              className="modal-title-exit"
              id="contained-modal-title-vcenter"
            >
              {i18n('report-an-error')}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="modal-report">
            <div className="modal-report-label">
              <div
                className="report-cb-div"
                align="center"
                onClick={() => this.handleCheckbox(1)}
              >
                <img
                  src={this.state.problem_one ? check_checked : check_unchecked}
                  alt={`problem_one`}
                />
              </div>
              <div
                className="cb-label-div"
                onClick={() => this.handleCheckbox(1)}
              >
                <span onClick={() => this.handleCheckbox(1)}>
                  {i18n('issue-1')}
                </span>
              </div>
            </div>
            <div className="modal-report-label">
              <div
                className="report-cb-div"
                align="center"
                onClick={() => this.handleCheckbox(2)}
              >
                <img
                  src={this.state.problem_two ? check_checked : check_unchecked}
                  alt={`problem_two`}
                />
              </div>
              <div
                className="cb-label-div"
                onClick={() => this.handleCheckbox(2)}
              >
                <span onClick={() => this.handleCheckbox(2)}>
                  {i18n('issue-2')}
                </span>
              </div>
            </div>
            <div className="modal-report-label">
              <div
                className="report-cb-div"
                align="center"
                onClick={() => this.handleCheckbox(3)}
              >
                <img
                  src={
                    this.state.problem_three ? check_checked : check_unchecked
                  }
                  alt={`problem_three`}
                />
              </div>
              <div
                className="cb-label-div"
                onClick={() => this.handleCheckbox(3)}
              >
                <span onClick={() => this.handleCheckbox(3)}>
                  {i18n('issue-3')}
                </span>
              </div>
            </div>
            <div className="modal-report-label">
              <div
                className="report-cb-div"
                align="center"
                onClick={() => this.handleCheckbox(4)}
              >
                <img
                  src={
                    this.state.problem_four ? check_checked : check_unchecked
                  }
                  alt={`problem_four`}
                />
              </div>
              <div
                className="cb-label-div"
                onClick={() => this.handleCheckbox(4)}
              >
                <span onClick={() => this.handleCheckbox(4)}>
                  {i18n('issue-4')}
                </span>
              </div>
            </div>

            <span style={{ float: 'right', marginBottom: '0px' }}>
              <Button
                className="modal-btn-close"
                variant="default"
                onClick={this.props.onHide}
              >
                {i18n('cancel')}
              </Button>
              <Button
                className="modal-btn-exit"
                variant="default"
                onClick={() => this.props.confirmModal(this.state)}
              >
                {i18n('submit')}
              </Button>
            </span>
          </Modal.Body>
        </Modal>
      </>
    );
  }
}

export default ResetModal;
