import React, { Component } from 'react';
import '../dashboard.css';
import Avatar from '../../common/Avatar';
import dataHelpers from '../../../utils/dataHelpers';

class UserRating extends Component {
  render() {
    return (
      <div className="user-rating-card shadow-1">
        <span className="rating-rank"> {this.props.rank} </span>
        <span className="rating-avatar ml-2">
          <Avatar {...this.props} data={this.props.user} clickable={false} />
        </span>
        <span className="rating-username ml-3">{this.props.user.name}</span>
        <span className="rating-points">
          {' '}
          <span> {this.props.user.statistics.points} </span>
          {dataHelpers.num2str18n(
            this.props.user.statistics.points,
            'points-case'
          )}
        </span>
      </div>
    );
  }
}

export default UserRating;
