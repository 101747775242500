import md5 from 'md5';
import { backend } from '../../../config';
import {
  saveStatisticsAction,
  saveProgressAction
} from '../../../redux-actions/dataActions';
import dataHelpers from '../../../utils/dataHelpers';

class ExerciseLogic {
  /* 
    + 1 за каждый правильный ответ, 
    + 10 за каждую завершенную тему, 
    + 5 баллов за упражнение «Больше слов»,  
    + 25 баллов за прохождение «Сдать досрочно»,
    + 500 баллов за завершение программы обучения.
  */

  onCorrectAnswer() {
    this.increment('addPoints', 1);
    this.increment('rightAnswers', 1);
  }

  onIncorrectAnswer() {
    this.increment('errorCount', 1);
  }

  onAddWord() {
    this.increment('addWords', 1);
  }

  onMoreWords() {
    this.increment('addPoints', 5);
  }

  onFinishedTopic() {
    this.increment('addPoints', 10);
  }

  onFinishedBlitz() {
    this.increment('addPoints', 25);
  }

  onFinishedCourse() {
    this.increment('addPoints', 500);
  }

  increment(name, add) {
    let was = Number(localStorage.getItem(name) || '0');
    localStorage.setItem(name, was + add);
  }

  resetLessonPoints(currentPoints) {
    localStorage.setItem('startPoints', currentPoints);
    localStorage.setItem('addPoints', 0);
    localStorage.setItem('addWords', 0);
    localStorage.setItem('rightAnswers', 0);
    localStorage.setItem('errorCount', 0);
  }

  getNumber(name) {
    return Number(localStorage.getItem(name) || '0');
  }

  getWeekNumber(d) {
    // Copy date so don't modify original
    d = new Date(Date.UTC(d.getFullYear(), d.getMonth(), d.getDate()));
    // Set to nearest Thursday: current date + 4 - current day number
    // Make Sunday's day number 7
    d.setUTCDate(d.getUTCDate() + 4 - (d.getUTCDay() || 7));
    // Get first day of year
    var yearStart = new Date(Date.UTC(d.getUTCFullYear(), 0, 1));
    // Calculate full weeks to nearest Thursday
    var weekNo = Math.ceil(((d - yearStart) / 86400000 + 1) / 7);
    // Return array of year and week number
    return [d.getUTCFullYear(), weekNo];
  }

  getWeekActivity(stats) {
    let thisWeek = this.getWeekNumber(new Date())[1];
    let lastWeek = stats.timestamp
      ? this.getWeekNumber(new Date(stats.timestamp * 1000))[1]
      : 0;
    let isNewWeek = thisWeek > lastWeek;

    let emptyActivity = [false, false, false, false, false, false, false];
    let weekActivity = isNewWeek ? emptyActivity : stats.weekActivity;
    return weekActivity;
  }

  hasNewStatus(statuses) {
    let startPoints = this.getNumber('startPoints');
    let points = startPoints + this.getNumber('addPoints');
    let startStatus = dataHelpers.getStatusByPoints(startPoints, statuses);
    let currentStatus = dataHelpers.getStatusByPoints(points, statuses);
    return currentStatus.points > startStatus.points;
  }

  commitStatitics(props, user, lesson) {
    let startPoints = this.getNumber('startPoints');
    let points = startPoints + this.getNumber('addPoints');
    let words = this.getNumber('addWords');
    let rightAnswers = this.getNumber('rightAnswers');
    let errorCount = this.getNumber('errorCount');
    let lastUpdate = user.statistics.timestamp;
    let days = user.statistics.days === 0 ? 1 : this.calculateDays(lastUpdate);
    let daysContinuous =
      user.statistics.days === 0
        ? 1
        : this.calculateContinuousDays(
            lastUpdate,
            user.statistics.daysContinuous
          );
    let weekActivity = this.getWeekActivity(user.statistics);
    let dayOfWeek = (new Date().getDay() || 7) - 1;
    weekActivity[dayOfWeek] = true;
    let timestamp = Math.floor(new Date().getTime() / 1000);

    let statistics = {
      userId: user.id,
      points,
      words: user.statistics.words + words,
      rightAnswers,
      errorCount,
      days,
      daysContinuous,
      weekActivity,
      timestamp
    };
    console.log('Updating stats:');
    console.log(statistics);
    props.dispatch(saveStatisticsAction(statistics));

    if (lesson.type === 2) {
      return;
    }

    let languages = props.response.data.response.languages;
    let next = dataHelpers.getNextProgressItem(user, languages, lesson);
    let selected =
      user.learningProgress && user.learningProgress.selected
        ? user.learningProgress.selected
        : [];
    var found = false;
    for (let item of selected) {
      item.selected = false;
      if (!found && item.topicId === next.topicId) {
        found = true;
        item.selected = true;
        item.lessonId = next.lessonId;
        item.finished = next.finished;
      }
    }
    if (!found) {
      selected.push(next);
    }
    var progress = user.learningProgress;
    progress.selected = selected;
    props.dispatch(saveProgressAction(progress));
  }

  getEpochDay(date) {
    let epochDays =
      (date.getTime() - date.getTimezoneOffset() * 60000) / 8.64e7;
    return epochDays;
  }

  calculateDays(lastUpdate) {
    let last = Math.floor(this.getEpochDay(new Date(lastUpdate * 1000)));
    let today = Math.floor(this.getEpochDay(new Date()));
    return last === today ? 0 : 1;
  }

  calculateContinuousDays(lastUpdate, days) {
    let last = Math.floor(this.getEpochDay(new Date(lastUpdate * 1000)));
    let today = Math.floor(this.getEpochDay(new Date()));
    return last === today ? days : last + 1 === today ? days + 1 : 1;
  }

  getAudioUrl(lang, phrase) {
    const hash = md5(phrase.toLowerCase().trim());
    //console.log("Hash for '%s' is %s", phrase, hash);
    return backend + 'audio/' + lang + '/' + hash + '.mp3';
  }
}

const logic = new ExerciseLogic();

export default logic;
