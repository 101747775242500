import React, { Component } from 'react';
import { backend } from '../../../../config';
import avatar_ic from '../../../../images/avatar_ic.svg';

class Type4 extends Component {
  render() {
    const subcards = [];
    const exercise = this.props.exercise;

    exercise.type4_image.forEach((param, index) => {
      subcards.push(
        <label className="type4_label" key={index}>
          <input
            type="radio"
            name="option1"
            className="type4_option"
            onClick={() => this.props.selectedValue(4, index)}
          />
          <div className="ex-subcard-type4">
            <img
              src={backend + param}
              onError={e => (e.target.src = avatar_ic)}
              alt="type4-img"
            />
          </div>
        </label>
      );
    });

    return (
      <div className="exercise-card-type4" align="center">
        {subcards}
      </div>
    );
  }
}

export default Type4;
