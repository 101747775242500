import React from 'react';
import { connect } from 'react-redux';
import '../main/dot-pulse.css';

let LoaderItem = () => (
  <div className="d-flex justify-content-center Loader">
    <div className="dot-pulse"></div>
  </div>
);

const mapStateToProps = () => ({});
LoaderItem = connect(
  mapStateToProps,
  null
)(LoaderItem);
export default LoaderItem;
