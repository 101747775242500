import React from 'react';
import logo from '../../images/clodeelogo.png';

import GeneralHeader from './header/GeneralHeader';
import UserHeader from './header/UserHeader';

import { Navbar } from 'react-bootstrap';
import { Link } from 'react-router-dom';

class Header extends React.Component {
  render() {
    return (
      <header className={'header' + (this.props.topAligned ? '' : ' mb-auto')}>
        <Navbar>
          <div>
            <Navbar.Brand>
              <Link to={this.props.toMain ? '/' : '/dashboard'}>
                <img
                  className="ClodeeLogo"
                  alt="clodee"
                  src={logo}
                  onClick={
                    this.props.isLogged
                      ? () => this.props.viewHandler('default', 'userstatus')
                      : undefined
                  }
                />
              </Link>
            </Navbar.Brand>
          </div>

          {!this.props.isLogged ? (
            <GeneralHeader {...this.props} />
          ) : (
            <UserHeader {...this.props} />
          )}
        </Navbar>
      </header>
    );
  }
}

export default Header;
