import React, { Component } from 'react';

class SwitchSetting extends Component {
  render() {
    return (
      <>
        <div className={`switch-setting-` + this.props.class}>
          <label> {this.props.id} </label>

          <div className="custom-control custom-switch switch-setting">
            <input
              type="checkbox"
              className="custom-control-input"
              checked={this.props.checked}
              onChange={this.props.onChange}
              id={this.props.id}
            />
            <label
              className="custom-control-label"
              htmlFor={this.props.id}
            ></label>
          </div>
        </div>
        <br></br>
      </>
    );
  }
}

export default SwitchSetting;
