import React, { Component } from 'react';
import '../dashboard.css';
import ResetModal from './ResetModal';
import warning from '../../../../src/images/ic-warning.svg';
import i18n from '../../../translation/translation';
import { updateProfileAction } from '../../../redux-actions/authActions';
import { connect } from 'react-redux';
import dataHelpers from '../../../utils/dataHelpers';
import { getCookie } from '../../../utils/cookies';
import MessageModal from './MessageModal';

class GeneralInfo extends Component {
  constructor(props) {
    super(props);
    this.rearm = false;
    this.state = {
      modalShow: false,
      errorMessage: null
    };
  }

  onUpdateProfile = event => {
    event.preventDefault();

    let name = event.target.name.value.trim();
    let oldPassword = event.target.oldpassword.value.trim();
    let newPassword = event.target.ppassword.value.trim();
    let repeat = event.target.repeat.value.trim();

    if (newPassword !== repeat) {
      this.setState({
        errorMessage: i18n('error-password-match')
      });
      return false;
    }

    if (newPassword.length > 0 && oldPassword.length === 0) {
      this.setState({
        errorMessage: i18n('old-password-empty')
      });
      return false;
    }

    if (oldPassword.length > 0 && newPassword.length < 6) {
      this.setState({
        errorMessage: i18n('new-password-len')
      });
      return false;
    }

    if (oldPassword.length === 0 && name.length === 0) {
      return false;
    }

    const data = {
      id: dataHelpers.getUser().id,
      name,
      oldPassword,
      newPassword
    };

    this.rearm = true;
    let accessToken = getCookie('accessToken');
    this.props.dispatch(updateProfileAction(data, accessToken));
  };

  setModalShow = val => {
    this.setState({
      modalShow: val
    });
  };

  render() {
    var showMessage = false;

    if (this.rearm && this.props.response.profile.hasOwnProperty('response')) {
      this.rearm = false;
      showMessage = true;
    }

    return (
      <>
        <div className="card general-info">
          <div className="general-header">{i18n('general-information')}</div>

          <div className="general-info-body">
            <form onSubmit={this.onUpdateProfile.bind(this)} autoComplete="off">
              <div className="general-username">
                <label>{i18n('your-name')}</label>
                <input
                  className="general-input-box"
                  type="text"
                  name="name"
                  id="name"
                  autoComplete="new-password"
                  placeholder={this.props.data.name}
                />
              </div>
              <h5> {i18n('change-login-password')} </h5>
              <hr className="general-divider"></hr>
              <div className="general-pass">
                <label>{i18n('old-password')}</label>
                <input
                  type="password"
                  name="oldpassword"
                  id="oldpassword"
                  autoComplete="off"
                  className="general-input-box"
                />

                <label>{i18n('new-password')}</label>
                <input
                  type="password"
                  name="ppassword"
                  id="ppassword"
                  autoComplete="new-password"
                  className="general-input-box"
                />

                <label> {i18n('once-more')} </label>
                <input
                  type="password"
                  name="repeat"
                  id="repeat"
                  autoComplete="new-password"
                  className="general-input-box"
                />
              </div>
              <h5> {i18n('reset-progress')}</h5>
              <hr className="general-divider"></hr>
              <div className="reset-warning-div">
                <div className="warning-block">
                  <img src={warning} alt="warning"></img>
                </div>

                <div className="general-reset-div">
                  <span> {i18n('reset-warning-label')} </span>

                  <p className="reset-warning-u mt-3">
                    <u onClick={() => this.setModalShow(true)}>
                      {i18n('reset-warning')}
                    </u>
                  </p>
                  <button
                    className="btn btn-default general-reset-btn"
                    type="submit"
                  >
                    {i18n('save')}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>

        <ResetModal
          {...this.props}
          show={this.state.modalShow}
          onHide={() => this.setModalShow(false)}
        />

        <MessageModal
          {...this.props}
          title={i18n('save-title')}
          message={i18n('profile-saved')}
          show={showMessage}
          onHide={() => {
            this.setModalShow(false);
            this.setState({
              errorMessage: null
            });
          }}
        />

        <MessageModal
          {...this.props}
          title={i18n('error')}
          message={this.state.errorMessage}
          show={this.state.errorMessage !== null}
          onHide={() => {
            this.setState({
              errorMessage: null
            });
          }}
        />
      </>
    );
  }
}

const mapStateToProps = response => ({ response });

export default connect(mapStateToProps)(GeneralInfo);
