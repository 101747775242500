import React, { Component } from 'react';
import CourseSelection from './CourseSelection';

class RegisterFirstStep extends Component {
  render() {
    const items = [];

    for (const [index, value] of this.props.languages.entries()) {
      items.push(
        <CourseSelection
          {...this.props}
          key={value.id}
          index={index}
          lang={value}
        />
      );
    }

    return <div className="center-content">{items}</div>;
  }
}

export default RegisterFirstStep;
