import React, { Component } from 'react';
import '../dashboard.css';

import { Modal, Button } from 'react-bootstrap';

class MessageModal extends Component {
  render() {
    return (
      <>
        <Modal
          onHide={this.props.onHide}
          show={this.props.show}
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header className="modal-header-reset">
            <Modal.Title
              className="modal-title-exit"
              id="contained-modal-title-vcenter"
            >
              {this.props.title}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="modal-body-reset">
            <p>{this.props.message}</p>
            <span style={{ float: 'right', marginBottom: '0px' }}>
              <Button
                className="modal-btn-exit"
                variant="default"
                onClick={() => {
                  this.props.onHide();
                }}
              >
                OK
              </Button>
            </span>
          </Modal.Body>
        </Modal>
      </>
    );
  }
}

export default MessageModal;
