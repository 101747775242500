import * as types from './index';

export const showAdAction = show => {
  return {
    type: types.AD_SHOW,
    show
  };
};

export const setLanguageAction = language => {
  return {
    type: types.SET_LANGUAGE,
    language
  };
};

export const subscriptionAction = subscriptionId => {
  return {
    type: types.GET_SUBSCRIPTION,
    subscriptionId
  };
};

export const cancelSubscriptionAction = subscriptionId => {
  return {
    type: types.CANCEL_SUBSCRIPTION,
    subscriptionId
  };
};

export const refreshDataAction = () => {
  return {
    type: types.REFRESH_DATA
  };
};
