import React, { Component } from 'react';
import i18n from '../../translation/translation';
import soon from '../../images/soon.svg';

class Course extends Component {
  handleClick = e => {
    if (!this.props.course.soon) {
      this.props.onCourseClick(this.props.course);
    }
  };

  render() {
    return (
      <div
        className="card-body-course course-step-two"
        style={{
          display: this.props.course.soon ? 'none' : 'auto'
        }}
      >
        <div className="list-group">
          <input
            type="checkbox"
            className="CheckBoxInputName"
            readOnly
            checked={
              this.props.courseSelected === this.props.course.id ? true : false
            }
            id={`cb-` + this.props.course.id}
          />
          <label
            style={{
              cursor: this.props.course.soon ? 'default' : 'pointer',
              opacity: this.props.course.soon ? 0.7 : 1,
              backgroundColor: '#' + this.props.course.color,
              boxShadow: '0 25px 20px -20px #' + this.props.course.color
            }}
            className="list-group-item"
            onClick={this.handleClick}
            htmlFor={`cb-` + this.props.course.id}
          >
            {' '}
            {this.props.course.name}
          </label>
          <img
            style={{
              cursor: this.props.course.soon ? 'default' : 'pointer',
              opacity: this.props.course.soon ? 0.7 : 1
            }}
            className="course-flag-img clickable"
            alt="flag"
            src={this.props.img}
          ></img>
          {this.props.course.soon ? (
            <div className="soon">
              <img alt="soon" src={soon}></img>
              <span>{i18n('soon')}</span>
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>
    );
  }
}

export default Course;
