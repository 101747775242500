import React, { Component } from 'react';
import { connect } from 'react-redux';
import FakeModalAd from './FakeModalAd';
import { showAdAction } from '../../redux-actions/miscActions';

class TestAd extends Component {
  constructor(props) {
    super(props);
    this.props.dispatch(showAdAction(true));
  }

  render() {
    return (
      <>
        <div>test</div>
        <FakeModalAd {...this.props} />
      </>
    );
  }
}

const mapStateToProps = response => ({ response });

export default connect(mapStateToProps)(TestAd);
