import React, { Component } from 'react';
import '../common.css';

import Terms from './Terms';
import Privacy from './Privacy';
import Contacts from './Contacts';

import Header from './LayoutHeader';

class FooterLayout extends Component {
  switchBody(param) {
    switch (param) {
      case '/terms':
        return <Terms />;
      case '/contacts':
        return <Contacts />;
      default:
        return <Privacy />;
    }
  }

  render() {
    return (
      <>
        <div className="info-layout">
          <div className="info-accord">
            <div className="card">
              <Header {...this.props} location={this.props.location.pathname} />
              <div className="collapse show">
                <div className="info-body">
                  {this.switchBody(this.props.location.pathname)}
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default FooterLayout;
