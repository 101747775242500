import React from 'react';
import { backend } from '../../config';
import Img from 'react-image';
import avatar_ic from '../../images/avatar_ic.svg';

class Avatar extends React.Component {
  render() {
    return (
      <div
        className={
          'avatar-wrapper' +
          (this.props.clickable === false ? '' : ' clickable')
        }
        onClick={() =>
          this.props.clickable === false
            ? false
            : this.props.viewHandler('general', 'usersettings')
        }
      >
        <div className="avatar-circle"></div>
        <Img
          className="avatar"
          loadimg={avatar_ic}
          unloadimg={avatar_ic}
          src={[
            this.props.data.avatar,
            backend + this.props.data.avatar,
            avatar_ic
          ]}
          alt="avatar_ic"
        />
      </div>
    );
  }
}

export default Avatar;
