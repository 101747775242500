import React, { Component } from 'react';
import './dashboard.css';
import Training from './Training';
import RatingsList from '../dashboard/ratings/RatingsList';
import StatusList from '../dashboard/status/StatusList';
import { AppContext } from '../main/AppContext';
import GeneralInfo from './settings/GeneralInfo';
import ProfilePhoto from './settings/ProfilePhoto';
import SoundsAndVoice from './settings/SoundsAndVoice';
import Subscription from './settings/Subscription';
import Reminders from './settings/Reminders';
import Exit from './settings/Exit';
import Rules from './topic/Rules';
import NotificationList from './notification/NotificationList';
import i18n from '../../translation/translation';
import dataHelpers from '../../utils/dataHelpers';

class DashboardLeftPanel extends Component {
  constructor(props) {
    super(props);
    this.onBlitz = this.onBlitz.bind(this);
  }

  switchView = param => {
    switch (param) {
      case 'ratings':
        return <RatingsList {...this.props} />;
      case 'userstatus':
        return <StatusList {...this.props} />;
      case 'general':
        return <GeneralInfo {...this.props} />;
      case 'photo':
        return <ProfilePhoto {...this.props} />;
      case 'sounds':
        return <SoundsAndVoice />;
      case 'subscription':
        return <Subscription {...this.props} />;
      case 'reminders':
        return (
          <Reminders {...this.props} reminders={this.props.data.settings} />
        );
      case 'topic-rules':
        return <Rules {...this.props} />;
      case 'notification':
        return <NotificationList viewHandler={this.props.viewHandler} />;
      case 'exit':
        return <Exit />;
      default:
        return this.trainingThemes(
          this.props.program.topics || [],
          this.props.topic,
          this.props
          // this.handleTopicLink
        );
    }
  };

  onBlitz = (that, topic, topics) => {
    let blitzTopics = dataHelpers.getBlitzTopics(topic.id, topics);
    that.props.history.push('/exercise', {
      blitzTopics
    });
  };

  trainingThemes = (topics, current, props, topiclink) => {
    const items = [];

    let last = dataHelpers.getSelectedProgress(props.data);
    var haveProgress = last.finished || false;
    if (current) {
      for (const t of topics) {
        if (current.id === t.id) {
          haveProgress = true;
          break;
        }
      }
    }

    var learned = haveProgress;
    let that = this;
    var hasBlitz = false;
    topics.forEach(function(topic) {
      if (topic.type === 0) {
        var enabled = learned;
        if (learned && current && topic.id === current.id) {
          if (!last.finished) learned = false;
          enabled = true;
        }
        items.push(
          <Training
            {...props}
            key={topic.id}
            topic={topic}
            complete={learned}
            enabled={enabled}
            viewHandler={props}
            // handleTopicLink={topiclink}
          />
        );
      } else if (topic.type === 2) {
        let blitzEnabled = !hasBlitz; // && learned;
        if(!learned) {
          hasBlitz = true;
          items.push(
            <button
              key={topic.id}
              className="to-blitz-btn"
              onClick={() => that.onBlitz(that, topic, topics)}
              disabled={!blitzEnabled}
            >
              {i18n('pass-blitz')}
            </button>
          );
        }
      }
    });

    return items;
  };

  render() {
    return <div className="left-panel">{this.switchView(this.props.page)}</div>;
  }
}

DashboardLeftPanel.contextType = AppContext;

export default DashboardLeftPanel;
