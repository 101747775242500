import React, { Component } from 'react';

class MainFlag extends Component {
  render() {
    return (
      <div className="flag-container">
        <img className="flag-img" src={this.props.flag} alt="flag" />
        <p> {this.props.language} </p>
      </div>
    );
  }
}

export default MainFlag;
