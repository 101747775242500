import React, { Component } from 'react';
import './dashboard.css';

import UserStatusInfo from './UserStatusInfo';
import TrainingDaysInfo from './TrainingDaysInfo';
import UserSettings from './settings/UserSettings';

class DashboardRightPanel extends Component {
  render() {
    return (
      <div className="right-panel">
        {this.props.rightPanel === 'userstatus' ? (
          <>
            <UserStatusInfo {...this.props} />
            <TrainingDaysInfo {...this.props} />
          </>
        ) : (
          //  `User Settings Component`
          <UserSettings {...this.props} />
        )}
      </div>
    );
  }
}

export default DashboardRightPanel;
