import React from 'react';
import i18n from '../../../translation/translation';
import BackBtn from '../BackBtn';
import message_ic from '../../../images/message_ic.svg';
import Notifications from './Notifications';

const NotificationList = props => {
  const json = localStorage.getItem('notifications') || '[]';
  const items = JSON.parse(json);

  let count = localStorage.getItem('read-notifications');
  if (count !== items.length) {
    localStorage.setItem('read-notifications', items.length);
    if (window.onMyNotification) {
      window.onMyNotification();
    }
  }

  var elements = [];

  let reverse = items.reverse();
  for (const item of reverse) {
    elements.push(
      <Notifications
        key={item.id}
        message={item.content}
        timestamp={new Date(item.timestamp || 0)}
      />
    );
  }

  return (
    <div className={1 ? 'card notification-info' : 'card notification-none'}>
      <div className="status-header">
        <BackBtn {...props} />
        {i18n('my-notifications')}
      </div>

      <div className="notification-body">
        {items.length > 0 ? (
          <>{elements}</>
        ) : (
          <div className="notifications-none-div" align="center">
            <img src={message_ic} alt="message_ic" />
            <p> {i18n('no-notifications')} </p>
          </div>
        )}
      </div>
    </div>
  );
};

export default NotificationList;
