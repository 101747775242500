import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { checkCookie } from '../utils/cookies';

const MainRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      checkCookie() ? (
        <Redirect
          to={{
            pathname: '/dashboard',
            state: {
              leftPanel: 'default',
              rightPanel: 'userstatus'
            }
          }}
        />
      ) : (
        <Component {...props} />
      )
    }
  />
);

export default MainRoute;
