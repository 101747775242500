import React, { Component } from 'react';
import { Form } from 'react-bootstrap';
import Program from './Program';
import { Redirect } from 'react-router-dom';

class RegisterSecondStep extends Component {
  render() {
    const items = [];

    if (!this.props.package) {
      return <Redirect to="/select-course" />;
    }

    // eslint-disable-next-line
    for (const [index, value] of this.props.package.programs.entries()) {
      // eslint-disable-next-line
      items.push(
        <Program
          onProgramClick={this.props.onProgramClick}
          {...this.props}
          key={value.id}
          index={index}
          program={value}
        />
      );
    }

    return (
      <Form className="center-content">
        <div className="card-deck">{items}</div>
      </Form>
    );
  }
}

export default RegisterSecondStep;
