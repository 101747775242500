import React, { Component } from 'react';
import '../dashboard.css';

import { Link } from 'react-router-dom';
import { Modal, Button } from 'react-bootstrap';
import i18n from '../../../translation/translation';

class ExerciseReport extends Component {
  render() {
    return (
      <>
        <Modal
          {...this.props}
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header className="modal-header-exit">
            <Modal.Title
              className="modal-title-exit"
              id="contained-modal-title-vcenter"
            >
              {i18n('confirm-exit')}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="modal-body-exit">
            <p>{i18n('are-you-sure-exit')}</p>
            <span style={{ float: 'right', marginBottom: '0px' }}>
              <Button
                className="modal-btn-close"
                variant="default"
                onClick={this.props.onHide}
              >
                {i18n('cancel')}
              </Button>
              <Link to="logout">
                <Button className="modal-btn-exit" variant="default">
                  {i18n('do-exit')}
                </Button>
              </Link>
            </span>
          </Modal.Body>
        </Modal>
      </>
    );
  }
}

export default ExerciseReport;
