import React from 'react';
import { Link } from 'react-router-dom';
import { Nav, Navbar, Button, ButtonToolbar } from 'react-bootstrap';
import LanguageSelector from './LanguageSelector';
import i18n from '../../../translation/translation';

class GeneralHeader extends React.Component {
  render() {
    return (
      <>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="mr-auto"> </Nav>
          <Nav>
            <ButtonToolbar>
              <Link to="login">
                {' '}
                <Button
                  // bsStyle=""
                  style={{ backgroundColor: '#683da1' }}
                  // bsClass="btn"
                  className="HeaderLogin"
                  variant="secondary"
                >
                  {i18n('login')}
                </Button>{' '}
              </Link>
              <Link to="register">
                {' '}
                <Button
                  // bsStyle=""
                  style={{ backgroundColor: '#ffa00a' }}
                  // bsClass="btn"
                  className="HeaderRegister"
                  variant="secondary"
                >
                  {i18n('create-account')}
                </Button>
              </Link>
              <LanguageSelector {...this.props} general={true} />
            </ButtonToolbar>
          </Nav>
        </Navbar.Collapse>
      </>
    );
  }
}

export default GeneralHeader;
