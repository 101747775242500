import * as types from '../redux-actions';

export default function(state = [], action) {
  const showAd = action.show;

  switch (action.type) {
    case types.AD_SHOW_SUCCESS:
      return { ...state, showAd: showAd.show };
    case types.SET_LANGUAGE_SUCCESS:
      return { ...state, uiLanguage: action.language };
    case types.LOGOUT_USER:
      return { ...undefined, undefined };
    default:
      return state;
  }
}
