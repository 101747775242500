import React from 'react';
import { Link } from 'react-router-dom';
import i18n from '../../translation/translation';

class Footer extends React.Component {
  styles = {
    textDecoration: 'none',
    border: 'none',
    color: '#737373'
  };

  render() {
    return (
      <footer className="footer mt-auto">
        <div className="footer-content">
          <span className="footer-copyright-date"> Cloodee © 2020 </span>
          <span className="footer-copyright-text text-left">
            {' '}
            <Link
              to={{
                pathname: 'terms'
              }}
              style={this.styles}
            >
              {i18n('terms')}
            </Link>
            {' | '}
            <Link
              to={{
                pathname: '/privacy'
              }}
              style={this.styles}
            >
              {i18n('privacy-terms')}
            </Link>
          </span>
          <span className="footer-additional-text">
            <Link
              to={{
                pathname: 'contacts'
              }}
              style={this.styles}
            >
              {i18n('contacts')}
            </Link>
          </span>
        </div>
      </footer>
    );
  }
}

export default Footer;
