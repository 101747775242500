import React from 'react';
import back_ic from '../../images/back_ic.svg';

const BackBtn = props => {
  return (
    <img
      style={{ opacity: '.5' }}
      src={back_ic}
      alt="back"
      onClick={() => props.viewHandler('default', 'userstatus')}
    />
  );
};

export default BackBtn;
